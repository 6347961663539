import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: "app-socialicon",
    template: `
    <div class="flex flex-col items-center text-gray-400 hover:text-blue-900">
        <a [target]="target" [href]="link"
            class="text-3xl  cursor-pointer ">
            <fa-icon [icon]='icon'></fa-icon>
        </a>   
        <p  style="font-size:0.55em">{{caption}}</p> 
    </div>
        
  `
})
export class SocialIconComponent implements OnInit {
    @Input() href: string;
    @Input() icon: string;
    @Input() caption: string;
    @Input() skipHttpCheck: boolean;
    @Input() targetself: boolean;
    link: string = "";
    target: string = "";

    ngOnInit(): void {
        if (!this.skipHttpCheck) {
            if (this.href.includes("http")) {
                this.link = this.href
            } else {
                this.link = "https://" + this.href;
            }
        } else {
            this.link = this.href;
        }
        if (this.targetself == true) {
            this.target = "_self";
        } else {
            this.target = "_blank";
        }
    }


}