<app-wild-header-checkout [businessId]="businessId" backText="Back" (onBackClick)="back()"></app-wild-header-checkout>
<div class="flex items-center justify-center pt-4">
    <nav aria-label="Progress">
        <ol role="list" class="flex items-center">
            <li (click)="goToCart()" class="cursor-pointer relative pr-8 sm:pr-20">
                <!-- Completed Step -->
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full wild-bg-primary"></div>
                </div>
                <a
                    class="relative w-8 h-8 flex items-center justify-center wild-bg-primary rounded-full hover:bg-indigo-900">
                    <!-- Heroicon name: solid/check -->
                    <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Step 2</span>
                </a>
            </li>
            <li class="relative pr-8 sm:pr-20">
                <!-- Current Step -->
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-gray-200"></div>
                </div>
                <a class="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-900 rounded-full"
                    aria-current="step">
                    <span class="h-2.5 w-2.5 wild-bg-primary rounded-full" aria-hidden="true"></span>
                    <span class="sr-only">Step 3</span>
                </a>
            </li>
            <li class="relative">
                <!-- Upcoming Step -->
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-gray-200"></div>
                </div>
                <a href="#"
                    class="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                    <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                        aria-hidden="true"></span>
                    <span class="sr-only">Step 5</span>
                </a>
            </li>


        </ol>
    </nav>
</div>
<div class="max-w-2xl mx-auto py-8 px-4 sm:px-6 mb-16">
    <h2 class="text-xl font-semibold  mt-1">Your Details</h2>
    <p class="mb-3">In case we need to contact you</p>
    <div>
        <ng-container *ngIf="user$ | async as user; else loadingOrError">
            <app-form [form]="form" [ctaText]="ctatext != null ? ctatext: 'Checkout'" disableDivide="true"
                [fullwidthbutton]="true" (onSubmit)="onSubmit($event)" [loading]="loading">
                <app-inputgroup>
                    <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="firstName"
                        description="First Name" placeholder="Rob" [span]=3
                        [value]="user?.personal?.firstName"></app-input>
                    <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="surname"
                        description="Surname" [span]=3 placeholder="Gray" [value]="user?.personal?.surname"></app-input>
                </app-inputgroup>
                <app-inputgroup>
                    <app-input [form]="form" type="text" name="pronouns" description="Pronouns" [span]=3
                        [value]="user?.personal?.pronouns"></app-input>
                </app-inputgroup>
                <app-inputgroup>
                    <app-select [options]="codeOptions" [form]="form" name="code" description="Code" [span]=3
                        [value]="user?.phone?.code"></app-select>
                    <app-input validation="Invalid number" description="Phone Number" [form]="form" type="text"
                        name="number" placeholder="07773123456" [span]=3 [value]="user?.phone?.number"></app-input>
                </app-inputgroup>
            </app-form>
        </ng-container>
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader [disablescreenheight]="true"></app-loader>
            </ng-template>
        </ng-template>
    </div>
</div>