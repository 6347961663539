import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { PostType } from 'src/shared/data/enums/post_type';
import { VerificationEmailType } from 'src/shared/data/enums/verification_email_type';
import { ErrorType } from 'src/shared/data/enums/error_type';
import { ErrorModel } from 'src/shared/data/models/error_model';

@Injectable({
    providedIn: 'root'
})
export class CloudFunctionsService {

    constructor(
        private fns: AngularFireFunctions,
    ) { }

    timeout = 10000;


    callFunctionObservable(functionName: string, body: any): Observable<any> {
        const callable = this.fns.httpsCallable(functionName);
        return callable(body).pipe(
            timeout(this.timeout),
            catchError((err: any) => {
                var error = this.getError(err);
                return throwError(error);
            }),
            // map(a => {
            //     if (a == null || a.data() == null)
            //       return null;
            //     const data = a.data();
            //     const id = a.id;
            //     return { id, ...data };
            //   })
        );
    }

    async callFunction(functionName: string, body: any): Promise<any> {
        const callable = this.fns.httpsCallable(functionName);
        const data = await callable(body).toPromise()
            .catch((error: any) => {
                this.throwAsyncError(error);
            });;
        return data;
    }

    private throwAsyncError(error: any) {
        var errorModel = this.getError(error);
        throw Error(errorModel.message);
    }

    private getError(err: any): ErrorModel {
        var message = "";
        var description = "";
        var code: ErrorType = ErrorType.UNKNOWN;
        var unKnownErrorMessage = "Unknown Error";
        if (err == undefined || err == null || err == "" || err.message == undefined || err.message == "") {
            message = unKnownErrorMessage;
        } else {
            if (code == ErrorType.UNKNOWN)
                switch (err.message) {
                    case "Timeout has occurred":
                        code = ErrorType.TIMEOUT;
                        message = "Network is running slow";
                        description = "Please give it another go";
                        break;
                    case "Missing or insufficient permissions.":
                        code = ErrorType.ACCESSDENIED;
                        message = "Access Denied";
                        description = "You do not have access to this content, please contact an administrator";
                        break;
                    default:
                        code = ErrorType.UNKNOWN;
                        message = unKnownErrorMessage;
                        description = "";
                        break;
                }
            if (code == ErrorType.UNKNOWN)
                switch (err.code) {
                    case "invalid-argument":
                        code = ErrorType.INVALIDARGUMENT400;
                        message = err.message;
                        description = "";
                        break;
                    case "unauthenticated":
                        code = ErrorType.ACCESSDENIED;
                        message = "Access Denied";
                        description = "You do not have access to perform this operation, please contact an administrator";
                        break;
                    case "permission-denied":
                        code = ErrorType.PERMISSIONDENIED403;
                        message = "Permission Denied";
                        description = "You do not have access to perform this operation, please contact an administrator";
                        break;
                    default:
                        code = ErrorType.UNKNOWN;
                        message = unKnownErrorMessage;
                        description = "";
                        break;
                }

        }
        var error: ErrorModel = {
            code: code,
            message: message,
            description: description
        }
        console.log("pre-processed error", err);
        console.log("pre-processed error code", err.code);
        console.log("pre-processed error message", err.message);
        console.log("Processed error", error);
        return error;
    }

}
