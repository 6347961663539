<!-- This example requires Tailwind CSS v2.0+ -->
<div [ngClass]="{
    'hidden':!show,
    'block':show
}" class="fixed inset-0 overflow-hidden z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
        <div [ngClass]="{
            'opacity-0':!showAnimation,
            'opacity-100':showAnimation
        }" class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-1000"
            aria-hidden="true">
            <div #panel class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                <div [ngClass]="{
                    'translate-x-full':!showAnimation,
                    'translate-x-0':showAnimation
                }" class="transform transition ease-in-out duration-500 sm:duration-700 w-screen max-w-2xl">
                    <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                        <div class="px-4 py-6 sm:px-6">
                            <div class="flex items-start justify-between">
                                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                                    {{settings?.header}}
                                    <div *ngIf="panelBackText"
                                        class="flex items-center justify-items-start cursor-pointer font-light text-sm">
                                        <fa-icon icon="angle-double-left"></fa-icon>
                                        <p class="ml-2" (click)="goBack()">
                                            {{panelBackText}}
                                        </p>
                                    </div>
                                </h2>
                                <div class="ml-3 h-7 flex items-center">
                                    <button (click)="close()" type="button"
                                        class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500">
                                        <!-- Heroicon name: outline/x -->
                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- Main -->
                        <div #panelBody></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>