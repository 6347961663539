import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { LoginComponent } from 'src/wild/modules/auth/components/login.component';
import { RegisterComponent } from 'src/wild/modules/auth/components/register.component';
import { MailinglistRepositoryService } from 'src/wild/modules/brochure/services/maillist_repository_service/mailinglist-repository.service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  sent: boolean = false;
  loading: boolean = false;
  isAppUser: boolean = false;
  isWidgetUser: boolean = false;
  form = this._formBuilder.group({
    email: new FormControl('', [
      Validators.required
    ])
  });

  constructor(
    private navigation: NavigationService,
    private auth: AuthenticationService,
    private maillistRepository: MailinglistRepositoryService,
    private notify: NotifyService,
    private _formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.isWidgetUser = this.auth.isWidgetUser;
  }

  async onSubmit(data: any) {
    if (this.form.invalid)
      return;
    await this.update(data);
  }

  async update(data: any) {
    this.loading = true;
    await this.maillistRepository.add(data.email)
      .then(() => {
        this.loading = false;
        this.sent = true;
        this.notify.notify("Subscribed", "You are now a part of the newsletter", NotifyType.SUCCESS);
        this.form.controls['email'].setValue("");
      })
      .catch((error) => {
        this.loading = false;
        //TODO - error handle notify me
      });
  }

  privacy() {
    this.navigation.navigate(WildNavigationType.PRIVACY);
  }

  terms() {
    this.navigation.navigate(WildNavigationType.TERMS);
  }

  about() {
    this.navigation.navigate(WildNavigationType.ABOUT);
  }

  safety() {
    this.navigation.navigate(WildNavigationType.SOS);
  }

  roadmap() {
    this.navigation.navigate(WildNavigationType.ROADMAP);
  }

  contact() {
    this.navigation.navigate(WildNavigationType.CONTACT);
  }

  search() {
    this.navigation.navigate(WildNavigationType.SEARCH)
  }

  pricing() {
    this.navigation.navigate(WildNavigationType.PRICING)
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  swimmer() {
    this.navigation.navigate(WildNavigationType.USERDASHBOARD);
  }

  businessDashboard() {
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME);
  }

  login() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.COMPONENT,
      component: LoginComponent,
      componentInputs: [{
        inputName: "modallink",
        value: WildNavigationType.USERDASHBOARD
      }],
      componentOutputs: [],
      close: true,
      large: false
    }
    this.modalService.show(settings);
  }

  logout() {
    this.auth.logout();
    this.navigation.navigate(WildNavigationType.HOME);
  }

  register() {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.COMPONENT,
      component: RegisterComponent,
      componentInputs: [{
        inputName: "modallink",
        value: WildNavigationType.USERDASHBOARD
      }],
      componentOutputs: [],
      close: true,
      large: false
    }
    this.modalService.show(settings);
  }

  forBuiness() {
    this.navigation.navigate(WildNavigationType.BECOMEANORGANISER);
  }



}
