<ng-container *ngIf="swimspots$ | async as swimspots; else loadingOrError">
    <div *ngIf="!swimspots || swimspots?.length == 0; else swimspotslist">
        <app-empty icon="swimmer" header="No Linked Swim Spots" description="This business isn't linked to any Spots">
        </app-empty>
    </div>
    <ng-template #swimspotslist>
        <h2 class="text-lg font-bold text-gray-900 truncate leading-tight mb-4">Where we operate</h2>
        <ul role="list" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 gap-x-4 gap-y-8">
            <li *ngFor="let swimspot of swimspots" [caption]="swimspot.name" [id]="swimspot.id"
                (onClick)="openSwimSpot($event)" [src]="
                swimspot?.image?.image == null ?
                'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/heros%2Fwetsuit-looking-over-lake.jpg?alt=media&token=24345735-f2eb-4bfe-a5c9-060593ee2839' :
                imagesUrl + swimspot?.image?.image + '_640x640.jpeg?alt=media'" app-swimspotlistitem>
            </li>
        </ul>
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
    </ng-container>
    <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
    </ng-template>
</ng-template>