import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private router : Router,
    private meta: Meta,
    private title: Title
  ) { }

  update(title:string, description:string, keywords:string, image:string) {
      var url = this.router.url;
      //TODO - swap keywords for array.
      this.title.setTitle(title);
      this.meta.updateTag({ 'name': 'keywords', 'content': keywords});
      this.meta.updateTag({ 'name': 'description', 'content': description });
      this.meta.updateTag({ 'name': 'twitter:card', 'content': image });
      this.meta.updateTag({ 'name': 'twitter:title', 'content': title });
      this.meta.updateTag({ 'name': 'twitter:text:title', 'content': title});
      this.meta.updateTag({ 'name': 'twitter:description', 'content': description });
      this.meta.updateTag({ 'name': 'twitter:image', 'content': image });
      this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': title });
      this.meta.updateTag({ 'property': 'og:title', 'content' : title });
      this.meta.updateTag({ 'property': 'og:url', 'content': url});
      this.meta.updateTag({ 'property': 'og:image', 'content': image});
      this.meta.updateTag({ 'property': 'og:image:alt', 'content': title });
      this.meta.updateTag({ 'property': 'og:description', 'content': description });
  }
}
