import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: "app-loadingserviceshimmer",
    template: `
    <section class="text-gray-300">
        <div class="flex flex-wrap -m-4">
            <div class="w-full px-4 md:px-0">
                <h1 class="w-1/6 my-4 h-6 animate-pulse bg-gray-300"></h1>
                <div class="flex">
                        <div class="hidden sm:block h-20 w-20 rounded-md bg-gray-400  mr-4"></div>
                        <div class="flex-1">
                            <h2 class="bg-gray-300 animate-pulse h-4 w-1/4 "></h2>
                            <div class="flex items-center flex-wrap my-3">
                                <a class="bg-blue-200 h-4 animate-pulse w-32 inline-flex items-center md:mb-2 lg:mb-0">
                                </a>
                            </div>
                            <p class="leading-relaxed mb-2 w-full h-3 animate-pulse bg-gray-300"></p>
                            <p class="leading-relaxed mb-0 w-2/3 h-3 animate-pulse bg-gray-300"></p>
                        </div>
                        <div class="flex-0 flex items-center justify-end">
                            <div class="flex flex-col justify-start items-end ml-2 text-right">
                                <p class="leading-relaxed mb-1 w-16 l h-3 animate-pulse bg-gray-300 text-right"></p>
                                <p class="leading-relaxed mb-1 w-12 h-2 animate-pulse bg-gray-300 text-right"></p>
                                <p class="leading-relaxed mb-0 w-8 h-4 animate-pulse bg-gray-300 text-right"></p>
                            </div>
                        </div>
                        <div class="flex-0 flex items-center justify-end">
                            <div class="h-10 w-14 rounded-md bg-gray-400  ml-1">
                        </div>
                    </div>
                </div>
                <p class="my-6 w-full h-1 animate-pulse bg-gray-100"></p>
            </div>
        </div>
    </section>
  `
})
export class LoadingServiceShimmer  {

}
