import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkboxandlink',
  templateUrl: './checkboxandlink.component.html',
  styleUrls: ['./checkboxandlink.component.scss']
})
export class CheckboxandlinkComponent implements OnInit {

  @Input() linktext: string;
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  click(){
    this.onClick.emit();
  }

}
