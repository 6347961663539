import { Component } from '@angular/core';

@Component({
  selector: 'app-lightweightmenu',
  template: `
  <nav class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="flex-1 flex items-center justify-center">
            <div [routerLink]="['']" class="flex-shrink-0 flex items-center cursor-pointer py-2">
                <img class="block h-12 w-auto"
                    src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fdarkbluelogonostrap.png?alt=media&token=e48ad5be-9ff5-455a-83ca-7f1c3092b9ff"
                    alt="Wild">
            </div>
        </div>
    </div>
</nav>
`
})
export class LightweightmenuComponent { }
