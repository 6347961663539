<main class="flex-grow mx-auto max-w-7xl w-full flex flex-col">
    <div class="flex-shrink-0 my-auto py-8 sm:py-16">
        <p class="text-sm font-semibold wild-text-primary uppercase tracking-wide">{{code != null ? "error code " + code
            :
            "error"}}</p>
        <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{{header != null ?
            header: "Sorry 🙈"}}
        </h1>
        <p class="mt-2 text-base text-gray-500">{{description != null ? description : "There was an error loading this
            content, let's try that again."}}</p>
        <div class="mt-6" *ngIf="!hideretry">
            <a (click)="retry()" class="wild-link cursor-pointer">Retry<span aria-hidden="true"> &rarr;</span></a>
        </div>
    </div>
</main>