import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-loginpage',
  template: `
  <app-registrationpagecontainer [src]="src" [mobilesrc]="mobilesrc">
    <app-login [isPage]="true" [isBusiness]='isBusiness'></app-login>
  </app-registrationpagecontainer>
  `
})
export class LoginpageComponent implements OnInit {

  src: string = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FSign%20In%20for%20business%20(2).png?alt=media&token=e1a3cabc-8864-4739-ad28-15f3281fd640";
  mobilesrc: string = "";
  isBusiness: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.business == 1) {
        this.isBusiness = true;
        this.src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FSign%20In%20for%20business.png?alt=media&token=ded43047-2c78-4bb2-8b4e-c220fce09b33";
      }
    })
  }

}
