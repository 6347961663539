import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { REGION } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingModule } from 'src/booking/booking.module';
import { environment } from 'src/environments/environment';
import { AuthenticationInterceptorService } from 'src/shared/services/http/auth_interceptor';
import { SharedModule } from 'src/shared/shared.module';
import { AuthModule } from 'src/wild/modules/auth/auth.module';
import { WidgetRoutingModule } from './widget-routing.module';
import { WidgetComponent } from './widget.component';

@NgModule({
  declarations: [
    //pages
    WidgetComponent
  ],
  imports: [
    SharedModule,
    //THIS NEEDS TO STAY HERE - IT CAUSES INJECTOR ERRORS WHEN USED IN THE SHARED MODULE
    BrowserAnimationsModule,
    //Firebase
    AngularFireModule.initializeApp(environment.firebase),
    //Modules
    AuthModule,
    // BusinessModule,
    WidgetRoutingModule,
    //IMPORTANT!!!! MAKE SURE THIS IS THE LAST IMPORTED MODULE SO BOOKINGS URLS KICK I.. IT"S LIKE A WILDCARD. 
    BookingModule,
  ],

  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptorService,
    multi: true
  }, { provide: REGION, useValue: 'europe-west2' }],
  bootstrap: [WidgetComponent]
})
export class WidgetModule { }
