import { Component, Input, OnInit } from '@angular/core';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';

@Component({
   template: `
   <app-lightweightmenu></app-lightweightmenu>
    <div class="mx-auto max-w-7xl p-6 w-full">
      <h1 class="my-3 md:my-10 text-xl font-bold tracking-tight text-gray-900 md:text-3xl text-center">Where would you like to go?</h1>
      <div  class="md:flex justify-between w-full">
          <div (click)="user()" class="mb-6 md:mb-6 md:w-1/2 rounded-lg wild-text-primary border-2 border border-blue-900 p-4 md:p-8 md:h-64 cursor-pointer hover:bg-gray-100 md:mr-3 ">
            <h2 class="text-2xl font-semibold md:text-center">Customer</h2>
            <p class="text-lg font-medium md:text-center mt-3 md:mt-6">Access your Profile & Bookings</p>
            <button type="button" class="block md:mx-auto  mt-5 md:mt-10   items-center rounded-md border border-transparent wild-bg-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Customer Mode
            </button>
          </div>
          <div (click)="business()" class="wild-bg-primary text-white  md:w-1/2 rounded-lg p-4 md:p-8 md:h-64 cursor-pointer hover:bg-gray-900 md:ml-3">
            <h2 class="text-2xl font-semibold md:text-center">Wild for Business</h2>
            <p class="text-lg font-medium md:text-center mt-3 md:mt-6">Access or Create a Business</p>
            <button type="button" class="block md:mx-auto mt-5 md:mt-10  items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium wild-text-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Business Mode
            </button>
          </div>
      </div>
    </div>
  `
})
export class WhereToComponent implements OnInit {


  constructor(
    private navigation: NavigationService) { }

  ngOnInit(): void {
  
  }

  user(){
    this.navigation.navigate(WildNavigationType.USERPROFILEPAGE)
  }

  business(){
    this.navigation.navigate(WildNavigationType.DASHBOARDHOME)
  }

}
