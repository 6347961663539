import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { BookingRoutingModule } from './business-routing.module';
import { SharedBasketComponent } from './components/sharedbasket/sharedbasket.component';
import { WildCheckoutHeaderComponent } from './components/wild_checkoutheader.component';
import { WildCheckoutHeaderBacketIconComponent } from './components/wild_checkoutheader_basketicon.component';
import { BasketComponent } from './pages/basket/basket.component';
import { BookinghomeComponent } from './pages/bookinghome/bookinghome.component';
import { ServiceAvailabilityComponent } from './pages/bookinghome/components/availability.component';
import { AvailableScheduleComponent } from './pages/bookinghome/components/availableschedule.component';
import { AvailableServicesComponent } from './pages/bookinghome/components/availableservices.component';
import { BookWrapperComponent } from './pages/bookinghome/components/bookwrapper.component';
import { ContinueShoppingComponent } from './pages/bookinghome/components/continueshopping.component';
import { SelectDateComponent } from './pages/bookinghome/components/selectdate.component';
import { SelectTeamMemberComponent } from './pages/bookinghome/components/selectTeamMember.component';
import { BookingDetailsComponent } from './pages/bookinghome/components/servicedetails.component';
import { ServiceReadMoreComponent } from './pages/bookinghome/components/servicereadmore.component';
import { ServiceReadMoreScheduledComponent } from './pages/bookinghome/components/servicereadmorescheduled.component';
import { SocialIconComponent } from './pages/bookinghome/components/socialicon.component';
import { SwimSpotsForBusinessLargeComponent } from './pages/bookinghome/components/swimspotsforbusinesslarge/swimspotsforbusinesslarge.component';
import { SwimSpotsForBusinessSmallComponent } from './pages/bookinghome/components/swimspotsforbusinesssmall/swimspotsforbusinesssmall.component';
import { BusinessTagsComponent } from './pages/bookinghome/components/business_tags.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { AvailableCouponsComponent } from './pages/bookinghome/components/availablecoupons.component';
import { AvailableVouchersComponent } from './pages/checkout/components/availablevouchers/availablevouchers.component';
import { CouponReadMoreComponent } from './pages/bookinghome/components/couponreadmore.component';
import { AvailableServicesV2Component } from './pages/bookinghome/components/availableservicesv2.component';
import { AvailableCouponsV2Component } from './pages/bookinghome/components/availablecouponsV2.component';
import { BookingUserDetailsForLinkedAccountsComponent } from './pages/bookinguserdetailsforlinkedaccounts/bookinguserdetailsforlinkedaccounts.component';
import { BookerDetailsIfMissing } from './pages/bookerdetailsifmissing/bookerdetailsifmissing.component';
import { BookWrapperScheduledComponent } from './pages/bookinghome/components/bookwrapperscheduled.component';
import { LoadingServiceShimmer } from './pages/bookinghome/components/loadingservice_shimmer';

@NgModule({
    declarations: [
        //Pages
        BookinghomeComponent,
        BookingDetailsComponent,
        CheckoutComponent,
        BookerDetailsIfMissing,
        BasketComponent,
        ConfirmationComponent,
        //Components
        AvailableServicesComponent,
        AvailableServicesV2Component,
        AvailableCouponsComponent,
        AvailableCouponsV2Component,
        SocialIconComponent,
        ServiceReadMoreComponent,
        ContinueShoppingComponent,
        SelectTeamMemberComponent,
        BookWrapperComponent,
        ServiceReadMoreScheduledComponent,
        AvailableScheduleComponent,
        ServiceAvailabilityComponent,
        SelectDateComponent,
        BookingUserDetailsForLinkedAccountsComponent,
        WildCheckoutHeaderComponent,
        WildCheckoutHeaderBacketIconComponent,
        BookWrapperScheduledComponent,
        SharedBasketComponent,
        LoadingServiceShimmer,
        SwimSpotsForBusinessLargeComponent,
        SwimSpotsForBusinessSmallComponent,
        BusinessTagsComponent,
        AvailableVouchersComponent,
        CouponReadMoreComponent
    ],
    imports: [
        SharedModule,
        BookingRoutingModule
    ]
})
export class BookingModule { } 
