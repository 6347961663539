<ng-container *ngIf="coupons$ | async as coupons; else loadingOrError">
    <div *ngIf="!coupons || coupons?.coupons?.length == 0; else couponslist">

    </div>
    <ng-template #couponslist>
        <h2 class="wild-header-small mt-7 mb-4">Available vouchers</h2>
        <div *ngFor="let coupon of coupons.coupons" class="flex justify-between mb-2">
            <div class="flex items-center">
                <div class="mr-3">
                    <fa-icon class="text-md text-gray-400" icon="ticket-alt"></fa-icon>
                </div>
                <div class="text-sm">
                    <p>{{coupon.name}}</p>
                    <p class="text-xs">{{coupon.quantityavailable - coupon.quantityused}} to use before
                        {{coupon.readableExpiryDate}}</p>
                </div>
            </div>
            <div>
                <button (click)="click(coupon)" class="wild-btn-primary w-14 py-1 px-2 text-xs">apply</button>
            </div>
        </div>
        <br />
    </ng-template>
</ng-container>
<ng-template #loadingOrError>
    <ng-container *ngIf="error; else loading">
        <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
        </app-retry>
    </ng-container>
    <ng-template #loading>
        <app-loader [disablescreenheight]="true"></app-loader>
    </ng-template>
</ng-template>