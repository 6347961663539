import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpContextToken,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/auth_service';
import { switchMap, tap } from 'rxjs/operators';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { NavigationService } from '../navigation_service/navigation_service';
import { ErrorType } from 'src/shared/data/enums/error_type';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
export const BYPASS_AUTH = new HttpContextToken(() => false);
@Injectable({
    providedIn: 'root'
})
export class AuthenticationInterceptorService implements HttpInterceptor {
    constructor(public authenticationService: AuthenticationService, public businessService: BusinessService, private navigation: NavigationService) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.authenticationService.isLoggedIn()) {
            if (request.context.get(BYPASS_AUTH) === true)
                return next.handle(request);
            var businessId = this.businessService.getActiveBusinessId();
            return this.authenticationService.getToken$().pipe(switchMap((token) => {
                const authenticatedRequest = request.clone({
                    headers: request.headers.append(
                        'Authorization',
                        `Bearer ${token.token}`
                    ).append(
                        'business',
                        businessId
                    )
                });
                return next.handle(authenticatedRequest).pipe(tap(() => { },
                    (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                            if (err?.error?.code == ErrorType.ACCESSDENIEDWHENCHECKINGBUSINESS) {
                                this.navigation.navigate(WildNavigationType.GROUPS);
                            } else if (err?.error?.code == ErrorType.ACCESSDENIED || err.status === 401) {
                                this.navigation.navigate(WildNavigationType.LOGIN);
                            } else {
                                return;
                            }
                        }
                    }));
            }));
        } else {
            return next.handle(request);
        }
    }
}