import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperComponent } from './components/buildingblocks/wrapper/wrapper.component';
import { AddbuttonComponent } from './components/addbutton/addbutton.component';
import { LabelComponent } from './components/forms/label/label.component';
import { TableComponent } from './components/table/table.component';
import { FormComponent } from './components/form.component';
import { InputComponent } from './components/input.component';
import { TextareaComponent } from './components/textarea.component';
import { InputgroupComponent } from './components/forms/inputgroup/inputgroup.component';
import { SelectComponent } from './components/select/select.component';
import { ToggleComponent } from './components/forms/toggle/toggle.component';
import { ParagraphComponent } from './components/copy/paragraph/paragraph.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeadersectionComponent } from './components/headersection/headersection.component';
import { AttributeComponent } from './components/attribute/attribute.component';
import { CenterheaderComponent } from './components/copy/centerheader/centerheader.component';
import { CtasectionComponent } from './components/ctasection/ctasection.component';
import { CtasectiontwoComponent } from './components/ctasectiontwo/ctasectiontwo.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { LogosComponent } from './components/logos/logos.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { HerotwoComponent } from './components/herotwo/herotwo.component';
import { SectionwrapComponent } from './components/buildingblocks/sectionwrap/sectionwrap.component';
import { GetintouchComponent } from './components/getintouch/getintouch.component';
import { NotifymeComponent } from './components/notifyme/notifyme.component';
import { StripctaComponent } from './components/stripcta/stripcta.component';
import { FeaturesComponent } from './components/features/features.component';
import { UpdatesComponent } from './components/updates/updates.component';
import { ShareModule } from 'ngx-sharebuttons';
import { SharelinksComponent } from './components/sharelinks/sharelinks.component';
import { AngularFireRemoteConfigModule } from '@angular/fire/remote-config';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { CardComponent } from './components/buildingblocks/card.component';
import { CheckboxandlinkComponent } from './components/buildingblocks/checkboxandlink/checkboxandlink.component';
import { TextdividerComponent } from './components/buildingblocks/textdivider/textdivider.component';
import { NotifyComponent } from './components/notify/notify.component';
import { OrderByPipe } from './pipes/orderby_pipe';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAd, faAngleDoubleLeft, faBan, faBars, faBell as fasBell, faBroadcastTower, faCalendar, faCalendarAlt, faCalendarCheck, faSlash, faCalendarPlus, faCheck, faCheckDouble, faChevronCircleLeft, faChevronCircleRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faClock, faCog, faCogs, faCoins, faComment as fasComment, faCopy, faCreditCard, faEdit as fasEdit, faEnvelope, faExchangeAlt, faExclamationTriangle, faExternalLinkAlt, faFileImport, faFilm, faFilter, faFlag, faFolderOpen, faHeart, faHome, faImages, faInfinity, faList, faLock, faMap, faMapMarkerAlt, faMapPin, faMobile, faPalette, faPen, faPhoneSquare, faPlug, faPlus, faPuzzlePiece, faQuestion, faRoad, faRocket, fas, faSearch, faShieldAlt, faShoppingBag, faSignInAlt, faSignOutAlt, faSms, faStar, faStickyNote as fasStickyNote, faStopwatch, faSwimmer, faTemperatureLow, faThumbsUp, faTicketAlt, faTimes, faTint, faTrash, faUmbrellaBeach, faUpload, faUserAlt, faUserCog, faUserEdit, faUserPlus, faUsers, faUserSlash, faWater, faWind, faWindowMaximize, faUserShield, faCalendarWeek, faFolder, faSun, faRunning, faMinus, faCode } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faStickyNote as farStickyNote, faBell as farBell, faComment as farComment, faEdit as farEdit } from '@fortawesome/free-regular-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faApple, fab, faFacebook, faGoogle, faInstagram, faLinkedin, faPinterest, faTelegram, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { LightweightmenuComponent } from './components/lightweightmenu/lightweightmenu.component';
import { StepsComponent } from './components/steps/steps.component';
import { OptionsComponent } from './components/buildingblocks/options/options.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { RetryComponent } from './components/retry/retry.component';
import { CardtwoComponent } from './components/buildingblocks/cardtwo/cardtwo.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ConfirmationComponent } from './components/buildingblocks/confirmation/confirmation.component';
import { CardlistitemComponent } from './components/buildingblocks/cardlist/item/cardlistitem.component';
import { CardlistwrapperComponent } from './components/buildingblocks/cardlist/wrapper/cardlistwrapper.component';
import { CardlistnewComponent } from './components/buildingblocks/cardlist/new/cardlistnew.component';
import { ModalComponent } from './components/modal/modal.component';
import { InlineFormComponent } from './components/forms/inlineform/inlineform.component';
import { TableCellComponent } from './components/table/cell.component';
import { SafePipe } from './pipes/safe_pipe';
import { ImageselectComponent } from './components/forms/imageselect/imageselect.component';
import { UploadimageComponent } from './components/uploadimage/uploadimage.component';
import { FilemanagerComponent } from './components/filemanager/filemanager.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { FilemanagerfilesComponent } from './components/filemanagerfiles/filemanagerfiles.component';
import { FilemanagerscrollableshellComponent } from './components/filemanager/components/filemanagerscrollableshell/filemanagerscrollableshell.component';
import { FilemanagerwrapperComponent } from './components/filemanager/components/filemanagerwrapper/filemanagerwrapper.component';
import { SearchresultsComponent } from './components/searchresults/searchresults.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { SearchresultsSwimspotsComponent } from './components/searchresults/components/searchresults-swimspots/searchresults-swimspots.component';
import { SearchresultsWrapperComponent } from './components/searchresults/components/searchresults-wrapper.component';
import { SearchresultsResultComponent } from './components/searchresults/components/searchresults-result.component';
import { AnimationComponent } from './components/animation/animation';
import { EmptyComponent } from './components/empty/empty.component';
import { ModalTopperComponent } from './components/modaltopper.component';
import { FormTagsComponent } from './components/formtags.component';
import { FormRatingComponent } from './components/forms/formrating/formrating.component';
import { DatepickerModule } from 'ng2-datepicker';
import { FormdatepickerComponent } from './components/forms/formdatepicker/formdatepicker.component';
import { SwimspotListItemComponent } from './components/swimspotslist/swimspotlistitem.component';
import { SwimspotListWrapperComponent } from './components/swimspotslist/swimspotlistwrapper.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ActionsWrapperComponent } from './components/actions/actionswrapper.component';
import { ActionsListItemComponent } from './components/actions/actionslistitem.component';
import { ToDoWrapperComponent } from './components/todo/todowrapper.component';
import { ToDoListItemComponent } from './components/todo/todolistitem.component';
import { PanelComponent } from './components/panel/panel.component';
import { ChartsModule } from 'ng2-charts';
import { WildHeaderComponent } from './components/wild_header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotificationsListComponent } from './components/sidemenu/components/notificationslist.component';
import { PanelRowComponent } from './components/panel/components/panelrow.component';
import { PanelHeaderComponent } from './components/panel/components/panelheader.component';
import { MoneyInputDirective } from './directive/moneyinput.directive';
import { PricePipe } from './pipes/price';
import { BookingProfileFormComponent } from './components/bookingprofile.component';
import { OrderLineActivitysomponent } from './components/orderline_activity';
import { UserProfileDetailsComponent } from './components/userprofiledetails.component';
import { AppDownloadLinksComponent } from './components/appdownloadlinks.component';
import { DetailsSectionComponent } from './components/detailssection.component';
import { InlineFormTightComponent } from './components/forms/inlineformtight/inlineformtight.component';
import { ListItemComponent } from './components/listitem.component';
import { CheckboxComponent } from './components/checkbox.component';
import { ReadMoreSwimSpotComponent } from './components/readmoreswimspot.component';
import { PointOnMapComponent } from './components/pointonmap.component';
import { FeatureLeftNewComponent } from './components/featureleft.component';
import { FeatureRightNewComponent } from './components/featurelrightcomponent';
import { SearchResultComponent } from './components/searchresult.component';
import { OneTwoThreeComponent } from './components/onetwothree.component';
import { RegistrationPageContainerComponent } from './components/registrationpagecontainer.component';
import { AppPromptComponent } from './services/appprompt/appcompt_component';
import { WildHeroComponent } from './components/wildhero.component';
import { HomePageResultsComponent } from 'src/shared/components/homepageresults.component';
import { SwimSpotSearchComponent } from './components/swimspotsearch.component';
import { ConvertToClickableLinksDirective } from './directive/converttoclickablelinks.directive';
import { RotatingTextComponent } from './components/rotatingtext/rotatingtext.component';
import { BookingProfileFormForLinkedAccountComponent } from './components/bookingprofileforlinkedaccount.component';
import { SearchResultsGridShimmer } from './components/shimmers/searchresultsgrid_shimmer';
import { SearchResultsRowShimmer } from './components/shimmers/searchresultsrow_shimmer';
import { SearchResultShimmer } from './components/shimmers/searchresult_shimmer';
import { WildShareLinksComponent } from './components/wildsharelinks';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    SafePipe,
    PointOnMapComponent,
    SwimspotListItemComponent,
    MoneyInputDirective,
    ConvertToClickableLinksDirective,
    SwimspotListWrapperComponent,
    RotatingTextComponent,
    SearchResultComponent,
    OneTwoThreeComponent,
    SearchresultsComponent,
    BookingProfileFormComponent,
    FeatureLeftNewComponent,
    FeatureRightNewComponent,
    RetryComponent,
    FilemanagerscrollableshellComponent,
    NotificationsListComponent,
    AppPromptComponent,
    AppDownloadLinksComponent,
    SwimSpotSearchComponent,
    UserProfileDetailsComponent,
    FilemanagerwrapperComponent,
    ConfirmationComponent,
    SearchresultsSwimspotsComponent,
    CtasectiontwoComponent,
    UploadimageComponent,
    ReadMoreSwimSpotComponent,
    ModalTopperComponent,
    CardlistwrapperComponent,
    CtasectionComponent,
    PanelHeaderComponent,
    ImageselectComponent,
    ActionsWrapperComponent,
    DetailsSectionComponent,
    OrderLineActivitysomponent,
    ActionsListItemComponent,
    AddbuttonComponent,
    TableCellComponent,
    WildHeaderComponent,
    TableComponent,
    CardtwoComponent,
    FormComponent,
    InputComponent,
    TextareaComponent,
    ListItemComponent,
    EmptyComponent,
    InputgroupComponent,
    ToDoWrapperComponent,
    ToDoListItemComponent,
    SelectComponent,
    ToggleComponent,
    HeaderComponent,
    FooterComponent,
    OptionsComponent,
    RegistrationPageContainerComponent,
    InlineFormComponent,
    ParagraphComponent,
    LabelComponent,
    TabsComponent,
    HeadersectionComponent,
    WildShareLinksComponent,
    AttributeComponent,
    CenterheaderComponent,
    FormTagsComponent,
    GalleryComponent,
    LogosComponent,
    InlineFormTightComponent,
    TestimonialComponent,
    CardlistnewComponent,
    PanelRowComponent,
    LoaderComponent,
    TimelineComponent,
    BookingProfileFormForLinkedAccountComponent,
    NotfoundComponent,
    HerotwoComponent,
    SectionwrapComponent,
    GetintouchComponent,
    FilemanagerfilesComponent,
    PanelComponent,
    NotifymeComponent,
    FormRatingComponent,
    FormdatepickerComponent,
    StripctaComponent,
    SidemenuComponent,
    FeaturesComponent,
    HomePageResultsComponent,
    FilemanagerComponent,
    ModalComponent,
    CardlistitemComponent,
    CheckboxComponent,
    UpdatesComponent,
    SearchresultsWrapperComponent,
    SearchresultsResultComponent,
    NotifyComponent,
    AnimationComponent,
    CardComponent,
    WildHeroComponent,
    CheckboxandlinkComponent,
    TextdividerComponent,
    OrderByPipe,
    PricePipe,
    SearchResultShimmer,
    SearchResultsRowShimmer,
    SearchResultsGridShimmer,
    StepsComponent,
    SharelinksComponent,
    LightweightmenuComponent,
    WrapperComponent],
  imports: [
    RouterModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    CommonModule,
    HttpClientModule,
    ShareModule,
    DatepickerModule,
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    FontAwesomeModule,
    LottieModule.forRoot({ player: playerFactory }),
    ChartsModule,

    // BrowserModule, BrowserAnimationsModule, LazyLoadImageModule (if using it), CarouselModule (if using it), InfiniteScrollModule (if using it), HttpModule ( if using it)
  ],
  exports: [
    CommonModule,
    ChartsModule,
    GoogleMapsModule,
    RetryComponent,
    HomePageResultsComponent,
    PointOnMapComponent,
    CtasectiontwoComponent,
    ShareModule,
    CtasectionComponent,
    SearchResultShimmer,
    SearchResultsRowShimmer,
    SearchResultsGridShimmer,
    SearchResultShimmer,
    CardlistitemComponent,
    AppDownloadLinksComponent,
    ConfirmationComponent,
    MoneyInputDirective,
    BookingProfileFormForLinkedAccountComponent,
    ConvertToClickableLinksDirective,
    TabsComponent,
    AddbuttonComponent,
    SwimSpotSearchComponent,
    EmptyComponent,
    FormsModule,
    PanelRowComponent,
    BookingProfileFormComponent,
    ReadMoreSwimSpotComponent,
    FormdatepickerComponent,
    CardtwoComponent,
    SwimspotListItemComponent,
    CheckboxComponent,
    SwimspotListWrapperComponent,
    FormTagsComponent,
    OptionsComponent,
    RegistrationPageContainerComponent,
    ListItemComponent,
    PanelComponent,
    RotatingTextComponent,
    FooterComponent,
    ModalComponent,
    TableComponent,
    ModalTopperComponent,
    InlineFormComponent,
    FormComponent,
    ActionsWrapperComponent,
    DetailsSectionComponent,
    ActionsListItemComponent,
    AnimationComponent,
    InputComponent,
    WildHeroComponent,
    TextareaComponent,
    ToDoWrapperComponent,
    ToDoListItemComponent,
    FilemanagerfilesComponent,
    InputgroupComponent,
    SelectComponent,
    ToggleComponent,
    SearchresultsComponent,
    WildHeaderComponent,
    HeaderComponent,
    ParagraphComponent,
    ImageselectComponent,
    OneTwoThreeComponent,
    LabelComponent,
    FilemanagerComponent,
    HeadersectionComponent,
    UserProfileDetailsComponent,
    AttributeComponent,
    InlineFormTightComponent,
    CardlistwrapperComponent,
    CenterheaderComponent,
    GalleryComponent,
    OrderLineActivitysomponent,
    LogosComponent,
    FeatureLeftNewComponent,
    FeatureRightNewComponent,
    CardlistnewComponent,
    TestimonialComponent,
    TableCellComponent,
    PricePipe,
    LoaderComponent,
    TimelineComponent,
    SearchResultComponent,
    HerotwoComponent,
    UploadimageComponent,
    PanelHeaderComponent,
    SidemenuComponent,
    ReactiveFormsModule,
    WildShareLinksComponent,
    SectionwrapComponent,
    GetintouchComponent,
    NotfoundComponent,
    NotifymeComponent,
    StripctaComponent,
    FeaturesComponent,
    OrderByPipe,
    FormRatingComponent,
    UpdatesComponent,
    SearchresultsSwimspotsComponent,
    StepsComponent,
    NotifyComponent,
    CheckboxandlinkComponent,
    CardComponent,
    TextdividerComponent,
    SafePipe,
    SharelinksComponent,
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    FontAwesomeModule,
    LightweightmenuComponent,
    WrapperComponent,
    LottieModule
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // library.addIconPacks(fas, fab, far);
    library.addIcons(
      faHome,
      faSwimmer,
      faWater,
      faTrash,
      faPlus,
      faQuestion,
      faImages,
      faFolderOpen,
      faFilter,
      faPen,
      faUsers,
      faCheckDouble,
      faPalette,
      faCog,
      faTint,
      faBan,
      faUpload,
      faUserCog,
      faExclamationTriangle,
      faFlag,
      faClock,
      faCircle,
      faCoins,
      faFacebook,
      faInstagram,
      faStopwatch,
      faStar,
      faMapMarkerAlt,
      faShieldAlt,
      faCode,
      faHeart,
      faHeartRegular,
      faUserEdit,
      faCalendarCheck,
      faYoutube,
      faCheck,
      faEnvelope,
      faUmbrellaBeach,
      faPinterest,
      faCalendar,
      faCalendarAlt,
      faChevronCircleLeft,
      faExchangeAlt,
      faChevronCircleRight,
      faTicketAlt,
      faSignOutAlt,
      faRoad,
      faChevronLeft,
      faSlash,
      faChevronUp,
      faChevronDown,
      faClock,
      faChevronRight,
      faPhoneSquare,
      faMobile,
      faMinus,
      faWindowMaximize,
      faUserPlus,
      faInfinity,
      faWind,
      faTwitter,
      faWhatsapp,
      faSearch,
      faMap,
      faWater,
      faTemperatureLow,
      faRocket,
      faFileImport,
      farStickyNote,
      faAd,
      faSignInAlt,
      fasStickyNote,
      faTimes,
      faLinkedin,
      faTelegram,
      faMapPin,
      faShoppingBag,
      faThumbsUp,
      fasComment,
      faCalendarWeek,
      faFolder,
      farComment,
      faBars,
      faStopwatch,
      faPuzzlePiece,
      faPlug,
      faUserSlash,
      faUserAlt,
      faCoins,
      farEdit,
      fasEdit,
      faAngleDoubleLeft,
      faBroadcastTower,
      farBell,
      faShieldAlt,
      fasBell,
      faCopy,
      faFacebook,
      faSun,
      faGoogle,
      faApple,
      faCreditCard,
      faUserShield,
      faCalendarPlus,
      faLock,
      faRunning,
      faList,
      faSms,
      faMobile,
      faExternalLinkAlt);
  }
}
