import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/shared/services/storage/storage.service';
import { BusinessStatus } from 'src/shared/data/enums/business_status';
import { BusinessModel } from 'src/shared/data/models/business_model';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { MyBusiness } from 'src/shared/data/models/mybusiness';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { CloudFunctionsService } from 'src/shared/services/cloudfunctions/cloudfunctions_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { TimeZone } from 'src/shared/data/enums/timezone';
import { CountryCodes } from 'src/shared/data/enums/country_codes';
import { BusinessTODOModel } from 'src/shared/data/dtos/business/BusinessTODOModel';
import { NewBusinessDTO } from 'src/shared/data/dtos/newBusinessDTO';
import { Currency } from 'src/shared/data/enums/currency';
import { AddBankDTO } from 'src/shared/data/dtos/business/AddBankDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { ChartType } from 'src/shared/data/enums/chart_type';
import { ChartTimeRange } from 'src/shared/data/enums/chart_time_range';
import { ChartDataDTO } from 'src/shared/data/dtos/charts/ChartDataDTO';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {

    collection: string = "business";

    constructor(
        private http: HttpService,
        private firestore: FirestoreService,
        private storage: StorageService,
    ) { }

    async create(data: NewBusinessDTO): Promise<void> {
        var response = await this.http.post(`${environment.api}/business/onboarding/addNewBusiness`, data).toPromise();
        if (response == null)
            throw Error("Could not find business data");
        var businessId = response.businessId;
        if (businessId == null)
            throw Error("Could not find business Id");
        this.setActiveBusiness(businessId, data.name, "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media", ['owner'], data.url);
        return businessId;
    }

    async addBank(data: AddBankDTO): Promise<void> {
        await this.http.put(`${environment.api}/business/finance/updatebankdetails`, data).toPromise();
    }

    getTODOs(): Observable<BusinessTODOModel[]> {
        var businessId = this.getActiveBusinessId();
        return this.http.get(`${environment.api}/business/onboarding/todos?businessId=${businessId}`)
    }
    getChartData(type:ChartType,time:ChartTimeRange): Observable<ChartDataDTO> {
        return this.http.get(`${environment.api}/business/charts?type=${type}&time=${time}`)
    }

    getVerificationLink(): Promise<any> {
        var businessId = this.getActiveBusinessId();
        return this.http.get(`${environment.api}/business/onboarding/verificationlink?businessId=${businessId}`).toPromise();
    }

    async updateSettings(timezone: TimeZone, countryCode: CountryCodes, currency: Currency) {
        var businessId = this.getActiveBusinessId();
        await this.firestore.update(businessId, "business", {
            timezone: timezone,
            countryCode: countryCode,
            currency: currency
        })
    }

    async updateArea(latitude: string, longitude: string) {
        var businessId = this.getActiveBusinessId();
        await this.firestore.update(businessId, "business_public", {
            latitude: latitude,
            longitude: longitude
        })
    }

    getMyBusinesses(): Observable<MyBusiness[]> {
        return this.http.get(`${environment.api}/business/mybusinesses`);
    }

    getUrlFromName(name: string) {
        return name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    }

    async updateBrand(data: any) {
        await this.firestore.update(this.businessId, "business_public", data);
    }

    getPublicBusiness(): Observable<BusinessPublicModel> {
        return this.firestore.getDocumentById("business_public", this.businessId)
    }

    getBusiness(): Observable<BusinessModel> {
        return this.firestore.getDocumentById("business", this.businessId)
    }

    setActiveBusiness(businessId: string, name: string, image: string, roles: string[], url: string) {
        this.updateActiveProfileId(businessId);
        this.updateActiveProfileName(name);
        this.updateActiveProfileUrl(url);
        if (image == null)
            image = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media&token=4f818d60-9730-4aca-ae56-35bdd097916d";
        this.updateActiveProfileImage(image);
        this.updateActiveProfileRoles(roles);
    }

    updateActiveProfileRoles(roles: string[]) {
        this.storage.setCookie("activeBusinessRoles", roles.toString(), 9999);
    }

    updateActiveProfileId(businessId: string) {
        this.storage.setCookie("activeBusinessId", businessId, 9999);
    }
    updateActiveProfileUrl(url: string) {
        this.storage.setCookie("activeBusinessUrl", url, 9999);
    }

    updateActiveProfileName(name: string) {
        this.storage.setCookie("activeBusinessName", name, 9999);
    }

    updateActiveProfileImage(image: string) {
        this.storage.setCookie("activeBusinessImage", image, 9999);
    }

    get businessId(): string {
        return this.storage.getCookie("activeBusinessId");
    }

    get businessUrl(): string {
        return this.storage.getCookie("activeBusinessUrl");
    }

    get businessName(): string {
        return this.storage.getCookie("activeBusinessName");
    }

    get businessImage(): string {
        return this.storage.getCookie("activeBusinessImage", true);
    }

    get businessRoles(): string {
        return this.storage.getCookie("activeBusinessRoles", true);
    }

    isInRole(role: string) {
        return this.businessRoles.indexOf(role) != -1;
    }

    getActiveBusinessId(): string {
        return this.storage.getCookie("activeBusinessId");
    }

    async delete(businessId: string) {
        await this.firestore.update(businessId, "business_public", {
            status: BusinessStatus.DELETED
        });
    }

    hasActiveBusiness(): boolean {
        try {
            const hasActiveBusiness = this.storage.getCookie("activeBusinessId");
            if (hasActiveBusiness)
                return true;
            return false;
        } catch (err) {
            return false;
        }
    }

    async getActiveBusinessPublicProfile(): Promise<BusinessPublicModel> {
        var businessId = this.getActiveBusinessId();
        return await this.firestore.getDocumentById("business_public", businessId).toPromise();;
    }

}
