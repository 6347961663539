import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SelectModel } from './select_model';

@Component({
  selector: 'app-select',
  template: `
  <div [formGroup]="form">
  <div *ngIf="!hideHeaders" class="flex justify-between">
    <label [for]="name" class="block wild-label">
        {{description}}<span *ngIf="required">*</span>
        </label>
  </div>
    <div>    
        <select (change)="changed()" [id]="name" [name]="name" [formControlName]="name"  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option *ngFor="let option of options" [ngValue]=option.value >{{option.name}}</option>
        </select>
      </div> 
  <div *ngIf="form.get(name)?.errors && (form.get(name)?.dirty || form['submitted'] == true)"> 
      <div class="font-light text-xs text-red-600 py-1" *ngIf="form.get(name)?.errors">
          <span *ngIf="!validation"> {{description}} is required</span>
          <span *ngIf="validation"> {{validation}}</span>
      </div>
  </div>
</div>
`
})
export class SelectComponent implements OnInit {

  @Input() form!: any;
  @Output() onChange = new EventEmitter<any>();
  @Input() options!: SelectModel[];
  @Input() validation!: string;
  @Input() name!: string;
  @Input() hideHeaders: boolean;
  @Input() description!: string;
  @Input() value!: any;
  @Input() span!: number;

  @HostBinding('class') get t() {
    return "p-0 border-0 bg-transparent sm:col-span-" + this.span;
  };

  required: boolean;

  constructor() { }

  ngOnInit(): void {
    //This is to fix selecting items on mobile... it needs to recognise a change in item and starting with a blank record fixes this
    if (this.options[0] !== undefined && this.options[0].name !== "" && this.options[0].value !== "")
      this.options.unshift({
        name: "",
        value: ""
      })
    if (this.value != undefined && this.value != null)
      this.form.controls[this.name].setValue(this.value);
    const form_field = this.form.get(this.name);
    if (form_field.validator) {
      const validator = form_field.validator({} as AbstractControl);
      if (validator && validator.required)
        this.required = true;
    }
  }

  changed() {
    try {
      this.onChange.emit(this.form.controls[this.name].value)
    } catch (error) {

    }
  }

}
