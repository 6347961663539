import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-wild-header-checkout',
  template: `
<div class="bg-white">
  <header class="relative bg-white">
    <nav aria-label="Top" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="border-b border-gray-200">
        <div class="h-16 flex items-center"> 
          <!-- <div class="ml-4 flex lg:ml-0">
            <a (click)="logoClick()">
              <span class="sr-only">Wild</span>
              <img class="h-8 w-auto" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fwild%20logo%20small.png?alt=media&token=5db7aaeb-d57d-455b-bb0b-966738cfde41" alt="">
            </a>
          </div> -->
          <div *ngIf="image" class="flex lg:ml-0 items-center justify-start cursor-pointer wild-bg-primary p-0.5 rounded-lg">
            <img [src]="image" class="h-9 w-9 rounded-md " />
          </div>
          <div *ngIf="backText" class="ml-4 flex lg:ml-0 items-center justify-start cursor-pointer">
            <fa-icon icon="chevron-left"></fa-icon>
            <a class="ml-2" (click)="backClick()">
               {{backText}}
            </a>
          </div>
          <div  class="ml-auto flex items-center">
            <app-wild-header-checkout-basketicon [hideCTAWhenEmpty]="hideCTAWhenEmpty" [hideCartIcon]="hideCartIcon" [hideCountdown]="hideCountdown" [businessId]="businessId"></app-wild-header-checkout-basketicon>
          </div>
        </div>
      </div>
    </nav>
  </header>
</div>

  `
})
export class WildCheckoutHeaderComponent implements OnInit {

  @Input() image: string;
  @Input() businessId: string;
  @Input() hideCTAWhenEmpty: boolean = false;
  isAppUser: boolean = false;
  @Input() hideCartIcon: boolean = false;
  @Input() hideCountdown: boolean = false;
  @Input() backText: string = "";
  @Output() onBackClick = new EventEmitter();

  constructor(private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
  }

  logoClick() { }

  backClick() {
    this.onBackClick.emit();
  }
  backToWebsiteClick() {
    window.history.back();
  }
}
