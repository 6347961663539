import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategorisedServicesDTO } from 'src/shared/data/dtos/services/CategorisedServicesDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { ScheduledServiceAndPricesWrapperDTO } from 'src/shared/data/dtos/services/ScheduledServiceAndPriceWrapperDTO';
import { AvailableCouponsDTO } from 'src/shared/data/dtos/services/AvailableCouponsDTO';
import { ServiceAndPricesDTO } from 'src/shared/data/dtos/services/ServiceAndPricesDTO';

@Injectable({
    providedIn: 'root'
})
export class ServicesPublicService {

    collection: string = "business";

    constructor(
        private http: HttpService
    ) { }

    getCoupons(businessId: string): Observable<AvailableCouponsDTO> {
        return this.http.get(`${environment.api}/user/services/coupons?businessId=${businessId}`);
    }

    getCategorisedServices(businessId: string): Observable<CategorisedServicesDTO> {
        return this.http.get(`${environment.api}/user/services/getServicesAndPrices?businessId=${businessId}`);
    }
    getCategorisedServicesV2(businessId: string): Observable<CategorisedServicesDTO> {
        return this.http.get(`${environment.api}/user/services/getServicesAndPricesV2?businessId=${businessId}`);
    }

    getServicesAndPricesV2ForService(businessId: string, serviceId: string): Observable<ServiceAndPricesDTO> {
        return this.http.get(`${environment.api}/user/services/getServicesAndPricesV2/${serviceId}?businessId=${businessId}`);
    }

    getScheduledServices(businessId: string, startDate: string, endDate: string, serviceId: string): Observable<ScheduledServiceAndPricesWrapperDTO> {
        return this.http.get(`${environment.api}/user/services/getscheduleV2?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}&serviceId=${serviceId}`);
    }

}
