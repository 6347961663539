import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, map, share, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AbandonCartDTO } from 'src/shared/data/dtos/booking/AbandonCartDTO';
import { RemoveOrderLineDTO } from 'src/shared/data/dtos/booking/RemoveOrderLineDTO';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { OrderModel } from 'src/shared/data/models/order_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BookingService } from '../../services/booking_service';
import { UserShoppingCartDTO } from 'src/shared/data/dtos/booking/UserShoppingCartDTO';
import { UserOrderLineDetailsDTO } from 'src/shared/data/dtos/booking/UserOrderLineDetailsDTO';
import { UserOrderDetailsDTO } from 'src/shared/data/dtos/booking/UserOrderDetailsDTO';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';

@Component({
  selector: 'app-sharedbasket',
  templateUrl: './sharedbasket.component.html',
})
export class SharedBasketComponent implements OnInit {

  @Input() businessId: string;
  @Input() hideCTAWhenEmpty: boolean;
  business: BusinessPublicModel;
  cart$: Observable<UserShoppingCartDTO>;
  imagesUrl = environment.imagesUrl;
  isAppUser: boolean = false;
  error: ErrorModel;

  constructor(
    private bookingService: BookingService,
    private auth: AuthenticationService,
    private modalService: ModalService,
    private notifyService: NotifyService,
    private navigate: NavigationService,
    private appComms: CommunitcateWithAppService
  ) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.load();
  }

  startTimers(orders: UserShoppingCartDTO) {
    this.bookingService.startTimers(orders);
  }

  removeOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: orderLine.removeFromCartMessageDescription,
      header: orderLine.removeFromCartMessageHeader,
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processRemoveOrderLine(order, orderLine);
      }
    }
    this.modalService.show(settings);
  }


  async processRemoveOrderLine(order: UserOrderDetailsDTO, orderLine: UserOrderLineDetailsDTO) {
    var settings: ModalModel = {
      header: "Removing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var removeOrderLineDTO: RemoveOrderLineDTO = {
      businessId: order.businessId,
      orderLineId: orderLine.id
    }
    this.bookingService.removeOrderLine(removeOrderLineDTO)
      .then(async () => {
        this.modalService.hide();
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      });
  }

  handlerError(message: string) {
    this.notifyService.notify("Error", message, NotifyType.ERROR);
  }

  goToCheckout(order: OrderModel) {
    this.modalService.hide();
    this.navigate.navigate(WildNavigationType.BOOKINGUSERDETAILS, {
      businessId: order.businessId
    })
  }

  backToStartOnApp() {
    this.appComms.backToStartOnApp();
  }

  clearBasket(order: UserOrderDetailsDTO) {
    var settings: ModalModel = {
      disableBodyScroll: true,
      design: ModalDesign.CENTRAL,
      type: ModalType.WARNING,
      description: "Are you sure you want to clear the basket?",
      header: "Are you sure?",
      close: true,
      ctaonetext: "Yes",
      ctatwotext: "No",
      ctaonetextclick: async () => {
        this.processClearBasket(order);
      }
    }
    this.modalService.show(settings);
  }

  async processClearBasket(order: UserOrderDetailsDTO) {
    var settings: ModalModel = {
      header: "Clearing",
      description: "Please wait",
      design: ModalDesign.LOADING
    }
    this.modalService.show(settings);
    var abandonCartDTO: AbandonCartDTO = {
      businessId: order.businessId
    }
    this.bookingService.abandonCart(abandonCartDTO)
      .then(async () => {
        this.modalService.hide();
        this.load();
      })
      .catch((error) => {
        this.handlerError(error.message);
      });
  }

  load() {
    this.cart$ = null;
    this.cart$ = this.bookingService.getBasketOrders(this.businessId).pipe(
      take(1),
      tap(orders => this.startTimers(orders)),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  search() {
    this.navigate.navigate(WildNavigationType.SEARCH);
  }

}
