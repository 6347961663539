<ng-container *ngIf="cart$ | async as cart; else loadingOrError">
  <div *ngIf="!cart || cart.carts?.length == 0 ; else cartsLoaded">
    <app-empty icon="shopping-bag" header="Cart is empty" ctatext="Services" [showcta]="!hideCTAWhenEmpty && isAppUser"
      (onClick)="backToStartOnApp()"></app-empty>
  </div>
  <ng-template #cartsLoaded>
    <h1 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Shopping Cart</h1>
    <div *ngFor="let cart of cart.carts">
      <div class=" mb-3 mt-12 ">
        <h2 class="text-xl font-semibold mb-2">{{cart.business}}</h2>
        <div *ngIf="cart?.expired">
          <h2 class="text-sm font-medium text-red-500">Order Expired</h2>
          <p (click)="search()" class="text-gray-400 text-xs underline cursor-pointer">go to search</p>
        </div>
        <div *ngIf="!cart?.expired">
          <span *ngIf="cart?.countdown" class="text-gray-400 text-xs">reserved for</span>
          <span *ngIf="!cart?.countdown" class="text-gray-400 text-xs">...loading</span>
          <span *ngIf="cart?.countdown" class="text-sm font-medium text-red-500"> {{cart["countdown"] ??
            ''}}</span>
        </div>
      </div>
      <form *ngIf="!cart?.expired" class="lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
        <section aria-labelledby="cart-heading" class="lg:col-span-7">
          <ul role="list" class="border-t border-b border-gray-200 divide-y divide-gray-200">
            <li *ngFor="let orderLine of cart.orderLines" class="flex py-6 sm:py-10">
              <div class="flex-shrink-0">
                <img
                  [src]="orderLine?.image != null ? imagesUrl + orderLine?.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                  alt="Service Image" class="w-20 h-20 rounded-md object-center object-cover">
              </div>
              <div class="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div class="flex justify-between">
                      <h3 class="text-sm">
                        <a href="#" class="font-medium text-gray-700 hover:text-gray-800">
                          {{orderLine.description}}
                        </a>
                      </h3>
                    </div>
                    <div class="mt-1 flex text-sm">
                      <p class="text-gray-500">
                        {{orderLine.priceDescription}}
                      </p>
                    </div>
                    <p class="mt-1 text-sm font-medium text-gray-900">{{orderLine.formattedTotal}}
                    </p>
                    <div *ngIf="orderLine.activity">
                      <div class="font-medium text-gray-700 hover:text-gray-800 text-sm mt-3">
                        {{orderLine.activity.readableLocalStartDateOnly}}
                      </div>
                      <p class="text-gray-500 text-sm">{{orderLine.activity.readableLocalTimeRange}}</p>
                      <div class="mt-2" *ngFor="let teamMember of orderLine.activity.teamMembers">
                        <span class="flex-shrink-0 group block">
                          <div class="flex items-center">
                            <div>
                              <img class="inline-block h-9 w-9 rounded-full"
                                [src]="teamMember?.image != null ? imagesUrl + teamMember?.image?.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                                alt="">
                            </div>
                            <div class="ml-3">
                              <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                {{teamMember?.firstName}} {{teamMember?.surname}}</p>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 sm:mt-0 sm:pr-9">
                    <!-- <select id="quantity-0" name="quantity-0"
                      class="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select> -->

                    <div class="absolute top-0 right-0">
                      <button (click)="removeOrderLine(cart,orderLine)" type="button"
                        class="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Remove</span>
                        <!-- Heroicon name: solid/x -->
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                          aria-hidden="true">
                          <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <p class="mt-4 flex text-xs text-gray-700 space-x-2">
                  <!-- Heroicon name: solid/check -->
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span>Reserved for you</span>
                </p>
              </div>
            </li>
          </ul>
        </section>

        <!-- Order summary -->
        <section aria-labelledby="summary-heading" class="mt-16  lg:mt-0 lg:col-span-5">
          <div class="bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8">
            <h2 id="summary-heading" class="text-lg font-medium text-gray-900">Order summary</h2>

            <dl class="mt-6 space-y-4">
              <div class="flex items-center justify-between">
                <dt class="text-sm text-gray-600">
                  Subtotal
                </dt>
                <dd class="text-sm font-medium text-gray-900">
                  {{cart.formattedTotal}}
                </dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm text-gray-600">
                  Tax
                </dt>
                <dd class="text-sm font-medium text-gray-900">
                  {{cart?.formattedTaxes}}
                </dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm text-gray-600">
                  Fees
                </dt>
                <dd class="text-sm font-medium text-gray-900">
                  {{cart.formattedFees}}
                </dd>
              </div>
              <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt class="text-base font-medium text-gray-900">
                  Order total
                </dt>
                <dd class="text-base font-medium text-gray-900">
                  {{cart.formattedGrandTotal}}
                </dd>
              </div>
            </dl>
            <div class="mt-6">
              <button (click)="goToCheckout(cart)"
                class="w-full wild-bg-primary border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">Checkout</button>
            </div>
            <div class="mt-6 text-center cursor-pointer">
              <a (click)="clearBasket(cart)" class="text-red-500">Clear Basket</a>
            </div>
          </div>
          <p class="flex justify-center text-sm font-medium text-gray-500 mt-6">
            <!-- Heroicon name: solid/lock-closed -->
            <svg class="w-5 h-5 text-gray-400 mr-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd" />
            </svg>
            Secure Payments by Stripe
          </p>
        </section>
      </form>
    </div>
  </ng-template>
</ng-container>
<ng-template #loadingOrError>
  <ng-container *ngIf="error; else loading">
    <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
    </app-retry>
  </ng-container>
  <ng-template #loading>
    <app-loader [disablescreenheight]="true"></app-loader>
  </ng-template>
</ng-template>