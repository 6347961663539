import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, take } from 'rxjs/operators';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { BusinessService } from 'src/wild/modules/business/services/business_service/business_service';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';
import { BusinessPublicService } from '../../services/business_public_service';

@Injectable({
  providedIn: 'root'
})
export class BookingHomeResolver implements Resolve<BusinessPublicModel> {
  constructor(
    private businessPublicService: BusinessPublicService,
    private notify: NotifyService
  ) { }
  resolve(route: ActivatedRouteSnapshot,): Observable<BusinessPublicModel> {
    return this.businessPublicService.getBusinessByUrl(route.params.businessurl).pipe(
      concatMap(business_public => business_public != null ? of(business_public) : of(null)),
      catchError((error: ErrorModel) => {
        this.notify.notify("Error fetching Swim Spot", error.message, NotifyType.ERROR);
        return of(null);
      })
    );
  }
}