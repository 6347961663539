import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MapsService {
	constructor(private httpClient:HttpClient) {}

    loadMaps() : Observable<boolean>{
        return this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyCIOzcnuFGieg7lJctF2QcbEfAqcVCxeOo', 'callback')
        .pipe(
          map(() => true),
          catchError((err) => throwError(err)),
        );
    }

}
 