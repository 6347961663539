import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetCartFromSecretDTO } from 'src/shared/data/dtos/booking/GetCartFromSecretDTO';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { OrderModel } from 'src/shared/data/models/order_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { BookingService } from '../../services/booking_service';
import { UserOrderDetailsDTO } from 'src/shared/data/dtos/booking/UserOrderDetailsDTO';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { UserActivityDetailsDTO } from 'src/shared/data/dtos/booking/UserActivityDetailsDTO';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { CommunitcateWithAppService } from 'src/shared/services/communicatewithapp/communicate_with_app_service';
import { id } from 'date-fns/locale';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit {

  business: BusinessPublicModel;
  isAppUser: boolean = false;
  order$: Observable<UserOrderDetailsDTO>;
  error: ErrorModel;
  imagesUrl = environment.imagesUrl;

  constructor(
    private auth: AuthenticationService,
    private appComms: CommunitcateWithAppService,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private navigate: NavigationService
  ) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.load();
  }

  load() {
    this.order$ = null;
    this.route.queryParams.subscribe((params: Params) => {
      if (params.payment_intent_client_secret != null && params.payment_intent_client_secret != undefined && params.payment_intent_client_secret != "") {
        var secretDTO: GetCartFromSecretDTO = {
          clientSecret: params.payment_intent_client_secret
        }
        this.order$ = this.bookingService.getCartFromSecret(secretDTO)
          .pipe(
            take(1),
            catchError(err => {
              this.error = err;
              return throwError(err);
            })
          );
      } else if (params.orderId != null && params.orderId != undefined && params.orderId != "") {
        this.order$ = this.bookingService.getOrder(params.orderId)
          .pipe(
            take(1),
            catchError(err => {
              this.error = err;
              return throwError(err);
            })
          );
      }
    });
  }

  orders() {
    if (this.isAppUser) {
      this.appComms.goToOrders();
    } else {
      this.navigate.navigate(WildNavigationType.USERORDERS)
    }
  }
  activities() {
    if (this.isAppUser) {
      this.appComms.goToActivities();
    } else {
      this.navigate.navigate(WildNavigationType.USERACTIVITIES)
    }
  }

  openActivity(activity: UserActivityDetailsDTO) {
    this.navigate.navigate(WildNavigationType.USERACTIVITYDETAILS, {}, activity.id);
  }

  openCoupons() {
    this.navigate.navigate(WildNavigationType.USERVOUCHERS, {});
  }

}
