import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { PanelModel } from 'src/shared/services/panel/panel_model';
import { PanelService } from 'src/shared/services/panel/panel_service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  @ViewChild('panel') panel: ElementRef;

  constructor(
    private panelService: PanelService,
    private renderer: Renderer2
  ) {
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   //Some janky code here... the dewlay of the animation allows this to work
    // Breaks when using the modal from the panel... needs some thought
    //   if (this.panel && this.showAnimation == true) {
    //     if (!this.panel.nativeElement.contains(e.target)) {
    //       this.close();
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
    this.panelService.registerPanel(this);
  }

  goBack() {
    var panelToGoBackTo = this.panelService.panels[this.panelService.panels.length - 2];
    //Remove current panel
    this.panelService.panels.splice(this.panelService.panels.length - 1);
    //Remove panel returning too as it will get added again
    this.panelService.panels.splice(this.panelService.panels.length - 1);
    //show
    this.panelService.show(panelToGoBackTo);
  }

  get panelBackText(): string {
    var panelToGoBackTo = this.panelService.panels[this.panelService.panels.length - 2];
    if (this.panelService.panels.length > 1) {
      return `Back to ${panelToGoBackTo.header}`;
    } else {
      return null;
    }
  }

  @ViewChild('panelBody', { read: ViewContainerRef }) dynamicTarget: ViewContainerRef;

  get show(): boolean {
    return this.panelService.showPanel;
  }

  get showAnimation(): boolean {
    return this.panelService.showPanelAnimation;
  }

  get settings(): PanelModel {
    return this.panelService.settings;
  }

  close() {
    this.panelService.panels = [];
    this.panelService.hide();
  }

  // ctaOneClick(){
  //   this.modalService.hide();
  //   if(this.settings.ctaonetextclick)
  //     this.settings.ctaonetextclick();
  // }

}
