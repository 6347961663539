import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-modaltopper',
  template: `
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img *ngIf="logo" class="mx-auto h-12 w-auto hidden md:block"
        src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/icons%2Fw-icon.png?alt=media&token=5eae8376-7952-4891-b550-11117b277543"
        alt="Wild">
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{header}}
    </h2>
    <p *ngIf="parapraph" class="mt-2 text-center text-sm text-gray-600">
        {{parapraph}}
    </p>
    <p *ngIf="ctatext" class="mt-2 text-center text-sm text-gray-600">
        Or
        <a (click)="click()" class="wild-link">
            {{ctatext}}
        </a>
    </p>
  </div>
  `,
})
export class ModalTopperComponent {
  @Input() header: string;
  @Input() parapraph: string;
  @Input() ctatext: string;
  @Input() logo: boolean;
  @Output() onClick = new EventEmitter();
  click() {
    this.onClick.emit();
  }
}
