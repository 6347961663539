<app-wild-header-checkout [businessId]="businessId" backText="Back" (onBackClick)="back()"></app-wild-header-checkout>
<div class="flex items-center justify-center pt-4">
    <nav aria-label="Progress">
        <ol role="list" class="flex items-center">
            <li (click)="goToCart()" class="cursor-pointer relative pr-8 sm:pr-20">
                <!-- Completed Step -->
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full wild-bg-primary"></div>
                </div>
                <a
                    class="relative w-8 h-8 flex items-center justify-center wild-bg-primary rounded-full hover:bg-indigo-900">
                    <!-- Heroicon name: solid/check -->
                    <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Step 2</span>
                </a>
            </li>

            <li class="relative pr-8 sm:pr-20">
                <!-- Current Step -->
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-gray-200"></div>
                </div>
                <a class="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-900 rounded-full"
                    aria-current="step">
                    <span class="h-2.5 w-2.5 wild-bg-primary rounded-full" aria-hidden="true"></span>
                    <span class="sr-only">Step 3</span>
                </a>
            </li>
            <li class="relative">
                <!-- Upcoming Step -->
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="h-0.5 w-full bg-gray-200"></div>
                </div>
                <a href="#"
                    class="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                    <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                        aria-hidden="true"></span>
                    <span class="sr-only">Step 5</span>
                </a>
            </li>


        </ol>
    </nav>
</div>
<div class="max-w-2xl mx-auto py-8 px-4 sm:px-6 mb-16">


    <app-loader *ngIf="loadingPage"></app-loader>
    <div *ngIf="!loadingPage && carts">
        <div *ngIf="carts?.carts?.length == 0;">
            <app-empty icon="shopping-bag" header="Cart is empty" ctatext="Services"
                [showcta]="!hideCTAWhenEmpty && isAppUser" (onClick)="backToStartOnApp()"></app-empty>
        </div>
        <div *ngFor="let cart of carts.carts">
            <h2 class="text-xl font-semibold  mt-1">Attendee Details</h2>
            <p class="mb-3">For your booking with {{cart.business}}</p>
            <div>
                <ul role="list" class="divide-y divide-gray-200 border-t border-b border-gray-200">
                    <li *ngFor="let orderLine of cart.orderLines">
                        <div *ngIf="orderLine.activity" class="mt-4 py-6 sm:py-10 relative">
                            <div class="flex relative">
                                <div class="absolute top-0 right-0">
                                    <button (click)="removeOrderLine(cart,orderLine)" type="button"
                                        class="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500">
                                        <span class="sr-only">Remove</span>
                                        <!-- Heroicon name: solid/x -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                            fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                                <div class="hidden sm:block flex-shrink-0">
                                    <img [src]="orderLine?.image != null ? imagesUrl + orderLine?.image?.image + '_640x640.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                                        alt="" class=" h-16 w-16 rounded-lg object-cover object-center sm:h-24 sm:w-24">
                                </div>

                                <div class="sm:pl-4 flex-1 ">
                                    <h3 class="text-sm leading-tight">
                                        <a
                                            class="font-medium text-gray-700 hover:text-gray-800">{{orderLine.description}}</a>
                                    </h3>
                                    <p class="mt-1 text-xs leading-tight text-gray-500">{{orderLine.priceDescription}}
                                    </p>
                                    <div>
                                        <div class="font-medium text-gray-700 hover:text-gray-800 text-sm mt-3">
                                            {{orderLine.activity.readableLocalStartDateOnly}}
                                        </div>
                                        <p class="text-gray-500 text-sm">{{orderLine.activity.readableLocalTimeRange}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="h-11">
                                <div *ngIf="orderLine.bookingProfileComplete == false"
                                    class="border-l-4 border-yellow-400 bg-yellow-50 p-2 my-2">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <!-- Heroicon name: mini/exclamation-triangle -->
                                            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                    d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <p class="text-xs text-yellow-700">
                                                Missing details on
                                                <a (click)="editAttendee(orderLine)"
                                                    class="cursor-pointer font-medium text-yellow-700 underline hover:text-yellow-600">booking
                                                    profile</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="orderLine.bookingForMe != true && !orderLine?.linkedAccount"
                                    class="rounded-md bg-blue-50 p-2 my-2">
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <!-- Heroicon name: mini/information-circle -->
                                            <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                    d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div class="ml-3 flex-1 md:flex md:justify-between">
                                            <p class="text-sm text-blue-700">Select an attendee</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="flex flex-col md:flex-row justify-between items-center">
                                <select (change)="updateAttendee($event,orderLine.id)"
                                    [ngClass]="{'md:w-3/4': orderLine.bookingForMe == true || orderLine?.linkedAccount}"
                                    class="w-full  block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <option *ngIf="orderLine.bookingForMe != true && !orderLine?.linkedAccount"
                                        value="">
                                        please select</option>
                                    <option [selected]="orderLine.bookingForMe == true" value="me">
                                        Booking for myself
                                    </option>
                                    <option [selected]="account.id===orderLine?.linkedAccount?.id"
                                        *ngFor="let account of linkedaccounts" [value]="account.id">
                                        {{account.firstName}}
                                        {{account.surname}}
                                    </option>
                                    <option value="addnew">+ add new person</option>
                                </select>
                                <div *ngIf="orderLine.bookingForMe == true || orderLine?.linkedAccount"
                                    class="mt-4 md:mt-0">
                                    <button (click)="editAttendee(orderLine)" type="button "
                                        class="text-sm font-medium text-blue-500 hover:text-blue-700 cursor-pointer">
                                        <span>edit details</span>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </li>
                </ul>
            </div>
            <div class="mt-10">
                <div *ngIf="!cart.allHaveAttendees" class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: mini/exclamation-triangle -->
                            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p class="text-sm text-yellow-700">
                                Please choose and add attendee details
                            </p>
                        </div>
                    </div>
                </div>
                <button [ngClass]="{'opacity-20':!cart.allHaveAttendees}" [disabled]="!cart.allHaveAttendees"
                    (click)="goToCheckout()" type="button"
                    class="disabled:opacity-25 w-full rounded-md border border-transparent wild-bg-primary py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                    <span *ngIf="goingToCheckout" class="loader"></span>
                    <span *ngIf="!goingToCheckout">Checkout</span>
                </button>
            </div>
        </div>

    </div>
</div>