import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  template: `
  <div [formGroup]="form">
  <div class="relative flex items-start">
      <div class="flex items-center h-5">
          <input type="checkbox" [formControlName]="name" [formControlName]="name" [name]="name"
              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
      </div>
      <div class="ml-3 text-sm">
          <label for="monday" class="font-medium text-gray-700">{{label}}</label>
      </div>
  </div>
</div>`
})
export class CheckboxComponent implements OnInit {

  @Input() label!: string;
  @Input() form!: FormGroup;
  @Input() name!: string;
  @Input() value!: any;
  constructor() { }
  ngOnInit(): void {
    if (this.value != undefined && this.value != null)
      this.form.controls[this.name].setValue(this.value);
  }

}
