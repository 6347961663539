export enum MessageType {
    UNKNOWN,
    CONTACTWELCOME,
    SWIMSPOTPOST,
    BUSINESSPOST,
    EVENTPOST,
    SWIMSPOTPOSTCOMMENT,
    BUSINESSPOSTCOMMENT,
    EVENTPOSTCOMMENT,
    SWIMSPOTALERT,
    SWIMSPOTREVIEW,
    BADGE,
    POSTLIKE,
    ALERTLIKE,
    REVIEWLIKE,
    SWIMSPOTIMAGELIKE,
    SWIMSPOTLIKE,
    NEWSWIMSPOT,
    SWIMSPOTAPPROVED,
    SWIMSPOTPOSTV2,
    SWIMSPOTPOSTCOMMENTV2,
    SWIMSPOTALERTV2,
    SWIMSPOTREVIEWV2,
    SWIMSPOTREVIEWCOMMENTV2,
    SWIMSPOTALERTCOMMENTV2,
    SWIMSPOTIMAGECOMMENTV2,
    NEWACTIVITY,
    ACTIVITYLIKE,
    ACTIVITYCOMMENT,
    NEWFOLLOWER
}