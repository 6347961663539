import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputgroup',
  templateUrl: './inputgroup.component.html',
  styleUrls: ['./inputgroup.component.scss']
})
export class InputgroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
