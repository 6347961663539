import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Route
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services/auth/auth_service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<firebase.User> {
  constructor(private auth: AuthenticationService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<firebase.User> {
    return this.auth.checkUser().pipe(first())
  }
}