<div class="mt-6 flex items-center justify-between">
    <div class="flex items-center">
        <input id="remember-me" name="remember-me" type="checkbox"
            class="h-4 w-4 wild-text-primary border-gray-300 rounded">
        <label for="remember-me" class="ml-2 block text-sm text-gray-900">
            Remember me
        </label>
    </div>
    <div class="text-sm">
        <a (click)="click()" class="wild-link">
            {{linktext}}
        </a>
    </div>
</div>