import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserCouponsDTO } from 'src/shared/data/dtos/coupons/UserCouponsDTO';
import { HttpService } from 'src/shared/services/http/http_service';

@Injectable({
  providedIn: 'root'
})
export class UserCouponsService {

  constructor(
    private http: HttpService,
  ) { }

  list(): Observable<UserCouponsDTO> {
    return this.http.get(`${environment.api}/user/coupons`);
  }

  listForOrder(businessId: string): Observable<UserCouponsDTO> {
    return this.http.get(`${environment.api}/user/coupons/fororder?businessId=${businessId}`);
  }

}

