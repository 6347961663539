import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { FirestoreService } from 'src/shared/services/firestore/firestore.service';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { OrderModel } from 'src/shared/data/models/order_model';
import { OrderStatus } from 'src/shared/data/enums/order_status';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { SharedBasketComponent } from './sharedbasket/sharedbasket.component';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { BookingService } from '../services/booking_service';
import { UserShoppingCartDTO } from 'src/shared/data/dtos/booking/UserShoppingCartDTO';
import { UserOrderDetailsDTO } from 'src/shared/data/dtos/booking/UserOrderDetailsDTO';

@Component({
  selector: 'app-wild-header-checkout-basketicon',
  template: `
  <div *ngIf="isLoggedIn" class="ml-4  lg:ml-6 flex justify-end items-center w-full pr-4">
    <span class="mr-3 flex flex-col justify-end text-right" *ngIf="!hideCountdown && order">
          <span *ngIf="order?.countdown && order?.countdown != '00' && numberOfOrderLines > 0" class="text-gray-400 text-xs">reserved for</span>
          <span *ngIf="order?.countdown && order?.countdown != '00' && numberOfOrderLines > 0" class="text-sm font-medium text-red-500"> {{order["countdown"] ??
            ''}}</span>
          <span *ngIf="order?.expired && numberOfOrderLines > 0" class="text-sm font-medium text-red-500"> expiring </span>
    </span>
    <a *ngIf="!hideCartIcon" (click)="openCart()" class="cursor-pointer group -m-2 p-2 flex items-center">
      <svg class="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
      </svg>
      <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{numberOfOrderLines}}</span>
      <span class="sr-only">items in cart, view bag</span>
    </a>
  </div>
  `
})
export class WildCheckoutHeaderBacketIconComponent implements OnInit {

  @Input() businessId: string;
  numberOfOrderLines: number = 0;
  isAppUser: boolean = false;
  order: UserOrderDetailsDTO;
  @Input() hideCTAWhenEmpty: boolean = false;
  @Input() hideCartIcon: boolean = false;
  @Input() hideCountdown: boolean = false;

  constructor(private auth: AuthenticationService,
    private modalService: ModalService,
    private authService: AuthenticationService,
    private bookingService: BookingService,
    private firestore: FirestoreService) {
  }


  logoClick() { }



  listenForOrders() {
    var userId = this.auth.getLoggedInCookie();
    var now = new Date();
    this.firestore.listenerByQueryForCollection("orders", [
      {
        operator: "==",
        property: "businessId",
        type: FirestoreQueryType.WHERE,
        value: this.businessId
      },
      {
        operator: "==",
        property: "contactId",
        type: FirestoreQueryType.WHERE,
        value: userId
      },
      {
        operator: ">=",
        property: "cartUpdated",
        type: FirestoreQueryType.WHERE,
        value: now
      },
      {
        operator: "==",
        property: "status",
        type: FirestoreQueryType.WHERE,
        value: OrderStatus.SHOPPINGCART
      }
    ]).subscribe(async (orders: OrderModel[]) => {
      var numberOfOrderLines = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        const orderLines = await this.firestore.getListFromSubcollectionByQuery("orders", order.id, "orderlines", []).toPromise();
        for (let y = 0; y < orderLines.length; y++) {
          numberOfOrderLines = numberOfOrderLines + 1;
        }
      }
      if (numberOfOrderLines != this.numberOfOrderLines)
        this.checkTimer();
      this.numberOfOrderLines = numberOfOrderLines;
    })
  }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
    this.listenForOrders();
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  async checkTimer() {
    try {
      var cart = await this.bookingService.getBasketOrders(this.businessId).toPromise();
      if (cart?.carts && cart?.carts?.length > 0) {
        this.order = cart.carts[0];
        this.startTimer(this.order);
      }
    } catch (error) {

    }
  }

  startTimer(orders: UserOrderDetailsDTO) {
    this.bookingService.startTimerForUserShoppingCart(orders);
  }

  openCart() {
    var settings: ModalModel = {
      close: true,
      large: true,
      design: ModalDesign.COMPONENT,
      component: SharedBasketComponent,
      componentInputs: [
        {
          inputName: "businessId",
          value: this.businessId,
        },
        {
          inputName: "hideCTAWhenEmpty",
          value: this.hideCTAWhenEmpty,

        }
      ]
    }
    this.modalService.show(settings);
  }

}
