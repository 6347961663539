import { Component, OnInit } from '@angular/core';
import { NotifyModel } from 'src/shared/services/notify_service/notify_model';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {

  constructor(
    private notifyService: NotifyService
  ) { }

  notifications: NotifyModel[] = this.notifyService.notifications;
  NotifyType = NotifyType;

  ngOnInit(): void {
  }

  remove(id: number) {
    this.notifyService.removeById(id);
  }

}
