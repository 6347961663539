import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-usernamepage',
  template: `
    <app-registrationpagecontainer [src]="src">
      <app-username [isPage]="true"></app-username>
    </app-registrationpagecontainer>
  `
})
export class UsernamepageComponent implements OnInit {

  src: string = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FUsername.png?alt=media&token=ad8a5343-dbc7-40d1-a1ea-fcf334577c0f";

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.business == 1)
        this.src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FUsername.png?alt=media&token=ad8a5343-dbc7-40d1-a1ea-fcf334577c0f";
    })
  }
}