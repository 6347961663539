import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Route
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth/auth_service';

@Injectable({
  providedIn: 'root'
})
export class CustomTokenResolver implements Resolve<any> {
  constructor(private auth: AuthenticationService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const token: string = route.queryParams["token"];
    if (token != undefined && token != "") {
      return this.auth.loginWithCustomToken(token).pipe(first());
    } else {
      return this.auth.checkUser().pipe(first())
    }
  }
}