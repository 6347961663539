import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommunitcateWithAppService {
    constructor() { }

    send(title: string, message: string) {
        try {
            window["flutterchannel"].postMessage(`${title}:::${message}`)
        } catch (error) {

        }
    }

    url(link: string) {
        this.send("url", link);
    }


    pop() {
        this.send("close", "");
    }

    backToStartOnApp() {
        this.send("backtostart", "");
    }

    goToActivities() {
        this.send("gotoactivities", "");
    }

    goToOrders() {
        this.send("gotoorders", "");
    }
}


