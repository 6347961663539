import { ComponentFactoryResolver, ComponentRef, Injectable, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { PanelComponent } from 'src/shared/components/panel/panel.component';
import { PanelModel } from './panel_model';
import { PanelType } from './panel_type';
@Injectable({
    providedIn: 'root'
})
export class PanelService {

    showPanel: boolean = false;
    showPanelAnimation: boolean = false;
    settings: PanelModel;
    private panel: PanelComponent;
    private componentRef: ComponentRef<any>;
    panels: PanelModel[] = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    show(settings: PanelModel) {
        this.panels.push(settings);
        this.settings = settings;
        this.clearOldComponentIfExists();
        if (this.settings.component != null)
            this.open(this.settings.component)
        this.showPanel = true;
        setTimeout(() => {
            this.showPanelAnimation = true;
        }, 30);
    }

    hide() {
        if (this.panels.length > 0)
            this.panels.splice(this.panels.length - 1, 1);
        console.log("hide", this.panels);
        this.showPanelAnimation = false;
        setTimeout(() => {
            this.showPanel = false;
        }, 30)
    }

    registerPanel(newPanel: PanelComponent): void {
        this.panel = newPanel;
    }

    clearOldComponentIfExists() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    open(component: any): PanelComponent {
        let factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = this.panel.dynamicTarget.createComponent(factory);
        if (this.settings.componentInputs != null && this.settings.componentInputs.length > 0) {
            for (let i = 0; i < this.settings.componentInputs.length; i++) {
                const input = this.settings.componentInputs[i];
                this.componentRef.instance[input.inputName] = input.value;
            }
        }
        if (this.settings.componentOutputs != null && this.settings.componentOutputs.length > 0) {
            for (let i = 0; i < this.settings.componentOutputs.length; i++) {
                const output = this.settings.componentOutputs[i];
                const sub: Subscription = this.componentRef.instance[output.outputName].subscribe(output.func);
                this.componentRef.onDestroy(() => { sub.unsubscribe(); console.log("Unsubscribing from panel output listener") });
            }
        }
        return this.panel;
    }

}
