import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';

@Component({
  selector: "app-continueshopping",
  template: `
  <div class="flex flex-col items-center justify-center h-48">
    <button (click)="goToCheckout()" class="wild-btn-primary mb-4 cursor-pointer w-full" style="max-width: 225px;">Continue</button>
    <button (click)="stay()" class="wild-btn-light mb-4 cursor-pointer w-full" style="max-width: 225px;">Continue shopping</button>
  </div>
  `
})
export class ContinueShoppingComponent implements OnInit {

  @Input() businessId: string;

  constructor(
    private navigation: NavigationService,
    private modelService: ModalService
  ) { }

  ngOnInit(): void {

  }

  stay() {
    this.modelService.hide();
  }

  goToBasket() {
    this.navigation.navigate(WildNavigationType.BOOKINGBASKET)
  }

  goToCheckout() {
    this.navigation.navigate(WildNavigationType.BOOKINGUSERDETAILS, {
      businessId: this.businessId
    })
  }
}
