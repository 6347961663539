import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlreadyAuthenticatedGuard } from './guards/already_authenticated.guard';
import { UsernamepageComponent } from './pages/usernamepage.component';
import { LoginpageComponent } from './pages/loginpage.component';
import { RegisterpageComponent } from './pages/registerpage.component';
import { ForgottenpageComponent } from './pages/forgottenpage.component';
import { WhereToComponent } from './components/whereto.component';

const routes: Routes = [
  {
    path: "login",
    canActivate: [AlreadyAuthenticatedGuard],
    component: LoginpageComponent,
  },
  {
    path: "register",
    canActivate: [AlreadyAuthenticatedGuard],
    component: RegisterpageComponent,
  },
  {
    path: "forgotten",
    canActivate: [AlreadyAuthenticatedGuard],
    component: ForgottenpageComponent,
  },
  {
    path: "username",
    canActivate: [AlreadyAuthenticatedGuard],
    component: UsernamepageComponent,
  },
  {
    path: "whereto",
    canActivate: [], 
    component: WhereToComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
