import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { environment } from 'src/environments/environment';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { UserCouponsService } from 'src/wild/modules/user/services/usercoupons_service';
import { UserCouponsDTO } from 'src/shared/data/dtos/coupons/UserCouponsDTO';
import { UserCouponDTO } from 'src/shared/data/dtos/coupons/UserCouponDTO';

@Component({
  selector: 'app-availablevouchers',
  templateUrl: './availablevouchers.component.html',
  styleUrls: ['./availablevouchers.component.scss']
})
export class AvailableVouchersComponent implements OnInit {

  public error: ErrorModel = null;
  @Input() businessId: string;
  @Output() apply = new EventEmitter<string>();
  coupons$: Observable<UserCouponsDTO>;
  imagesUrl = environment.imagesUrl;

  constructor(
    private userCouponService: UserCouponsService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.coupons$ = null;
    this.coupons$ = this.userCouponService.listForOrder(this.businessId).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    );
  }

  click(coupon: UserCouponDTO) {
    var couponCode = coupon.code;
    this.apply.emit(couponCode);
  }

  //   async openSwimSpot(id) {
  //     var settings: ModalModel = {
  //       header: "Opening",
  //       description: "Please wait",
  //       design: ModalDesign.LOADING
  //     }
  //     this.modalService.show(settings);
  //     var swimspot = await this.swimspotDate.read(id).toPromise();
  //     this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, swimspot.canonical);
  //     this.modalService.hide();
  //   }

}
