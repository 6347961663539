import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import { DatesService } from 'src/shared/services/dates_service/dates_service';

@Component({
  selector: 'app-formdatepicker',
  templateUrl: './formdatepicker.component.html',
  styleUrls: ['./formdatepicker.component.scss']
})
export class FormdatepickerComponent implements OnInit {

  @Input() form!: any;
  @Input() name!: string;
  @Input() minyear!: number;
  @Input() hideHeaders: boolean;
  @Input() maxyear!: number;
  @Input() description!: string;
  @Input() placeholder!: string;
  @Input() value!: any;
  required: boolean;
  @Input() span!: number;

  @HostBinding('class') get t() {
    return "p-0 border-0 bg-transparent sm:col-span-" + this.span;
  };

  options: DatepickerOptions = {
    // minYear: getYear(new Date()) - 30, // minimum available and selectable year
    // maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: this.placeholder, // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'LLLL do yyyy', // date format to display in input
    formatTitle: 'LLLL yyyy',
    formatDays: 'EEEEE',
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    //SOME STYLES OVER WROTE IN THE SCSS FILE
    calendarClass: 'datepicker-blue', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#031d44', // in case you customize you theme, here you define scroll bar color
    // enable keyboard events
  };

  constructor(
    private dateService: DatesService
  ) { }

  ngOnInit(): void {
    this.options.placeholder = this.placeholder ?? "";
    if (this.minyear) {
      this.options.minYear = this.minyear;
    }
    if (this.maxyear) {
      this.options.maxYear = this.maxyear;
    }
    if (!this.value) {
      if (this.form.controls[this.name].value != null && this.form.controls[this.name].value != "") {
        var date = this.dateService.getDateObjectFromIsoString(this.form.controls[this.name].value);
        this.value = date;
      } else {
        this.value = null;
      }
    } else {
      var date = this.dateService.getDateObjectFromIsoString(this.value);
      this.value = date;
      this.form.controls[this.name].setValue(this.dateService.getIsoDateFromObject(date));
    }
    const form_field = this.form.get(this.name);
    if (form_field.validator) {
      const validator = form_field.validator({} as AbstractControl);
      if (validator && validator.required)
        this.required = true;
    }
  }

  onChange(date: Date) {
    this.value = date;
    this.form.controls[this.name].setValue(this.dateService.justDate(date));
  }

}
