import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreCollectionGroup, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take, timeout } from 'rxjs/operators';
import { ErrorType } from 'src/shared/data/enums/error_type';
import { FirestoreQueryType } from 'src/shared/data/enums/firestore_query_type';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { FirestoreQuery } from 'src/shared/data/models/firestore_query_model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private readonly afs: AngularFirestore,
  ) { }

  timeout = 10000;

  async create(collection: string, data: any, id: string = ""): Promise<string> {
    var doc = await this.afs.collection(collection).add(data)
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
    if (doc !== undefined && doc !== null) {
      var ref = doc as DocumentReference;
      return ref.id;
    }
    return "";
  }

  async createSubCollectionDocument(collection: string, collectionId: string, subCollection: string, data: any): Promise<string> {
    var doc = await this.afs.collection(collection).doc(collectionId).collection(subCollection).add(data)
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
    if (doc !== undefined && doc !== null) {
      var ref = doc as DocumentReference;
      return ref.id;
    }
    return "";
  }

  async createSubSubCollectionDocument(collection: string, collectionId: string, subCollection: string, subCollectionId: string, subSubCollection: string, data: any): Promise<string> {
    var doc = await this.afs.collection(collection).doc(collectionId).collection(subCollection).doc(subCollectionId).collection(subSubCollection).add(data)
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
    if (doc !== undefined && doc !== null) {
      var ref = doc as DocumentReference;
      return ref.id;
    }
    return "";
  }

  async update(id: string, collection: string, data: any) {
    var ref = this.afs.collection(collection).doc(id);
    await ref.set(data, { merge: true })
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
  }

  async updateSubcollection(id: string, collection: string, subCollection: string, subCollectionId: string, data: any) {
    var ref = this.afs.collection(collection).doc(id).collection(subCollection).doc(subCollectionId);
    await ref.set(data, { merge: true })
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
  }

  async updateSubSubcollection(id: string, collection: string, subCollection: string, subCollectionId: string, subSubCollection: string, subSubCollectionId: string, data: any) {
    var ref = this.afs.collection(collection).doc(id).collection(subCollection).doc(subCollectionId).collection(subSubCollection).doc(subSubCollectionId);
    await ref.set(data, { merge: true })
      .catch((error: any) => {
        this.throwAsyncError(error);
      });
  }

  listener(collection: string, id: string): Observable<any> {
    return this.afs.collection(collection).doc(id).snapshotChanges()
      .pipe(
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map(a => {
          if (a == null || a.payload.data() == null)
            return null;
          const data = a.payload.data() ?? {};
          return data;
        })
      )
  }

  listenerByQueryForCollection(collection: string, query: FirestoreQuery[]): Observable<any> {
    return this.afs.collection<any>(collection, ref => {
      var updatedReference = this.getQueryReferences(ref, query);
      return updatedReference;
    }).snapshotChanges()
      .pipe(
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map(a => {
          if (a == null || a.length == 0)
            return [];
          var array = [];
          for (let i = 0; i < a.length; i++) {
            const element = a[i];
            array.push(
              {
                ...element.payload.doc.data(),
                id: element.payload.doc.id,
              }
            );
          }
          return array;
        })
      )
  }

  listenerByQueryForCollectionGroup(collection: string, query: FirestoreQuery[]): Observable<any> {
    return this.afs.collectionGroup<any>(collection, ref => {
      var updatedReference = this.getQueryReferences(ref, query);
      return updatedReference;
    }).snapshotChanges()
      .pipe(
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map(a => {
          if (a == null || a.length == 0)
            return [];
          var array = [];
          for (let i = 0; i < a.length; i++) {
            const element = a[i];
            array.push(
              {
                ...element.payload.doc.data(),
                id: element.payload.doc.id,
              }
            );
          }
          return array;
        })
      )
  }



  getDocumentById(collection: string, id: string): Observable<any> {
    try {
      var document = this.afs.collection<any>(collection).doc(id);
      return this.getDocument(document);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getSubCollectionDocumentById(collection: string, id: string, subCollection: string, subCollectionId: string): Observable<any> {
    try {
      var document = this.afs.collection<any>(collection).doc(id).collection(subCollection).doc(subCollectionId);
      return this.getDocument(document);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getDocumentByQuery(collection: string, query: FirestoreQuery[]): Observable<any> {
    try {
      var documents = this.afs.collection<any>(collection, ref => {
        var updatedReference = this.getQueryReferences(ref, query);
        return updatedReference;
      });
      return this.getDocumentByCondition(documents);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getListByQuery(collection: string, query: FirestoreQuery[]): Observable<any> {
    try {
      var documents = this.afs.collection<any>(collection, ref => {
        var updatedReference = this.getQueryReferences(ref, query);
        return updatedReference;
      });
      return this.getListByCondition(documents);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getCollectionGroupListByQuery(collection: string, query: FirestoreQuery[]): Observable<any> {
    try {
      var documents = this.afs.collectionGroup<any>(collection, ref => {
        var updatedReference = this.getQueryReferences(ref, query);
        return updatedReference;
      });
      return this.getListByCondition(documents);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getListFromSubcollectionByQuery(collection: string, collectionId: string, subCollection: string, query: FirestoreQuery[]): Observable<any> {
    try {
      var documents = this.afs.collection(collection).doc(collectionId).collection<any>(subCollection, ref => {
        var updatedReference = this.getQueryReferences(ref, query);
        return updatedReference;
      });
      return this.getListByCondition(documents);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  getListFromSubSubcollectionByQuery(collection: string, collectionId: string, subCollection: string, subCollectionId: string, subSubCollection: string, query: FirestoreQuery[]): Observable<any> {
    try {
      var documents = this.afs.collection(collection).doc(collectionId).collection(subCollection).doc(subCollectionId).collection<any>(subSubCollection, ref => {
        var updatedReference = this.getQueryReferences(ref, query);
        return updatedReference;
      });
      return this.getListByCondition(documents);
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  //Returns null or a single document
  private getDocument(document: AngularFirestoreDocument<any>): Observable<any> {
    try {
      return document.get().pipe(
        timeout(this.timeout),
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map(a => {
          if (a == null || a.data() == null)
            return null;
          const data = a.data();
          const id = a.id;
          return { id, ...data };
        })
      );
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  //Returns null or a single document
  private getDocumentByCondition(documents: AngularFirestoreCollection<any>): Observable<any> {
    try {
      return documents.get().pipe(
        timeout(this.timeout),
        catchError((err: any) => {
          var error = this.getError(err);
          return throwError(error);
        }),
        map(a => {
          if (a == null || a.docs == null)
            return null;
          if (a.docs.length == 0)
            return null;
          const data = a.docs[0].data();
          const id = a.docs[0].id;
          return { id, ...data };
        })
      );
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  //Returns [] or array or documents 
  private getListByCondition(documents: AngularFirestoreCollection<any> | AngularFirestoreCollectionGroup<any>): Observable<any> {
    try {
      return documents.get().pipe(
        timeout(this.timeout),
        catchError((err: any) => {
          var error = this.getError(err);

          return throwError(error);
        }),
        map(a => {
          if (a == null || a.docs == null)
            return [];
          var array = [];
          for (let i = 0; i < a.docs.length; i++) {
            const element = a.docs[i];
            array.push(
              {
                ...element.data(),
                id: element.id,
              }
            );
          }
          return array;
        })
      );
    } catch (err) {
      var error = this.getError(err);
      return throwError(error);
    }
  }

  private getError(err: any): ErrorModel {
    var message = "";
    var description = "";
    var code: ErrorType = ErrorType.UNKNOWN;
    var unKnownErrorMessage = "Unknown Error";
    if (err == undefined || err == null || err == "" || err.message == undefined || err.message == "") {
      message = unKnownErrorMessage;
    } else {
      switch (true) {
        case err.message == "Timeout has occurred":
          code = ErrorType.TIMEOUT;
          message = "Network is running slow";
          description = "Please give it another go";
          break;
        case err.message == "Missing or insufficient permissions.":
          code = ErrorType.ACCESSDENIED;
          message = "Access Denied";
          description = "You do not have access to this content, please contact an administrator";
          break;
        case err.message.includes("That index is currently building"):
          code = ErrorType.INDEXBEINGBUILT;
          message = "Index Building";
          description = "Index under construction";
          break;
        case err.message.includes("The query requires an index"):
          code = ErrorType.NEEDSINDEX;
          message = "Index Required";
          description = "This query requires an index";
          break;
        case err.message.includes("Query.where() called with invalid data"):
          code = ErrorType.INVALIDQUERY;
          message = "Invalid Query";
          description = "Invalid parameters sent in query";
          break;
        default:
          code = ErrorType.UNKNOWN;
          message = unKnownErrorMessage;
          description = "";
          break;
      }
    }
    var error: ErrorModel = {
      code: code,
      message: message,
      description: description
    }
    console.log("pre-processed error", err);
    console.log("pre-processed error message", err.message);
    console.log("Processed error", error);
    return error;
  }

  private throwAsyncError(error: any) {
    var errorModel = this.getError(error);
    throw Error(errorModel.message);
  }

  private getQueryReferences(ref: any, query: FirestoreQuery[]) {
    try {
      var updatedReference = ref;
      for (let i = 0; i < query.length; i++) {
        var condition = query[i];
        if (condition.type == FirestoreQueryType.WHERE)
          updatedReference = updatedReference.where(condition.property, condition.operator, condition.value);
        if (condition.type == FirestoreQueryType.ORDERBY)
          updatedReference = updatedReference.orderBy(condition.property, condition.value);
        if (condition.type == FirestoreQueryType.LIMIT)
          updatedReference = updatedReference.limit(condition.value)
        if (condition.type == FirestoreQueryType.ARRAYCONTAINS)
          updatedReference = updatedReference.where(condition.property, "array-contains", condition.value)
        if (condition.type == FirestoreQueryType.STARTAT)
          updatedReference = updatedReference.startAt(condition.value)
        if (condition.type == FirestoreQueryType.STARTAFTER)
          updatedReference = updatedReference.startAfter(condition.value)
        if (condition.type == FirestoreQueryType.ENDAT)
          updatedReference = updatedReference.endAt(condition.value)
      }
      return updatedReference;
    } catch (error) {
      throw Error(error.message);
    }
  }

}
