<app-wild-header [hidebusinesslinks]="true" *ngIf="!preview && !isWidgetUser"></app-wild-header>
<div class="border-t border-b q py-3"
  *ngIf="(!preview && business.id == activeBusinessId) && !isAppUser && !isWidgetUser">
  <h3 class="text-center text-md">Editing Tools</h3>
  <div class="flex items-center justify-center my-2">
    <button (click)="openOrganiser()" class="w-10 md:w-auto wild-btn-light">
      <span class="hidden md:inline-block">Dashboard</span>
      <fa-icon class="ml-0 md:ml-2 wild-text-primary" icon="home"></fa-icon>
    </button>
    <button (click)="openBrand()" class="w-10 md:w-auto wild-btn-light ml-2 md:ml-2">
      <span class="hidden md:inline-block">Brand</span>
      <fa-icon class="ml-0 md:ml-2 text-red-400" icon="palette"></fa-icon>
    </button>
    <button (click)="openServices()" class="w-10 md:w-auto wild-btn-light ml-2 md:ml-2">
      <span class="hidden md:inline-block">Services</span>
      <fa-icon class="ml-0 md:ml-2 text-yellow-400" icon="water"></fa-icon>
    </button>
    <button (click)="openArea()" class="w-10 md:w-auto wild-btn-light ml-2 md:ml-2">
      <span class="hidden md:inline-block">Area</span>
      <fa-icon class="ml-0 md:ml-2 text-green-400" icon="map-marker-alt"></fa-icon>
    </button>
    <button (click)="openAddress()" class="w-10 md:w-auto wild-btn-light ml-2 md:ml-2">
      <span class="hidden md:inline-block">Address</span>
      <fa-icon class="ml-0 md:ml-2 text-green-400" icon="map-pin"></fa-icon>
    </button>
    <button (click)="openSettings()" class="w-10 md:w-auto wild-btn-light ml-2 md:ml-2">
      <span class="hidden md:inline-block">Settings</span>
      <fa-icon class="ml-0 md:ml-2 wild-text-primary" icon="cog"></fa-icon>
    </button>
  </div>
  <p class="text-center text-xs">only visible to you</p>
</div>
<div *ngIf="business" style="min-height: 80vh; " class="max-w-7xl mx-auto mt-4 mb-20 lg:flex lg:px-8">
  <div [ngClass]="{'lg:w-2/3': !isWidgetUser,'w-full': isWidgetUser}">
    <!-- Profile header START -->
    <div *ngIf="!isWidgetUser">
      <div class="max-w-7xl mx-auto rounded-xl">
        <img class="h-48 w-full object-cover lg:h-72 xl:h-80 lg:rounded-xl"
          (load)="imageLoadedBanner($event, business.uploadedbannerimage)" data-max-retry="5" data-retry="0"
          (error)="waitAndReloadBanner($event, business.uploadedbannerimage,'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fbanners%2Fwater.jpg?alt=media')"
          [src]="business.uploadedbannerimage != null && business.uploadedbannerimage != '' ? imageUrl + business.uploadedbannerimage.image + '_1920x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fbanners%2Fwater.jpg?alt=media'"
          alt="">
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="-mt-12 sm:-mt-16 flex items-end sm:space-x-0">
          <div class="flex">
            <img class="h-24 w-24 rounded-lg ring-4 ring-white sm:h-32 sm:w-32 object-cover"
              (load)="imageLoadedProfile($event, business.uploadedprofilepic)" data-max-retry="5" data-retry="0"
              (error)="waitAndReloadProfile($event, business.uploadedprofilepic,'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media')"
              [src]="business.uploadedprofilepic != null && business.uploadedprofilepic != '' ? imageUrl + business.uploadedprofilepic.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
              alt="">
          </div>
          <div class="hidden sm:flex mt-6 sm:flex-1 sm:min-w-0 items-center justify-end sm:space-x-6 sm:pb-1">
            <div class="ml-4 2xl:block mt-6 min-w-0 flex-1">
              <h1 class="text-2xl font-bold text-gray-900 truncate leading-tight">
                {{business.name}}
              </h1>
              <p *ngIf="business.location" class="wild-text-primary text-sm leading-tight">
                {{business.location.county}}, {{business.location.state}}, {{business.location.country}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Profile header END -->
    <!-- Services Wrapper START -->
    <div class="w-full px-4 lg:px-0">
      <!-- MOBILE NAME AND LOCATION START -->
      <div class="sm:hidden">
        <div class=" xl:ml-4 2xl:block mt-6 min-w-0 ">
          <h1 class="text-2xl font-bold text-gray-900 truncate leading-tight">
            {{business.name}}
          </h1>
          <p *ngIf="business.location" class="wild-text-primary text-sm leading-tight">
            {{business.location.county}}, {{business.location.state}}, {{business.location.country}}
          </p>
        </div>
      </div>
      <!-- MOBILE NAME AND LOCATION END -->
      <!-- Status -->
      <div class="w-full py-6 " *ngIf="business.status != BusinessStatus.LIVE && !isWidgetUser">
        <h1 class="text-md text-gray-500">Not Currently Available</h1>
        <p class="text-sm text-gray-500 mt-3">You will be able to make bookings with {{business.name}} as soon as they
          come back online</p>
      </div>
      <!-- SWIMSPOTS FOR LISTING ONLY -->
      <div class="mt-6">
        <app-swimspotsforbusinesslarge *ngIf="business.mode == BusinessMode.ADVERTISE" [business]="business">
        </app-swimspotsforbusinesslarge>
      </div>

      <!-- Services -->
      <div *ngIf="(business.status == BusinessStatus.LIVE && business.mode != BusinessMode.ADVERTISE) || isWidgetUser">
        <app-availableservicesv2 [isWidgetUser]="isWidgetUser" [business]="business"
          [businessId]="business.id"></app-availableservicesv2>
      </div>
    </div>
    <!-- Services Wrapper END -->
  </div>
  <div *ngIf="!isWidgetUser" class="lg:w-1/3 lg:ml-8 px-4 lg:px-0">
    <div *ngIf="business.mode != BusinessMode.ADVERTISE"
      class="lg:shadow-sm lg:rounded-xl lg:border lg:border-gray-100 lg:p-4 mb-4">
      <app-swimspotsforbusinesssmall [business]="business"></app-swimspotsforbusinesssmall>
    </div>
    <div *ngIf="business.bookingurl && business.mode == BusinessMode.ADVERTISE"
      class="lg:shadow-sm lg:rounded-xl lg:p-4 lg:border lg:border-gray-100 mb-8 lg:mb-4">
      <a class="w-full wild-btn-primary mb-4" [href]="business.bookingurl" target="_blank">Book with Business</a>
      <p class="text-center text-xs">secure bookings are not available directly through Wild, please go directly to
        {{business.name}}'s website</p>
    </div>
    <div class="lg:shadow-sm lg:rounded-xl lg:p-4 lg:border lg:border-gray-100 lg:mb-4">
      <!-- DETAILS START -->
      <div *ngIf="!isAppUser && !isWidgetUser">
        <div class="">
          <div class="text-lg font-bold text-gray-900 truncate leading-tight">
            About
          </div>
          <div class="wild-paragraph-prominent w-full">
            <p *ngIf="business.description">{{business.description}}</p>
            <small *ngIf="!business.description">empty</small>
          </div>
          <app-businesstags [business]="business"></app-businesstags>
        </div>
      </div>
      <!-- DETAILS END -->
    </div>
    <div class="lg:shadow-sm lg:rounded-xl lg:border lg:border-gray-100 lg:p-4 mb-10">
      <div class="text-lg font-bold text-gray-900 truncate leading-tight">
        Phone
      </div>
      <div class="flex justify-between items-center">
        <div class="wild-paragraph-prominent">
          <a *ngIf="business.phone">{{business.phone}}</a>
          <small *ngIf="!business.phone">empty</small>
        </div>
        <a [href]="'tel:' + business.phone" class="wild-btn-light">Call</a>
      </div>
      <div>
        <div *ngIf="business?.addresses?.length > 0"
          class="text-lg font-bold text-gray-900 truncate leading-tight mt-6">
          Address<span *ngIf="business?.addresses?.length > 1">es</span>
        </div>
        <a target="_blank" [href]="'https://maps.google.com/?q=' 
        + ((address?.lineone ? address?.lineone + ',' : '')
        + (address?.linetwo ? address?.linetwo + ',' : '')
        + (address?.city ? address?.city + ',' : '')
        + (address?.county ? address?.county + ',' : '')
        + (address?.postcode ? address?.postcode : ''))" *ngFor="let address of business?.addresses"
          class="wild-paragraph-prominent mt-4 block">
          <span *ngIf="address?.lineone">{{address?.lineone}},</span>
          <span *ngIf="address?.linetwo"> {{address?.linetwo}},</span>
          <span *ngIf="address?.city"> {{address?.city}},</span>
          <span *ngIf="address?.county"> {{address?.county}},</span>
          <span *ngIf="address?.postcode"> {{address?.postcode}},</span>
          <span *ngIf="address?.country == CountryCodes.GBGBR"> UK</span>
          <span *ngIf="address?.country == CountryCodes.USUSA"> USA</span>
        </a>
      </div>
      <div class="text-lg font-bold text-gray-900 truncate leading-tight mt-8">
        Links
      </div>
      <div class="grid grid-cols-4 gap-4 py-3 mt-4">
        <app-socialicon caption="website" *ngIf="business.website" [href]="business.website" icon="window-maximize">
        </app-socialicon>
        <app-socialicon caption="facebook" *ngIf="business.facebook" [href]="business.facebook"
          [icon]="['fab', 'facebook']">
        </app-socialicon>
        <app-socialicon caption="twitter" *ngIf="business.twitter" [href]="'https://twitter.com/' + business.twitter"
          [icon]="['fab', 'twitter']"></app-socialicon>
        <app-socialicon caption="instagram" *ngIf="business.instagram"
          [href]="'https://instagram.com/' + business.instagram" [icon]="['fab', 'instagram']"></app-socialicon>
        <app-socialicon caption="youtube" *ngIf="business.youtube" [href]="business.youtube"
          [icon]="['fab', 'youtube']"></app-socialicon>
        <app-socialicon caption="whatsapp" *ngIf="business.whatsapp" [href]="'https://wa.me/'+business.whatsapp"
          [icon]="['fab', 'whatsapp']"></app-socialicon>
        <app-socialicon [targetself]="true" [skipHttpCheck]="true" caption="email" *ngIf="business.email"
          [href]="'mailto:' + business.email" [icon]="'envelope'">
        </app-socialicon>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isWidgetUser" class="mb-16">
  <app-appdownloadlinks *ngIf="business.mode != BusinessMode.ADVERTISE && !preview"
    [header]="'You can also book with ' + business.name + ' on the Wild app'"></app-appdownloadlinks>
</div>