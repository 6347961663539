import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { ForgottenComponent } from './components/forgotten.component';
import { LoginComponent } from './components/login.component';
import { RegisterComponent } from './components/register.component';
import { UsernameComponent } from './components/username.component';
import { WhereToComponent } from './components/whereto.component';
import { ForgottenpageComponent } from './pages/forgottenpage.component';
import { LoginpageComponent } from './pages/loginpage.component';
import { RegisterpageComponent } from './pages/registerpage.component';
import { UsernamepageComponent } from './pages/usernamepage.component';

@NgModule({
    declarations: [
        //pages
        LoginpageComponent,
        RegisterpageComponent,
        ForgottenpageComponent,
        UsernamepageComponent,
        //components
        LoginComponent,
        WhereToComponent,
        RegisterComponent,
        ForgottenComponent,
        UsernameComponent
    ],
    imports: [
        SharedModule,
        AuthRoutingModule
    ]
})
export class AuthModule { } 
