import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	constructor() { }

	setCookie(cname: string, cvalue: string, exdays: number, sessionOnly: boolean = false, setSameSite: boolean = false) {
		try {
			var d = new Date();
			d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
			var expires = 'expires=' + d.toUTCString();
			var cookie = cname + '=' + cvalue + ';'
			if (sessionOnly == false)
				cookie = cookie + expires;
			cookie = cookie + ';path=/'
			//This ensures that the cookies can be set in the iFrame
			if (setSameSite == true)
				cookie = cookie + ';SameSite=None; Secure'
			document.cookie = cookie;
		} catch (err) {
			console.log(err)
		}
	}

	getCookie(cname: string, doNotDecode: boolean = false) {
		var name = cname + '=';
		var decodedCookie = document.cookie;
		if (doNotDecode == false)
			decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	deleteCookie(cname: string) {
		var d = new Date(); //Create an date object
		d.setTime(d.getTime() - 1000 * 60 * 60 * 24); //Set the time to the past. 1000 milliseonds = 1 second
		var expires = 'expires=' + d.toUTCString(); //Compose the expirartion date
		window.document.cookie = cname + '=' + '; ' + expires + ';path=/'; //Set the cookie with name and the expiration date
	}

	setSessionStorageItem(key: string, value: string) {
		sessionStorage.setItem(key, value);
	}

	getSessionStorageItem(key: string) {
		return sessionStorage.getItem(key);
	}

	removeSessionStorageItem(key: string) {
		sessionStorage.removeItem(key);
	}

	removeAllSessionStorageItems() {
		sessionStorage.clear();
	}
}
