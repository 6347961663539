import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorModel } from 'src/shared/data/models/error_model';

@Component({
  selector: 'app-retry',
  templateUrl: './retry.component.html',
  styleUrls: ['./retry.component.scss']
})
export class RetryComponent implements OnInit {

  @Input() code: string = null;
  @Input() header: string = null;
  @Input() description: string = null;
  @Output() onRetry = new EventEmitter<any>();
  @Input() hideretry: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  retry() {
    this.onRetry.emit();
  }

}
