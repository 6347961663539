import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { ModalType } from 'src/shared/services/modal/modal_type';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private modalService: ModalService, 
    private router: Router) {
    //Close modal if changing page
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.close();
      }
    });
  }

  ModalType = ModalType;
  ModalDesign = ModalDesign;
  @ViewChild('modalBody', {read: ViewContainerRef}) dynamicTarget: ViewContainerRef;

  get show(): boolean {
    return this.modalService.showModal;
  }

  get showAnimation(): boolean {
    return this.modalService.showModalAnimation;
  }

  get settings(): ModalModel {
    return this.modalService.settings;
  }

  ngOnInit(): void {
    this.modalService.registerModal(this); 
    this.modalService.setDocumentAndRenderer(this.document, this.renderer);
  }

  close(){
    this.modalService.hide();
  }

  ctaOneClick(){
    this.modalService.hide();
    if(this.settings.ctaonetextclick)
      this.settings.ctaonetextclick();
  }

  ctaTwoClick(){
    this.modalService.hide();
        if(this.settings.ctatwotextclick)
      this.settings.ctatwotextclick();
  }

}
