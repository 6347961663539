import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
@Component({
  selector: 'app-registrationpagecontainer',
  template: `
    <div>
      <app-lightweightmenu *ngIf="!isAppUser"></app-lightweightmenu>
    </div>
    <div class="min-h-full sm:mt-12">
        <div class="mx-auto w-full max-w-sm lg:w-96 px-4 md:px-0">
         <ng-content></ng-content>
        </div>
    </div>
  `,
})
export class RegistrationPageContainerComponent implements OnInit {

  @Input() src: string;
  @Input() mobilesrc: string;
  isAppUser: boolean;

  constructor(
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.isAppUser = this.auth.isAppUser;
  }


}
