import { ComponentFactoryResolver, ComponentRef, Injectable, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { ModalModel } from './modal_model';
@Injectable({
    providedIn: 'root'
})
export class ModalService {

    showModal: boolean = false;
    showModalAnimation: boolean = false;
    settings: ModalModel;
    document: Document;
    renderer: Renderer2;
    private modal: ModalComponent;
    private componentRef: ComponentRef<any>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    setDocumentAndRenderer(document: Document, renderer: Renderer2) {
        this.document = document;
        this.renderer = renderer;
    }

    show(settings: ModalModel) {
        //Ensure this timeout is HIGHER than the hide
        setTimeout(() => {
            if (settings.disableBodyScroll)
                this.renderer.addClass(this.document.body, 'disable-scroll');
            this.settings = settings;
            this.clearOldComponentIfExists();
            if (this.settings.component != null)
                this.open(this.settings.component)
            this.showModal = true;
            setTimeout(() => {
                this.showModalAnimation = true;
            }, 30);
        }, 40);

    }

    hide() {
        this.renderer.removeClass(this.document.body, 'disable-scroll');
        this.showModalAnimation = false;
        //Ensure this timeout is LOWER than the hide
        setTimeout(() => {
            this.showModal = false;
        }, 30)
    }

    registerModal(newModal: ModalComponent): void {
        this.modal = newModal;
    }

    clearOldComponentIfExists() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    open(component: any): ModalComponent {
        let factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = this.modal.dynamicTarget.createComponent(factory);
        if (this.settings.componentInputs != null && this.settings.componentInputs.length > 0) {
            for (let i = 0; i < this.settings.componentInputs.length; i++) {
                const input = this.settings.componentInputs[i];
                this.componentRef.instance[input.inputName] = input.value;
            }
        }
        if (this.settings.componentOutputs != null && this.settings.componentOutputs.length > 0) {
            for (let i = 0; i < this.settings.componentOutputs.length; i++) {
                const output = this.settings.componentOutputs[i];
                const sub: Subscription = this.componentRef.instance[output.outputName].subscribe(output.func);
                this.componentRef.onDestroy(() => { sub.unsubscribe(); console.log("Unsubscribing from modal output listener") });
            }
        }
        return this.modal;
    }

}
