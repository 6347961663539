import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { SwimSpot } from 'src/shared/data/models/swimspot_model';
import { SwimspotsdataService } from 'src/wild/modules/swimspots/services/swimspotdata_service/swimspotsdata.service';
import { WildNavigationType } from '../data/enums/navigation_type';
import { ModalDesign } from '../services/modal/modal_design';
import { ModalModel } from '../services/modal/modal_model';
import { ModalService } from '../services/modal/modal_service';
import { NavigationService } from '../services/navigation_service/navigation_service';

@Component({
    selector: "app-readmoreswimspot",
    template: `
        <ng-container *ngIf="swimspot$ | async as swimspot; else loadingOrError">
            <div *ngIf="!swimspot; else showswimspot">
                
            </div>
            <ng-template #showswimspot>
                <!--  -->
                <div  class="flex items-center justify-start mt-2 mb-6 cursor-pointer " (click)="openSwimSpot()">
                    <div class="mr-4" style="min-width:75px">
                        <img [src]="swimspot?.image != null && swimspot?.image != '' ? imagesUrl + swimspot.image.image + '_100x100.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
                            alt="" class="rounded-lg h-16 w-16">
                    </div> 
                    <div>
                        <div class="text-sm text-gray-700 line-clamp-1 leading-tight">{{swimspot.name}}</div>
                        <div class="text-xs text-gray-700 line-clamp-4">{{swimspot.description}}</div>
                    </div> 
                </div>  
            </ng-template>
        </ng-container>  
        <ng-template #loadingOrError>
            <ng-container *ngIf="error; else loading">
                <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                    (onRetry)="load()">
                </app-retry>
            </ng-container>
            <ng-template #loading>
                <app-loader [disablescreenheight]="true"></app-loader>
            </ng-template>
        </ng-template>     
    
  `
})
export class ReadMoreSwimSpotComponent implements OnInit {

    @Input() swimSpotId: string;
    error: ErrorModel;
    swimspot$: Observable<SwimSpot>;
    imagesUrl = environment.imagesUrl;

    constructor(
        private modalService: ModalService,
        private navigation: NavigationService,
        private swimSpotData: SwimspotsdataService) { }

    ngOnInit(): void {
        this.load();
    }


    load() {
        this.swimspot$ = null;
        this.swimspot$ = this.swimSpotData.read(this.swimSpotId).pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    async openSwimSpot() {
        var settings: ModalModel = {
            header: "Opening",
            description: "Please wait",
            design: ModalDesign.LOADING
        }
        this.modalService.show(settings);
        var swimspot = await this.swimSpotData.read(this.swimSpotId).toPromise();
        this.navigation.navigate(WildNavigationType.BROCHURESWIMSPOT, {}, swimspot.canonical);
        this.modalService.hide();
    }

}
