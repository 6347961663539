
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CloudFunctionsService } from 'src/shared/services/cloudfunctions/cloudfunctions_service';
import { AddToCartDTO } from 'src/shared/data/dtos/booking/AddToCartDTO';
import { AbandonCartDTO } from 'src/shared/data/dtos/booking/AbandonCartDTO';
import { CreateCheckoutDTO } from 'src/shared/data/dtos/booking/CreateCheckoutDTO';
import { RemoveOrderLineDTO } from 'src/shared/data/dtos/booking/RemoveOrderLineDTO';
import { GetCartFromSecretDTO } from 'src/shared/data/dtos/booking/GetCartFromSecretDTO';
import { HttpService } from 'src/shared/services/http/http_service';
import { environment } from 'src/environments/environment';
import { UserShoppingCartDTO } from 'src/shared/data/dtos/booking/UserShoppingCartDTO';
import { UserOrderDetailsDTO } from 'src/shared/data/dtos/booking/UserOrderDetailsDTO';
import { ApplyUserCouponDTO } from 'src/shared/data/dtos/coupons/ApplyUserCouponDTO';
import { RemoveUserCouponsFromCheckoutDTO } from 'src/shared/data/dtos/coupons/RemoveUserCouponsFromCheckoutDTO';
import { CheckOutForFreeDTO } from 'src/shared/data/dtos/booking/CheckOutForFreeDTO';
import { UserCheckoutDetailsDTO } from 'src/shared/data/dtos/booking/UserCheckoutDetailsDTO';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { AvailableScheduleComponent } from '../pages/bookinghome/components/availableschedule.component';
import { ServiceType } from 'src/shared/data/enums/service_type';
import { BookWrapperComponent } from '../pages/bookinghome/components/bookwrapper.component';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ServiceAndPricesDTO } from 'src/shared/data/dtos/services/ServiceAndPricesDTO';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { UpdateOrderLineAttendeeDTO } from 'src/shared/data/dtos/booking/UpdateOrderLineAttendeeDTO';
import { BookWrapperScheduledComponent } from '../pages/bookinghome/components/bookwrapperscheduled.component';
import { UserOrderLineServiceSummaryDTO } from 'src/shared/data/dtos/booking/UserOrderLineServiceSummaryDTO';
import { AddAttendeeToServiceDTO } from 'src/shared/data/dtos/booking/AddAttendeeToServiceDTO';
import { RemoveAttendeeFromServiceDTO } from 'src/shared/data/dtos/booking/RemoveAttendeeFromServiceDTO';
import { RemoveAllAttendeesFromServiceDTO } from 'src/shared/data/dtos/booking/RemoveAllAttendeesFromServiceDTO';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    constructor(
        private http: HttpService,
        private modalService: ModalService,
        private authService: AuthenticationService,
    ) { }

    book(service: ServiceAndPricesDTO) {
        if (this.authService.isAuthedIfNotShowPopUp()) {
            if (service.type == ServiceType.CALENDARBASED) {
                var settings: ModalModel = {
                    close: true,
                    large: true,
                    design: ModalDesign.COMPONENT,
                    component: BookWrapperComponent,
                    componentInputs: [
                        {
                            inputName: "service",
                            value: service
                        }
                    ]
                }
            } else if (service.type == ServiceType.GROUP) {
                var settings: ModalModel = {
                    close: true,
                    large: true,
                    design: ModalDesign.COMPONENT,
                    component: BookWrapperScheduledComponent,
                    componentInputs: [
                        {
                            inputName: "service",
                            value: service
                        }
                    ]
                }
            }
            this.modalService.show(settings);
        }
    }
    

    addToCart(dto: AddToCartDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/addToCart`, dto).toPromise();
    }

    addAttendeeToService(dto: AddAttendeeToServiceDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/addAttendee`, dto).toPromise();
    }

    removeAttendeeFromService(dto: RemoveAttendeeFromServiceDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/removeAttendee`, dto).toPromise();
    }

    removeAllAttendeesFromService(dto: RemoveAllAttendeesFromServiceDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/removeAllAttendees`, dto).toPromise();
    }

    createCheckout(dto: CreateCheckoutDTO): Promise<UserOrderDetailsDTO> {
        return this.http.post(`${environment.api}/user/booking/createCheckout`, dto).toPromise();
    }

    createCheckoutV2(dto: CreateCheckoutDTO): Promise<UserCheckoutDetailsDTO> {
        return this.http.post(`${environment.api}/user/booking/createCheckoutV2`, dto).toPromise();
    }

    checkoutforfree(dto: CheckOutForFreeDTO): Promise<UserOrderDetailsDTO> {
        return this.http.post(`${environment.api}/user/booking/checkoutforfree`, dto).toPromise();
    }

    applyVoucher(dto: ApplyUserCouponDTO): Promise<UserOrderDetailsDTO> {
        return this.http.post(`${environment.api}/user/booking/applyvoucher`, dto).toPromise();
    }

    removevouchers(dto: RemoveUserCouponsFromCheckoutDTO): Promise<UserOrderDetailsDTO> {
        return this.http.post(`${environment.api}/user/booking/removevouchers`, dto).toPromise();
    }

    reset(businessId: string): Promise<UserOrderDetailsDTO> {
        return this.http.get(`${environment.api}/user/booking/reset/${businessId}`,).toPromise();
    }

    getCartFromSecret(dto: GetCartFromSecretDTO): Observable<UserOrderDetailsDTO> {
        return this.http.get(`${environment.api}/user/booking/confirmation?clientSecret=${dto.clientSecret}`);
    }

    getOrder(orderId: string): Observable<UserOrderDetailsDTO> {
        return this.http.get(`${environment.api}/user/booking/orders/${orderId}`);
    }

    abandonCart(dto: AbandonCartDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/abandonCart`, dto).toPromise();
    }

    removeOrderLine(dto: RemoveOrderLineDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/removeOrderLine`, dto).toPromise();
    }

    updateAttendee(dto: UpdateOrderLineAttendeeDTO): Promise<void> {
        return this.http.post(`${environment.api}/user/booking/updateAttendee`, dto).toPromise();
    }

    getBasketOrders(businessId: string = ""): Observable<UserShoppingCartDTO> {
        if (businessId != "") {
            return this.http.get(`${environment.api}/user/booking/cart?businessId=${businessId}`);
        } else {
            return this.http.get(`${environment.api}/user/booking/cart`);
        }
    }

    getserviceorderlinesummary(businessId: string = "",serviceId: string = ""): Observable<UserOrderLineServiceSummaryDTO> {
            return this.http.get(`${environment.api}/user/booking/getserviceorderlinesummary?businessId=${businessId}&serviceId=${serviceId}`);
    }

    startTimers(orders: UserShoppingCartDTO) {
        for (let i = 0; i < orders?.carts?.length; i++) {
            const order = orders.carts[i];
            this.startTimerForUserShoppingCart(order);
        }
    }

    startTimerForUserShoppingCart(order: UserOrderDetailsDTO) {
        var interval = setInterval(() => {
            var minutes = Math.floor(order.secondsUntilExpiry / 60);
            var remainingSeconds = order.secondsUntilExpiry - (minutes * 60);
            var str = "";
            if (minutes > 0)
                str += `${minutes}:`
            if (remainingSeconds < 10) {
                str += `0${remainingSeconds}`
            } else {
                str += `${remainingSeconds}`
            }
            order.secondsUntilExpiry = order.secondsUntilExpiry - 1;
            order.countdown = str;
            if (minutes <= 0 && remainingSeconds <= 0) {
                clearInterval(interval);
                order.expired = true;
                //TODO - redirect here with modal
            }
        }, 1000);
    }
}
