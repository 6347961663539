import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SearchResults } from 'src/shared/data/models/SearchResults';
import { HttpService } from '../http/http_service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private httpService: HttpService) { }

  search(query: string, latitude: string = "52.554584", longitude: string = "-1.850033"): Observable<SearchResults> {
    return this.httpService.get(`https://ow0qwly665.execute-api.eu-west-1.amazonaws.com/Production/searchV2?query=${query}&latitude=${latitude}&longitude=${longitude}`, true).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  swimspotSearch(query: string, latitude: string = "52.554584", longitude: string = "-1.850033"): Observable<SearchResults> {
    return this.httpService.get(`https://ow0qwly665.execute-api.eu-west-1.amazonaws.com/Production/swimspotlocationsearch?query=${query}&latitude=${latitude}&longitude=${longitude}`, true).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCoordinatesForW3w(words: string,): Observable<any> {
    //returns simple object with latitude and longitude parameters
    return this.httpService.get(`https://ow0qwly665.execute-api.eu-west-1.amazonaws.com/Production/getwhat3wordslatlng?words=${words}`, true).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  nearestswimspots(latitude: string, longitude: string) {
    return this.httpService.get(`https://ow0qwly665.execute-api.eu-west-1.amazonaws.com/Production/nearestswimspots?latitude=${latitude}&longitude=${longitude}`, true).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

}
