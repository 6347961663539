import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TeamMemberPublicDTO } from 'src/shared/data/dtos/serviceAvailability/teamMemberPublicDTO';

@Component({
  selector: "app-selectteammember",
  template: `
    <small *ngIf="!team || team.length == 0">No team members available</small>
    <div class="mt-12 mb-6 flow-root">
    <ul role="list" class="-my-5 divide-y divide-gray-200">
      <li *ngFor="let teamMember of team" (click)="click(teamMember)" class="py-4 cursor-pointer hover:bg-gray-50">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0">
            <img class="h-12 w-12 rounded-full" [src]="teamMember.image != null ? imagesUrl + teamMember.image.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2Fprofile4.png?alt=media&token=1343da00-487a-465c-ac82-a33b13098d0d'" alt="">
          </div>
          <div class="min-w-0 flex-1">
            <p class="truncate text-sm font-medium text-gray-900">{{teamMember.firstName}} {{teamMember.surname}}</p>
            <!-- <p class="truncate text-sm text-gray-500">@leonardkrasner</p> -->
          </div>
          <div>
            <span class="inline-flex items-center rounded-full border border-gray-300 wild-bg-primary px-2.5 py-0.5 text-sm font-medium leading-5 text-white shadow-sm hover:bg-blue-900">Select</span>
          </div>
        </div>
      </li>
    </ul>
    </div>
  `
})
export class SelectTeamMemberComponent implements OnInit {

  @Input() team: TeamMemberPublicDTO[];
  @Output() selected = new EventEmitter<TeamMemberPublicDTO>();
  imagesUrl = environment.imagesUrl;

  constructor() { }

  ngOnInit(): void {

  }

  click(teamMember: TeamMemberPublicDTO) {
    this.selected.emit(teamMember);
  }



}
