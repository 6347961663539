import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgottenpage',
  template: `
  <app-registrationpagecontainer [src]="src">
    <app-forgotten [isPage]="true"></app-forgotten>
  </app-registrationpagecontainer>
  `,
})
export class ForgottenpageComponent implements OnInit {

  src: string = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FReset%20Password.png?alt=media&token=d1c04b01-4b9b-401b-bb62-1fe064d8f6e0";

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.business == 1)
        this.src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FReset%20Password.png?alt=media&token=d1c04b01-4b9b-401b-bb62-1fe064d8f6e0";
    })
  }

}
