import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AvailabilityForServiceDTO } from 'src/shared/data/dtos/serviceAvailability/AvailabilityForServiceDTO';
import { GetServiceDaysDTO } from 'src/shared/data/dtos/serviceAvailability/GetServiceDaysDTO';
import { TeamMemberPublicDTO } from 'src/shared/data/dtos/serviceAvailability/teamMemberPublicDTO';
import { ServiceAndPricesDTO } from 'src/shared/data/dtos/services/ServiceAndPricesDTO';
import { ServicePriceDTO } from 'src/shared/data/dtos/services/ServicePriceDTO';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { DatesService } from 'src/shared/services/dates_service/dates_service';
import { BookingDayStatus } from '../../../enums/booking_day_status';
import { BookingDay } from '../../../models/booking_day';
import { BookingMonth } from '../../../models/booking_month';
import { BookingWeek } from '../../../models/booking_week';
import { AvailabilityPublicService } from '../../../services/availability_service';
import { BookingDateService } from '../../../services/booking_date_service';

@Component({
  selector: "app-selectdate",
  template: `
      <div *ngIf="serviceDays">
          <div *ngIf="view == 'month'">
              <div (click)="weekView()" class="cursor-pointer text-center wild-text-primary text-md font-medium">
                  {{bookingMonth.description}}
              </div>
              <div class="flex items-center mt-4">
                <div class="flex-none cursor-pointer" (click)="previousMonth()">
                  <fa-icon icon="chevron-left"></fa-icon>
                </div>
                <div class="flex-1 mx-4">
                  <div class="flex justify-between border-t border-b border-gray-100">
                      <div class="py-2 w-full text-center text-gray-400 text-sm" *ngFor="let day of bookingMonth.weeks[0].days">
                        {{day.readableDayShort | uppercase}}
                      </div>
                  </div>
                  <div *ngFor="let week of bookingMonth.weeks" class="flex justify-between border-b border-gray-100 py-2 md:py-4">
                      <div (click)="selectMonthDate(day)" [ngClass]="{'border border-blue-900 bg-blue-50':selectedDate == day.date, 'text-gray-200':day.status == BookingDayStatus.INACTIVE || day.month != bookingMonth.month, 'hover:border hover:border-blue-900 hover:bg-gray-50 cursor-pointer ':day.status == BookingDayStatus.ACTIVE}" class="rounded-sm py-2 w-full text-center " *ngFor="let day of week.days">
                        {{day.dayOfMonth}}
                      </div>
                  </div>
                </div>
                <div class="flex-none cursor-pointer" (click)="nextMonth()">
                  <fa-icon icon="chevron-right"></fa-icon>
                </div>
              </div>
            </div>
            <div *ngIf="view == 'week'">
              <div (click)="monthView()" class="cursor-pointer text-center wild-text-primary text-md font-medium">
                  {{bookingWeek.description}}
              </div>
              <div class="flex items-center mt-4">
                <div class="flex-none cursor-pointer" (click)="previousWeek()">
                  <fa-icon icon="chevron-left"></fa-icon>
                </div>
                <div class="flex-1 mx-4">
                  <div class="flex justify-between border-t border-b border-gray-100">
                      <div class="py-2 w-full text-center text-gray-400 text-sm" *ngFor="let day of bookingWeek.days">
                        {{day.readableDayShort | uppercase}}
                      </div>
                  </div>
                  <div class="flex justify-between border-b border-gray-100 py-2 md:py-4">
                      <div (click)="selectWeekDate(day)" [ngClass]="{'border border-blue-900 bg-blue-50':selectedDate == day.date, 'text-gray-200':day.status == BookingDayStatus.INACTIVE || !isAfterFirstAvailableDay(day.date), 'border hover:border-blue-900 hover:bg-gray-50 cursor-pointer ':day.status == BookingDayStatus.ACTIVE && isAfterFirstAvailableDay(day.date)}" class="rounded-sm py-2 w-full text-center " *ngFor="let day of bookingWeek.days">
                        {{day.dayOfMonth}}
                      </div>
                  </div>
                </div>
                <div class="flex-none cursor-pointer" (click)="nextWeek()">
                  <fa-icon icon="chevron-right"></fa-icon>
                </div>
              </div>
            </div>
    </div>
    <div *ngIf="!serviceDays">
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </div>    
  `
})
export class SelectDateComponent implements OnInit {

  @Input() service: ServiceAndPricesDTO;
  @Input() teamMember: TeamMemberPublicDTO;
  @Output() onDateSelected = new EventEmitter<string>()

  view: string = "week";
  error: ErrorModel;
  selectedDate: string;
  bookingWeek: BookingWeek;

  bookingMonth: BookingMonth;
  BookingDayStatus = BookingDayStatus;
  serviceDays: AvailabilityForServiceDTO;

  constructor(
    private availabilityService: AvailabilityPublicService,
    private datesService: DatesService,
    private bookingDateService: BookingDateService
  ) {

  }

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  async load() {
    this.serviceDays = null;
    var dto: GetServiceDaysDTO = {
      businessId: this.service.businessId,
      serviceId: this.service.serviceId,
      teamMemberId: this.teamMember.id
    }
    this.serviceDays = await this.availabilityService.getServiceDays(dto).pipe(
      take(1),
      catchError(err => {
        this.error = err;
        return throwError(err);
      })
    ).toPromise();
    this.bookingDateService.availabilityForServiceDTO = this.serviceDays;
    this.bookingWeek = this.bookingDateService.weekToStart(this.serviceDays.teamMemberAvailability.nextAvailableDate);
    this.selectedDate = this.serviceDays.teamMemberAvailability.nextAvailableDate;
    setTimeout(() => {
      this.loadAvailabilityBasedOnDate();
    }, 200)
  }

  loadAvailabilityBasedOnDate() {
    this.onDateSelected.emit(this.selectedDate);
  }

  //WEEK START

  nextWeek() {
    this.bookingWeek = this.bookingDateService.getBookingWeek(this.bookingWeek.nextWeekStart);
  }

  previousWeek() {
    this.bookingWeek = this.bookingDateService.getBookingWeek(this.bookingWeek.previousWeekStart);
  }

  isAfterFirstAvailableDay(date: string): boolean {
    return this.datesService.isAfter(date, this.serviceDays.teamMemberAvailability.nextAvailableDate) || this.datesService.isSame(date, this.serviceDays.teamMemberAvailability.nextAvailableDate)
  }

  isBeforeLastAvailableDay(date: string): boolean {
    //TODO - add last available day data and update UI
    return this.datesService.isBefore(date, this.serviceDays.teamMemberAvailability.nextAvailableDate);
  }

  selectWeekDate(day: BookingDay) {
    if (day.status == BookingDayStatus.ACTIVE && this.isAfterFirstAvailableDay(day.date)) {
      this.selectedDate = day.date;
      this.loadAvailabilityBasedOnDate();
    }
  }

  weekView() {
    var dateForWeek = this.bookingDateService.ifLessThanTodayReturnToday(this.bookingMonth.currentMonth)
    this.bookingWeek = this.bookingDateService.getBookingWeek(dateForWeek);
    this.view = 'week'
  }

  //WEEK END

  //MONTH START

  nextMonth() {
    this.bookingMonth = this.bookingDateService.getBookingMonth(this.bookingMonth.nextMonthStart);
  }

  previousMonth() {
    this.bookingMonth = this.bookingDateService.getBookingMonth(this.bookingMonth.previousMonthStart);
  }

  selectMonthDate(day: BookingDay) {
    if (day.status == BookingDayStatus.ACTIVE) {
      this.bookingWeek = this.bookingDateService.getBookingWeek(day.date);
      this.view = 'week';
      this.selectedDate = day.date;
      this.loadAvailabilityBasedOnDate();
    }
  }

  monthView() {
    this.bookingMonth = this.bookingDateService.getBookingMonth(this.bookingWeek.currentWeek);
    this.view = 'month'
  }

  //MONTH END

}
