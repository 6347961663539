import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SelectModel } from 'src/shared/components/select/select_model';
import { Gender } from 'src/shared/data/enums/gender_type';

import { ErrorModel } from 'src/shared/data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UtilService } from '../services/util/util_service';
import { LinkedAccountsService } from 'src/wild/modules/user/services/linkedaccounts_service';
import { LinkedAccountDTO } from '../data/dtos/linkedaccounts/LinkedAccountDTO';
import { RelationshipType } from '../data/enums/relationship_type';
import { EmergencyModel } from '../data/models/emergency_model';
import { UserService } from 'src/wild/modules/user/services/user_service';

@Component({
    selector: "app-bookingprofileformforlinkedaccount",
    template: `
    <ng-container *ngIf="user$ | async as user; else loadingOrError">
        <app-form  [form]="form" [ctaText]="id != null ? 'Update': 'Add'" disableDivide="true" (onSubmit)="onSubmit($event)"
            [loading]="loading">
            <app-inputgroup>
                <app-input validation="Minimum of 3 characters"  [form]="form" type="text" name="firstName" description="First Name" placeholder="Rob" [span]=3 [value]="user?.firstName"></app-input>
                <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="surname" description="Surname" [span]=3 placeholder="Gray" [value]="user?.surname"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-input [form]="form" type="text" name="pronouns" description="Pronouns" [span]=3 [value]="user?.pronouns"></app-input>
                <app-select [options]="genderOptions" [form]="form" name="gender" description="Gender" [span]=3 [value]="user?.gender"></app-select>
            </app-inputgroup> 
            <app-inputgroup>
                <app-formdatepicker [form]="form" name="dob" description="DOB" placeholder="Pick a date" [maxyear]="2023" [minyear]="1910" [span]=3 [value]="user?.dob"></app-formdatepicker>
                <app-select [options]="relationshipOptions" [form]="form" name="relationship" description="Relationship" [span]=3 [value]="user?.relationship"></app-select>
            </app-inputgroup>
            <h1 class="text-lg mt-6 font-semibold">Communications</h1>
            <p class="text-xs">
                If you would like updates about bookings to go to you. You can <a class=" text-blue-600 cursor-pointer" (click)="useMineComms()">use your details.</a>
            </p>
            <app-inputgroup>
                <app-select [options]="codeOptions" [form]="form" name="code" description="Code" [span]=2 [value]="user?.phone?.code"></app-select>
                <app-input validation="Invalid number" description="Phone Number" [form]="form" type="text" name="number" placeholder="07773123456" [span]=4 [value]="user?.phone?.number"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-input validation="Add email address" description="Email Address" [form]="form" type="email" name="email" placeholder="rob@gmail.com" [span]=3 [value]="user?.email"></app-input>
            </app-inputgroup>
            <h1 class="text-lg mt-6 font-semibold">Medical</h1>
            <app-inputgroup>
                <app-textarea validation="Just type NA if not applicable" [form]="form" type="text" name="medicalInfo" description="Health Conditions" placeholder="Details of any underlying health conditions that we should be aware of. Just type NA if this is not applicable" [span]=6 [value]="user?.emergency?.medicalInfo"></app-textarea>
            </app-inputgroup>
            <h1 class="text-lg mt-6 font-semibold">Emergency Contact</h1>
            <p class="text-xs">
                You can <a class=" text-blue-600 cursor-pointer" (click)="useMineEmergency()">use your details</a> as an emergency contact.
            </p>
            <app-inputgroup>
                <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="nextOfKinFirstName" description="First Name" placeholder="Rob" [span]=3 [value]="user?.emergency?.nextOfKin?.firstName"></app-input>
                <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="nextOfKinSurname" description="Surname" placeholder="Gray" [span]=3 [value]="user?.emergency?.nextOfKin?.surname"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-select [options]="codeOptions" [form]="form" name="nextOfKinCode" description="Code" [span]=3 [value]="user?.emergency?.nextOfKinPhone?.code"></app-select>
                <app-input validation="Invalid number" description="Phone Number" [form]="form" type="text" name="nextOfKinNumber" placeholder="07773123456" [span]=3 [value]="user?.emergency?.nextOfKinPhone?.number"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-input [form]="form" type="text" name="nextOfKinEmail" description="Email" placeholder="support@wildopenwater.com" [span]=3 [value]="user?.emergency?.nextOfKin?.email"></app-input>
            </app-inputgroup>
        </app-form> 
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>

  `
})
export class BookingProfileFormForLinkedAccountComponent implements OnInit {

    user$: Observable<any>;
    @Input() id: string;
    @Output() updated = new EventEmitter<LinkedAccountDTO>();
    codeOptions: SelectModel[] = [
        {
            name: "+44",
            value: "+44"
        }
    ]
    genderOptions: SelectModel[] = [
        {
            name: "",
            value: Gender.UNKNOWN
        }, {
            name: "Female",
            value: Gender.FEMALE
        }, {
            name: "Male",
            value: Gender.MALE
        }, {
            name: "Rather not say",
            value: Gender.RATHERNOTSAY
        }
    ]
    relationshipOptions: SelectModel[] = [
        {
            name: "",
            value: RelationshipType.UNKNOWN
        }, {
            name: "Child",
            value: RelationshipType.CHILD
        }, {
            name: "Sibling",
            value: RelationshipType.SIBLING
        }, {
            name: "Friend",
            value: RelationshipType.FRIEND
        }, {
            name: "Parent",
            value: RelationshipType.PARENT
        }, {
            name: "Partner",
            value: RelationshipType.PARTNER
        }
    ]
    error: ErrorModel;

    constructor(
        private fb: FormBuilder,
        private util: UtilService,
        private userService: UserService,
        private linkedAccountsService: LinkedAccountsService,
        private modalService: ModalService,
        private notifyService: NotifyService) { }

    form!: FormGroup;
    loading: boolean;

    async useMineComms() {
        await this.userService.getUser().toPromise().then((user) => {
            this.form.controls['email'].setValue(user.email);
            var code = user?.phone?.code ?? "";
            var number = user?.phone?.number ?? "";
            if (code == "" || number == "") {
                this.handlerError("Phone number not available")
            } else {
                this.form.controls['code'].setValue(code);
                this.form.controls['number'].setValue(number);
            }
        }).catch((error) => {
            this.handlerError(error.message)
        })
    }
    async useMineEmergency() {
        await this.userService.getUser().toPromise().then((user) => {
            this.form.controls['nextOfKinEmail'].setValue(user.email);
            var code = user?.phone?.code ?? "";
            var number = user?.phone?.number ?? "";
            if (code == "" || number == "") {
                this.handlerError("Phone number not available")
            } else {
                this.form.controls['nextOfKinCode'].setValue(code);
                this.form.controls['nextOfKinNumber'].setValue(number);
            }
            var firstName = user?.personal?.firstName ?? "";
            var surname = user?.personal?.surname ?? "";
            if (firstName == "" || surname == "") {
                this.handlerError("Your name is not available on your account")
            } else {
                this.form.controls['nextOfKinFirstName'].setValue(firstName);
                this.form.controls['nextOfKinSurname'].setValue(surname);
            }
        }).catch((error) => {
            this.handlerError(error.message)
        })
    }

    ngOnInit(): void {
        this.load();
        this.form = this.fb.group({
            //personal
            firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
            surname: new FormControl('', [Validators.required, Validators.minLength(3)]),
            email: new FormControl('', [Validators.required]),
            pronouns: new FormControl('', []),
            //dob
            dob: new FormControl('', [Validators.required]),
            //gender
            gender: new FormControl(Gender.UNKNOWN, [Validators.required]),
            //phone
            code: new FormControl('+44', [Validators.required]),
            relationship: new FormControl('', [Validators.required]),
            number: new FormControl('', [Validators.required, this.util.phoneValidator]),
            //emergency
            medicalInfo: new FormControl('', [Validators.required, Validators.minLength(2)]),
            nextOfKinCode: new FormControl('+44', [Validators.required]),
            nextOfKinNumber: new FormControl('', [Validators.required, this.util.phoneValidator]),
            nextOfKinEmail: new FormControl('', []),
            nextOfKinFirstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
            nextOfKinSurname: new FormControl('', [Validators.required, Validators.minLength(3)]),
        });
    }

    load() {
        this.user$ = null
        if (this.id != null) {
            this.user$ = this.linkedAccountsService.get(this.id).pipe(
                take(1),
                catchError(err => {
                    this.error = err;
                    return throwError(err);
                })
            );
        } else {
            this.user$ = of("empty");
        }
    }

    onSubmit(data: any) {
        this.loading = true;
        var emergency: EmergencyModel = {
            medicalInfo: data.medicalInfo,
            nextOfKinPhone: {
                number: data?.nextOfKinNumber,
                code: data?.nextOfKinCode
            },
            nextOfKin: {
                pronouns: "",
                email: data.nextOfKinEmail,
                firstName: data.nextOfKinFirstName,
                surname: data.nextOfKinSurname
            }
        }
        var dto: LinkedAccountDTO = {
            dob: data.dob,
            firstName: data.firstName,
            surname: data.surname,
            gender: data.gender,
            emergency: emergency,
            email: data.email,
            phone: {
                code: data.code,
                number: data.number
            },
            pronouns: data.pronouns,
            relationship: data.relationship,
        }
        if (this.id == null || this.id == undefined) {
            this.linkedAccountsService.add(dto)
                .then((updatedDTO) => {
                    this.next(updatedDTO);
                })
                .catch((error) => {
                    this.handlerError(error.message);
                })
                .finally(() => {
                    this.finally();
                })
        } else {
            this.linkedAccountsService.update(this.id, dto)
                .then((updatedDTO) => {
                    this.next(updatedDTO);
                })
                .catch((error) => {
                    this.handlerError(error.message);
                })
                .finally(() => {
                    this.finally();
                })
        }

    }

    handlerError(message: string) {
        this.notifyService.notify("Error", message, NotifyType.ERROR);
    }

    next(updatedDTO: LinkedAccountDTO) {
        this.notifyService.notify("Updated", "Linked Account Updated", NotifyType.SUCCESS);
        this.updated.emit(updatedDTO);
    }

    finally() {
        this.loading = false;
    }

}
