<ng-container *ngIf="order$ | async as order; else loadingOrError">
  <div *ngIf="!order else orderLoaded">
    No order found
  </div>
  <ng-template #orderLoaded>
    <main class="bg-white px-4 pt-16 pb-24 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
      <div class="max-w-3xl mx-auto">
        <div class="max-w-xl">
          <h1 class="text-sm font-semibold uppercase tracking-wide  wild-text-primary">Thank you!</h1>
          <p class="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">Booking Confirmed!</p>
          <p class="mt-2 text-base text-gray-500">Your booking has been confirmed with {{order?.business}}.</p>
          <button class="my-2 wild-btn-primary cursor-pointer" (click)="orders()">Go To
            Orders</button>
          <button class="my-2 wild-btn-light ml-3 cursor-pointer" (click)="activities()">Go To
            Activities</button>
          <dl class="mt-12 text-sm font-medium">
            <dt class="text-gray-900">Order Number</dt>
            <dd class="wild-text-primary mt-2">{{order?.reference}}</dd>
          </dl>
        </div>
        <section aria-labelledby="order-heading" class="mt-10 border-t border-gray-200">
          <h3 class="sr-only">Items</h3>
          <div *ngFor="let orderLine of order.orderLines" class="py-10 border-b border-gray-200 flex space-x-6">
            <img
              [src]="orderLine?.image != null ? imagesUrl + orderLine?.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"
              [alt]="orderLine?.description"
              class="flex-none w-20 h-20 object-center object-cover bg-gray-100 rounded-lg sm:w-40 sm:h-40">
            <div class="flex-auto flex flex-col">
              <div>
                <h4 class="font-medium text-gray-900">
                  <a>
                    {{orderLine?.description}}
                  </a>
                </h4>
                <p class="text-sm text-gray-600">
                  {{orderLine?.priceDescription}}
                </p>
                <div *ngIf="orderLine.activity"
                  class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 mt-2">
                  Booking for <span *ngIf="!orderLine.linkedAccount">&nbsp;myself</span>
                  <span *ngIf="orderLine.linkedAccount">&nbsp;{{orderLine.linkedAccount?.firstName}}
                    {{orderLine.linkedAccount?.surname}}</span>
                </div>
                <div *ngIf="orderLine.activity">
                  <div class="font-medium text-gray-700 hover:text-gray-800 text-sm mt-3">
                    {{orderLine.activity.readableLocalStartDateTime}}
                  </div>
                  <!-- <small *ngIf="!isAppUser" class="text-xs italics"><span (click)="openActivity(orderLine.activity)"
                      class="cursor-pointer underline">Activity details</span></small> -->
                  <p class="mt-3 text-xs" *ngIf="orderLine?.confirmationMessage">{{orderLine?.confirmationMessage}}</p>
                </div>
                <div *ngIf="orderLine.couponId">
                  <small *ngIf="!isAppUser" class="text-xs italics"><span (click)="openCoupons()"
                      class="cursor-pointer underline">Go to vouchers</span></small>
                </div>
              </div>
              <div class="mt-6 flex-1 flex items-end">
                <dl class="flex text-sm divide-x divide-gray-200 space-x-4 sm:space-x-6">
                  <div class="flex">
                    <dt class="font-medium text-gray-900">Quantity</dt>
                    <dd class="ml-2 text-gray-700">
                      {{orderLine?.quantity}}
                    </dd>
                  </div>
                  <div class="pl-4 flex sm:pl-6">
                    <dt class="font-medium text-gray-900">Price</dt>
                    <dd class="ml-2 text-gray-700">
                      {{orderLine?.formattedGrandTotal}}
                    </dd>
                  </div>
                </dl>
              </div>
              <div class="mb-2 mt-3" *ngFor="let coupon of orderLine.coupons">
                <p class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                  {{coupon?.name}} (-{{coupon?.formattedDiscountPrice}})</p>
              </div>
            </div>
          </div>

          <div class="sm:ml-40 sm:pl-6">
            <!-- <dl class="grid grid-cols-2 gap-x-6 border-t border-gray-200 text-sm py-10">
              <div>
                <dt class="font-medium text-gray-900">Payment method</dt>
                <dd class="mt-2 text-gray-700">
                  <p>Apple Pay</p>
                  <p>Mastercard</p>
                  <p><span aria-hidden="true">•••• </span><span class="sr-only">Ending in </span>1545</p>
                </dd>
              </div>
              <div>
                <dt class="font-medium text-gray-900">Shipping method</dt>
                <dd class="mt-2 text-gray-700">
                  <p>DHL</p>
                  <p>Takes up to 3 working days</p>
                </dd>
              </div>
            </dl> -->
            <dl class="space-y-6 border-t border-gray-200 text-sm pt-10">
              <div class="flex justify-between">
                <dt class="font-medium text-gray-900">Subtotal</dt>
                <dd class="text-gray-700">{{order?.formattedTotal}}</dd>
              </div>
              <!-- <div class="flex justify-between">
                <dt class="flex font-medium text-gray-900">
                  Discount
                  <span class="rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 ml-2">STUDENT50</span>
                </dt>
                <dd class="text-gray-700">-$18.00 (50%)</dd>
              </div> -->
              <div class="flex justify-between">
                <dt class="font-medium text-gray-900">Tax</dt>
                <dd class="text-gray-700">{{order?.formattedTaxes}}</dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-medium text-gray-900">Fees</dt>
                <dd class="text-gray-700">{{order?.formattedFees}}</dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-medium text-gray-900">Discount</dt>
                <dd class="text-gray-900">{{order?.formattedDiscount}}</dd>
              </div>
              <div class="flex justify-between">
                <dt class="font-medium text-gray-900">Total</dt>
                <dd class="text-gray-900">{{order?.formattedGrandTotal}}</dd>
              </div>
            </dl>
          </div>
        </section>
      </div>
    </main>
  </ng-template>
</ng-container>
<ng-template #loadingOrError>
  <ng-container *ngIf="error; else loading">
    <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
    </app-retry>
  </ng-container>
  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</ng-template>
<app-footer></app-footer>