import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { WidgetModule } from './widget/widget.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(WidgetModule)
  .catch(err => console.error(err));
