import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetNotFoundComponent } from './widget.notfound';

const routes: Routes = [
  {
    path: "",
    component: WidgetNotFoundComponent
  },
  { path: '404', component: WidgetNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class WidgetRoutingModule { }
