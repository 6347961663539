import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomTokenResolver } from 'src/shared/resolvers/customtoken.resolver';
import { UserResolver } from 'src/shared/resolvers/user.resolver';
import { BasketComponent } from './pages/basket/basket.component';
import { BookerDetailsIfMissing } from './pages/bookerdetailsifmissing/bookerdetailsifmissing.component';
import { BookinghomeComponent } from './pages/bookinghome/bookinghome.component';
import { BookingHomeResolver } from './pages/bookinghome/bookinghome.resolver';
import { BookingDetailsComponent } from './pages/bookinghome/components/servicedetails.component';
import { BookingUserDetailsForLinkedAccountsComponent } from './pages/bookinguserdetailsforlinkedaccounts/bookinguserdetailsforlinkedaccounts.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';

const routes: Routes = [
  {
    path: "basket",
    resolve: {
      user: UserResolver
    },
    component: BasketComponent,
  },
  {
    path: "details",
    resolve: {
      user: UserResolver
    },
    component: BookingUserDetailsForLinkedAccountsComponent,
  },
  {
    path: "bookerdetails",
    resolve: {
      user: UserResolver
    },
    component: BookerDetailsIfMissing,
  },
  {
    path: "checkout",
    resolve: {
      user: UserResolver
    },
    component: CheckoutComponent,
  },
  {
    path: "confirmation",
    resolve: {
      user: UserResolver
    },
    component: ConfirmationComponent,
  },
  {
    path: ":businessurl",
    component: BookinghomeComponent,
    resolve: {
      customtoken: CustomTokenResolver,
      // user: UserResolver,
      business: BookingHomeResolver,
    },
  },
  {
    path: ":businessurl/service/:id",
    component: BookingDetailsComponent,
    resolve: {
      user: UserResolver,
      business: BookingHomeResolver
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BookingRoutingModule { }
