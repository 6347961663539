import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Days } from 'src/shared/data/enums/days';

@Injectable({
    providedIn: 'root'
})
export class DatesService {
    constructor() { }

    addHours(date: string, hours: number): string {
        return moment(date).add('hours', hours).toISOString();
    }

    addDays(date: string, days: number): string {
        return moment(date).add('days', days).toISOString();
    }

    diffInMinutes(startDate: string, endDate: string): number {
        return moment(startDate).diff(endDate, 'minutes');
    }

    diffInDays(startDate: Date, endDate: Date): number {
        return moment(startDate).diff(endDate, 'days', false);
    }

    diffBetweenNowAndDateInSeconds(endDate: string): number {
        return moment(endDate).diff(moment().utc(), 'seconds');
    }

    diffInSeconds(startDate: string, endDate: string): number {
        return moment(startDate).diff(endDate, 'seconds');
    }

    addMinutes(date: string, hours: number): string {
        return moment(date).add('minutes', hours).toISOString();
    }

    isBefore(startDate: string, endDate: string): boolean {
        return moment(startDate).isBefore(endDate);
    }

    isAfter(startDate: string, endDate: string): boolean {
        return moment(startDate).isAfter(endDate);
    }

    isSame(startDate: string, endDate: string): boolean {
        return moment(startDate).isSame(endDate);
    }

    isSameDay(startDate: string, endDate: string): boolean {
        return moment(startDate).isSame(endDate,'day');
    }

    getYear(date: string): number {
        return moment(date).year();
    }

    startOfToday(): Date {
        return moment().startOf('day').toDate();
    }
    startOfTodayIso(): string {
        return moment().startOf('day').toISOString();
    }

    getDateObjectFromIsoString(date: string): Date {
        return moment.utc(date).toDate();
    }

    getFormattedDateFromObject(date: Date): string {
        return moment(date).format('DD/MM/YYYY');
    }

    getFormattedDateTimeFromObject(date: Date): string {
        return moment(date).format('DD/MM/YYYY h:mm a');
    }
    getFormattedDateTimeFromEpochTime(epoch: number): string {
        return moment.unix(epoch).format('DD/MM/YYYY h:mm a');
    }

    getReadableDateTimeFromISO(date: string): string {
        return moment(date).isValid() ? moment(date).format('DD/MM/YYYY h:mm a') : "";
    }

    getReadableDateDayAndMonthLong(date: string): string {
        return moment(date).format('dddd, MMMM Do, YYYY');
    }

    getReadableDateDayAndMonthShort(date: string): string {
        return moment(date).format('ddd, MMM Do YYYY');
    }

    getReadableDate(date: string): string {
        return moment(date).format('DD/MM/YYYY');
    }

    getReadableDateTime(date: string): string {
        return moment(date).format('H:mm');
    }

    getDifferenceInMinutes(start: string, end: string): number {
        return moment(start).diff(end, "minutes");
    }

    getIsoDateFromObject(date: Date): string {
        return moment.utc(date).toISOString();
    }

    formatYYYYMMDDINTODate(date: string): Date {
        if (date == undefined)
            return moment().toDate();
        var split = date.split("-");
        if (split.length == 0)
            return moment().toDate();
        return moment([Number.parseInt(split[0]), Number.parseInt(split[0]), Number.parseInt(split[0])]).toDate();
    }

    justDate(date: Date) {
        return moment(date).format("YYYY-MM-DD");
    }

    getIsoDateFromObjectNotInUTC(date: Date): string {
        return moment(date).toISOString();;
    }

    getMonth(date: string): number {
        //Returns Jan - Dec as 0 - 11
        return moment(date).month();
    }

    getCalendarMonth(date: string): number {
        //Returns Jan - Dec as 1 - 12
        return moment(date).month() + 1;
    }

    getCalendarDayOfMonth(isoDate: string): number {
        //Returns days as 1-31
        return moment(isoDate).date();
    }

    getCalendarDayOfMonthUTC(isoDate: string): number {
        //Returns days as 1-31
        return moment.utc(isoDate).date();
    }

    getHourFromDate(date: Date): number {
        return moment(date).hour();
    }

    getMinuteFromDate(date: Date): number {
        return moment(date).minute();
    }

    //This will get the hour + UTC hours giving the local time
    getHour(isoDate: string): number {
        return moment(isoDate).hour();
    }

    //This will get the UTC hour... this is used to pass to the calendar that then adds the local hours
    getUTCHour(isoDate: string): number {
        return moment.utc(isoDate).hour();
    }

    getMinute(isoDate: string): number {
        return moment(isoDate).minute();
    }

    getDayStringFromDays(day: Days): string {
        var dayStr = "";
        switch (day) {
            case Days.MONDAY:
                dayStr = "Monday";
                break;
            case Days.TUESDAY:
                dayStr = "Tuesday";
                break;
            case Days.WEDNESDAY:
                dayStr = "Wednesday";
                break;
            case Days.THURSDAY:
                dayStr = "Thursday";
                break;
            case Days.FRIDAY:
                dayStr = "Friday";
                break;
            case Days.SATURDAY:
                dayStr = "Saturday";
                break;
            case Days.SUNDAY:
                dayStr = "Sunday";
                break;
            default:
                break;
        }
        return dayStr;
    }

}
