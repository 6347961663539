import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty',
  template: `
    <div class="flex items-center justify-center">
          <div class="text-center mt-8 mx-auto">
            <fa-icon class="mx-auto text-2xl text-gray-400" [icon]="icon"></fa-icon>
              <h3 class="mt-2 text-sm font-medium text-gray-900">{{header}}</h3>
              <p class="mt-1 text-sm text-gray-500">
                {{description}}
              </p>
              <div class="mt-6">
                <button *ngIf="showcta" (click)="click()" type="button" class="wild-btn-primary" style="min-width: 120px;">
                  {{ctatext}}
                </button>
              </div>
              <div *ngIf="ctatwotext" class="mt-3">
                <button  (click)="clickTwo()" type="button" class="wild-btn-light" style="min-width: 120px;">
                  {{ctatwotext}}
                </button>
              </div> 
          </div>
    </div>
  `,
})
export class EmptyComponent implements OnInit {
  @Input() icon: string;
  @Input() header: string;
  @Input() description: string;
  @Input() showcta: boolean;
  @Input() ctatext: boolean;
  @Output() onClick = new EventEmitter();
  @Input() ctatwotext: boolean;
  @Output() onClickTwo = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit();
  }

  clickTwo() {
    this.onClickTwo.emit();
  }

}
