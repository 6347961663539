import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { NavigationService } from 'src/shared/services/navigation_service/navigation_service';
import { WildNavigationType } from 'src/shared/data/enums/navigation_type';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

interface UsernameFormData {
  username: string;
}

@Component({
  selector: 'app-username', template: `
  <app-modaltopper [logo]="false" header="Username" paragraph="time to get creative"></app-modaltopper>
<app-form [form]="form" fullwidthbutton="true" ctaText="Add Username" disableDivide="true" (onSubmit)="onSubmit($event)"
    [loading]="loading">
    <app-inputgroup>
        <app-input [disableAutoComplete]="true" [form]="form" type="text" name="username" description="Username" [span]=6></app-input>
    </app-inputgroup>
</app-form>`
})
export class UsernameComponent implements OnInit {

  @Input() modallink: WildNavigationType;
  @Input() isPage: boolean;
  nav: WildNavigationType = WildNavigationType.UNKNOWN;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private navigation: NavigationService) { }

  form!: FormGroup;
  loading: boolean;

  ngOnInit(): void {
    this.form = this.fb.group({
      username: new FormControl('', [Validators.required]),
    });
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.nav != null && params?.nav != undefined && params?.nav != WildNavigationType.UNKNOWN)
        this.nav = parseInt(params.nav.toString());
    })
  }

  onSubmit(data: UsernameFormData) {
    this.loading = true;
    this.authenticationService.updateUsername(data.username)
      .then(async () => {
        await this.next();
      })
      .catch((error) => {
        this.handlerError(error.message);
      })
      .finally(() => {
        this.resetForm();
      })
  }

  handlerError(message: string) {

    this.notifyService.notify("Username", message, NotifyType.ERROR);
  }

  async next() {
    this.notifyService.notify("Username Saved", "You're all set", NotifyType.SUCCESS);
    if (this.isPage) {
      if (this.nav != WildNavigationType.UNKNOWN) {
        this.navigation.navigateWithReturnUrlCheck(this.nav);
      } else {
        this.navigation.navigate(WildNavigationType.WHERETO);
      }
    } else {
      this.modalService.hide();
      if (this.modallink) {
        this.navigation.navigate(this.modallink);
      }
    }
  }
  resetForm() {
    this.loading = false;
  }

}
