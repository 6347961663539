<div *ngIf="orderlineServiceSummary$ ">
  <ng-container *ngIf="orderlineServiceSummary$ | async as orderlineServiceSummary; else loadingOrError">
    <div *ngIf="!orderlineServiceSummary; else orderlineServiceSummaryFetched">
        <app-empty icon="calendar-alt" header="Error loading service details - try again" description=""></app-empty>    
    </div>
    <ng-template #orderlineServiceSummaryFetched> 
    <!-- CHOOSE QUANTITY START -->
    <div *ngIf="orderlineServiceSummary.placesInCart && orderlineServiceSummary.placesInCart > 0">
      <app-modaltopper [header]="orderlineServiceSummary.dateOnly" [parapraph]="orderlineServiceSummary.localStartTime + ' - ' + orderlineServiceSummary.localEndTime"></app-modaltopper>
      <div class="minheightforbookingExtraPeople flex flex-col tems-center justify-center">
        <div class="w-full">
          <p class="my-2 text-md font-semibold text-gray-600">Number of attendees:</p>
          <span class="isolate inline-flex rounded-md shadow-sm w-full">
            <button  (click)="removeAttendee()"type="button" class="flex-none w-20 flex items-center justify-center relative inline-flex items-center rounded-l-md border border-gray-300 wild-bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-blue-900 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 ">
              <fa-icon *ngIf="!removingAttendee" icon="minus"></fa-icon>
              <span *ngIf="removingAttendee" class="loader"></span>
            </button>
            <span type="button" class="flex-1 cursor-not-allowed-20 flex items-center justify-center relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
              <span *ngIf="!addingAttendee && !removingAttendee && !removingAllAttendees">{{orderlineServiceSummary.placesInCart}}</span>
              <span *ngIf="addingAttendee || removingAttendee || removingAllAttendees" class="loader"></span>
            </span>
            <button (click)="addAttendee()" type="button" class="flex-none w-20 flex items-center justify-center relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 wild-bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-blue-900 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1">
              <fa-icon *ngIf="!addingAttendee" icon="plus"></fa-icon>
              <span *ngIf="addingAttendee" class="loader"></span>
            </button>
          </span>
        </div>
        <p class="my-2 text-md font-medium text-gray-500 text-center">
          <span *ngIf="orderlineServiceSummary?.placesRemaining == 0">Phew! You've reserved the last place<span *ngIf="orderlineServiceSummary.placesInCart > 1">s</span></span>
          <span *ngIf="orderlineServiceSummary?.placesRemaining > 0">{{orderlineServiceSummary.placesRemaining}} place<span *ngIf="orderlineServiceSummary?.placesRemaining != 1">s</span> left</span>
        </p>
      </div>
      <button (click)="nextStep()" type="button" class="mt-8 w-full rounded-md border border-transparent wild-bg-primary py-2 px-4 text-sm font-medium text-white shadow hover:bg-blue-900 focus:outline-none focus:ring-2  focus:ring-offset-2">
        <span *ngIf="!addingAttendee && !removingAttendee && !removingAllAttendees">Continue</span>
        <span *ngIf="addingAttendee || removingAttendee || removingAllAttendees"  class="loader"></span>
      </button>
      <div class="mt-6 text-center cursor-pointer">
        <a *ngIf="!removingAllAttendees" (click)="removeAllAttendee()" class="text-red-500">Choose a different date</a>
        <span *ngIf="removingAllAttendees" (click)="removeAllAttendee()" class="text-red-500">loading...</span>
      </div>
    </div>
    <!-- CHOOSE QUANTITY END-->
    <!-- CHOOSE A NEW TIME SLOT START -->
    <div *ngIf="!orderlineServiceSummary.placesInCart || orderlineServiceSummary.placesInCart == 0">
      <app-modaltopper header="Select a Time" [parapraph]="service.name"></app-modaltopper>
        <div class="mb-8">
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-16">
            <div class="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-4">
              <div class="minheightforcalendar">
              <div class="flex items-center text-gray-900">
                <button (click)="prev()" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Previous month</span> 
                  <!-- Heroicon name: mini/chevron-left -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                  </svg>
                </button>
                <div class="flex-auto font-semibold">{{bookingMonth.description}}</div>
                <button (click)="next()" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Next month</span>
                  <!-- Heroicon name: mini/chevron-right -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
              <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
                <div>S</div>
              </div>
              <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                <!--
                  Always include: "py-1.5 hover:bg-gray-100 focus:z-10"
                  Is current month, include: "bg-white"
                  Is not current month, include: "bg-gray-50"
                  Is selected or is today, include: "font-semibold"
                  Is selected, include: "text-white"
                  Is not selected, is not today, and is current month, include: "text-gray-900"
                  Is not selected, is not today, and is not current month, include: "text-gray-400"
                  Is today and is not selected, include: "text-indigo-600"
                  Top left day, include: "rounded-tl-lg"
                  Top right day, include: "rounded-tr-lg"
                  Bottom left day, include: "rounded-bl-lg"
                  Bottom right day, include: "rounded-br-lg"
                -->
                <!-- <button type="button" class="rounded-tl-lg bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10"> -->
                  <!--
                    Always include: "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                    Is selected and is today, include: "bg-indigo-600"
                    Is selected and is not today, include: "bg-gray-900"
                  -->
                  <!-- <time datetime="2021-12-27" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">27</time>
                </button> -->
                
                <button (click)="day.status == BookingDayStatus.ACTIVE ? selectDate(day.date) : console.log('inactive date')" [ngClass]="{ 'bg-white text-gray-700 cursor-pointer hover:bg-gray-100':day.status == BookingDayStatus.ACTIVE,'bg-gray-50 text-gray-300 cursor-not-allowed':day.status != BookingDayStatus.ACTIVE}" *ngFor="let day of daysInMonth" type="button" class=" py-1.5 focus:z-10">
                  <time  [ngClass]="{'wild-bg-primary font-semibold text-white':dateMatchesSelectedDate(day.date)}" datetime="2021-12-28" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">{{day.dayOfMonth}}</time>
                </button>
              </div>
            </div>
            </div>
            <div  class="mt-4 text-sm leading-6 lg:col-span-7">
              <div class="minheightfordates">
                <div *ngIf="loadingDates">
                  <app-loader [disablescreenheight]="true"></app-loader>
                </div>
                <div *ngIf="!loadingDates && services?.results?.length == 0">
                  <app-empty 
                  [showcta]="true"
                  ctatext="Next Month"
                  (onClick)="next()"
                  ctatwotext="Prev Month"
                  (onClickTwo)="prev()"
                  icon="calendar-alt" header="No availability for this month" description=""></app-empty>    
                </div>
                <div *ngIf="!loadingDates && services?.results?.length > 0">
                  <h2 *ngIf="selectedDate" class="text-lg font-semibold text-gray-900 hidden sm:block">{{getReadableDateDayAndMonthLong()}}</h2>
                  <h2 *ngIf="selectedDate" class="text-lg font-semibold text-gray-900 sm:hidden">{{getReadableDateDayAndMonthShort()}}</h2>
                  <div *ngIf="services" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 mt-4 ">
                    <div (click)="slotSelected(service)" [ngClass]="{'hidden': !dateMatchesSelectedDate(service.date),'bg-green-500 border-white shadow-md':selectedSlot?.date == service.date, 'hover:bg-gray-50 hover:border-blue-900 border border-gray-100 shadow-lg':selectedSlot?.date != service.date}" *ngFor="let service of services?.results" class="flex flex-col items-center justify-center py-2    cursor-pointer">
                      <span [ngClass]="{'text-gray-700':selectedSlot?.date != service.date,'text-white':selectedSlot?.date == service.date}" class="text-sm leading-none">{{service.localstarttime}} - {{service.localendtime}}</span>
                      <span [ngClass]="{'text-gray-700':selectedSlot?.date != service.date,'text-white':selectedSlot?.date == service.date}" class="text-xs">{{service.capacity - service.slotsTaken}} place<span *ngIf="(service.capacity - service.slotsTaken) > 1">s</span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!loadingDates && services?.results?.length > 0">
                <button (click)="addToCart()" [ngClass]="{'opacity-25': !selectedSlot}" type="button" class="mt-8 w-full rounded-md border border-transparent wild-bg-primary py-2 px-4 text-sm font-medium text-white shadow hover:bg-blue-900 focus:outline-none focus:ring-2  focus:ring-offset-2">
                    <span *ngIf="!addingToCart && !selectedSlot">Select a time</span>
                    <span *ngIf="!addingToCart && selectedSlot">Add to Cart</span>
                    <span *ngIf="addingToCart" class="loader"></span>
                </button>
                <!-- <p class="text-center text-xs text-gray-500 font-semibold mt-3">You can adjust attendees on the next page</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- CHOOSE A NEW TIME SLOT END -->
  </ng-template> 
  </ng-container>  
  <ng-template #loadingOrError>
      <ng-container *ngIf="error; else loading">
          <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
              (onRetry)="load()">
          </app-retry>
      </ng-container>
      <ng-template #loading>
        <div class="minheightforloader flex items-center justify-center">
          <app-loader [disablescreenheight]="true"></app-loader>
        </div>
      </ng-template>
  </ng-template>    
</div>
