import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-registerpage',
  template: `
    <app-registrationpagecontainer [src]="src">
      <app-register [isPage]="true" [isBusiness]='isBusiness'></app-register>
    </app-registrationpagecontainer>
  `
})
export class RegisterpageComponent implements OnInit {

  src: string = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FUser%20Login%20Screen.png?alt=media&token=1faef80c-70e7-4cd8-8554-a024dae52474";

  constructor(private activatedRoute: ActivatedRoute) { }


  isBusiness: boolean;

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (params?.business == 1) {
        this.isBusiness = true;
        this.src = "https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/misc%2FBusiness%20register%20page.png?alt=media&token=e90c3a20-e8ab-4671-9899-115d41aa8a4f";
      }
    })
  }

}
