import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SelectModel } from 'src/shared/components/select/select_model';
import { Gender } from 'src/shared/data/enums/gender_type';
import { EmergencyModel } from 'src/shared/data/models/emergency_model';
import { PersonalModel } from 'src/shared/data/models/personal_model';
import { PhoneModel } from 'src/shared/data/models/phone_model';
import { UserModel } from 'src/shared/data/models/user_model';
import { UserProfileModel } from 'src/shared/data/models/user_profile_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { UserService } from 'src/wild/modules/user/services/user_service';
import { UtilService } from '../services/util/util_service';

@Component({
    selector: "app-bookingprofileform",
    template: `
    <ng-container *ngIf="user$ | async as user; else loadingOrError">
        <app-form [form]="form" [ctaText]="ctatext != null ? ctatext: 'Update'" disableDivide="true" (onSubmit)="onSubmit($event)"
            [loading]="loading">
            <app-inputgroup>
                <app-input validation="Minimum of 3 characters"  [form]="form" type="text" name="firstName" description="First Name" placeholder="Rob" [span]=3 [value]="user?.personal?.firstName"></app-input>
                <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="surname" description="Surname" [span]=3 placeholder="Gray" [value]="user?.personal?.surname"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-input [form]="form" type="text" name="pronouns" description="Pronouns" [span]=3 [value]="user?.personal?.pronouns"></app-input>
                <app-select [options]="genderOptions" [form]="form" name="gender" description="Gender" [span]=3 [value]="user?.gender"></app-select>
            </app-inputgroup> 
            <app-inputgroup>
                <app-formdatepicker [form]="form" name="dob" description="DOB" placeholder="Pick a date" [maxyear]="2010" [minyear]="1910" [span]=3 [value]="user?.dob"></app-formdatepicker>
            </app-inputgroup>
            <app-inputgroup>
                <app-select [options]="codeOptions" [form]="form" name="code" description="Code" [span]=3 [value]="user?.phone?.code"></app-select>
                <app-input validation="Invalid number" description="Phone Number" [form]="form" type="text" name="number" placeholder="07773123456" [span]=3 [value]="user?.phone?.number"></app-input>
            </app-inputgroup>
            <h1 class="text-lg mt-6 font-semibold">Medical</h1>
            <app-inputgroup>
                <app-textarea validation="Just type NA if not applicable" [form]="form" type="text" name="medicalInfo" description="Health Conditions" placeholder="Details of any underlying health conditions that we should be aware of. Just type NA if this is not applicable" [span]=6 [value]="user?.emergency?.medicalInfo"></app-textarea>
            </app-inputgroup>
            <h1 class="text-lg mt-6 font-semibold">Emergency Contact</h1>
            <app-inputgroup>
                <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="nextOfKinFirstName" description="First Name" placeholder="Rob" [span]=3 [value]="user?.emergency?.nextOfKin?.firstName"></app-input>
                <app-input validation="Minimum of 3 characters" [form]="form" type="text" name="nextOfKinSurname" description="Surname" placeholder="Gray" [span]=3 [value]="user?.emergency?.nextOfKin?.surname"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-select [options]="codeOptions" [form]="form" name="nextOfKinCode" description="Code" [span]=3 [value]="user?.emergency?.nextOfKinPhone?.code"></app-select>
                <app-input validation="Invalid number" description="Phone Number" [form]="form" type="text" name="nextOfKinNumber" placeholder="07773123456" [span]=3 [value]="user?.emergency?.nextOfKinPhone?.number"></app-input>
            </app-inputgroup>
            <app-inputgroup>
                <app-input [form]="form" type="text" name="nextOfKinEmail" description="Email" placeholder="support@wildopenwater.com" [span]=3 [value]="user?.emergency?.nextOfKin?.email"></app-input>
            </app-inputgroup>
            
        </app-form> 
    </ng-container>
    <ng-template #loadingOrError>
        <ng-container *ngIf="error; else loading">
            <app-retry [code]="error.code" [description]="error.description" [header]="error.message" (onRetry)="load()">
            </app-retry>
        </ng-container>
        <ng-template #loading>
            <app-loader [disablescreenheight]="true"></app-loader>
        </ng-template>
    </ng-template>

  `
})
export class BookingProfileFormComponent implements OnInit {

    user$: Observable<UserModel>;
    @Output() updated = new EventEmitter<void>();
    @Input() ctatext: string;
    codeOptions: SelectModel[] = [
        {
            name: "+44",
            value: "+44"
        }
    ]
    genderOptions: SelectModel[] = [
        {
            name: "",
            value: Gender.UNKNOWN
        }, {
            name: "Female",
            value: Gender.FEMALE
        }, {
            name: "Male",
            value: Gender.MALE
        }, {
            name: "Rather not say",
            value: Gender.RATHERNOTSAY
        }
    ]
    error: ErrorModel;

    constructor(
        private fb: FormBuilder,
        private util: UtilService,
        private userService: UserService,
        private modalService: ModalService,
        private notifyService: NotifyService) { }

    form!: FormGroup;
    loading: boolean;

    ngOnInit(): void {
        this.load();
        this.form = this.fb.group({
            //personal
            firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
            surname: new FormControl('', [Validators.required, Validators.minLength(3)]),
            pronouns: new FormControl('', []),
            //dob
            dob: new FormControl('', [Validators.required]),
            //gender
            gender: new FormControl(Gender.UNKNOWN, [Validators.required]),
            //phone
            code: new FormControl('+44', [Validators.required]),
            number: new FormControl('', [Validators.required, this.util.phoneValidator]),
            //emergency
            medicalInfo: new FormControl('', [Validators.required, Validators.minLength(2)]),
            nextOfKinCode: new FormControl('+44', [Validators.required]),
            nextOfKinNumber: new FormControl('', [Validators.required, this.util.phoneValidator]),
            nextOfKinEmail: new FormControl('', []),
            nextOfKinFirstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
            nextOfKinSurname: new FormControl('', [Validators.required, Validators.minLength(3)]),
        });
    }

    load() {
        this.user$ = null
        this.user$ = this.userService.getUser().pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    onSubmit(data: any) {
        this.loading = true;
        var personal: PersonalModel = {
            pronouns: data.pronouns,
            firstName: data.firstName,
            surname: data.surname
        }
        var phone: PhoneModel = {
            code: data.code,
            number: data.number
        }
        var emergency: EmergencyModel = {
            medicalInfo: data.medicalInfo,
            nextOfKinPhone: {
                number: data?.nextOfKinNumber,
                code: data?.nextOfKinCode
            },
            nextOfKin: {
                pronouns: "",
                email: data.nextOfKinEmail,
                firstName: data.nextOfKinFirstName,
                surname: data.nextOfKinSurname
            }
        }
        this.userService.updateBookingProfile(personal, phone, data.gender, emergency, data.dob)
            .then(() => {
                this.next();
            })
            .catch((error) => {
                this.handlerError(error.message);
            })
            .finally(() => {
                this.resetForm();
            })
    }

    handlerError(message: string) {
        this.notifyService.notify("Update", message, NotifyType.ERROR);
    }

    next() {
        this.notifyService.notify("Updated", "Perfect!", NotifyType.SUCCESS);
        this.updated.emit();
    }

    resetForm() {
        this.loading = false;
    }

}
