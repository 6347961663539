import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MailingListType } from 'src/shared/data/enums/mailinglist_type';

@Injectable({
  providedIn: 'root'
})
export class MailinglistRepositoryService {

  constructor(private readonly afs: AngularFirestore) { }

  async add(email: string) {
    await this.afs.collection('mailinglist').add({ email: email, type: MailingListType.USER })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        throw Error(errorMessage);
      });
  }

  async addOrganiser(email: string) {
    await this.afs.collection('mailinglist').add({ email: email, type: MailingListType.ORGANISER })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        throw Error(errorMessage);
      });
  }
}

