import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { SelectModel } from 'src/shared/components/select/select_model';
import { Currency } from 'src/shared/data/enums/currency';
import { CurrencyService } from '../currency/currency_service';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor(private currencyService: CurrencyService) { }



    getRandomNumber(): number {
        return Math.floor((Math.random() * 100000000000) + 1);
    }

    isMobile() {
        var a = navigator.userAgent || navigator.vendor;
        return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    }

    getNumberOptions(limit: number, multiple: number): SelectModel[] {
        var numbers: SelectModel[] = [];
        for (let i = 0; i < limit; i++) {
            const element = i * multiple;
            numbers.push({
                name: element.toString(),
                value: element
            })
        }
        return numbers;
    }
    getFormattedPrice(price, currency: Currency): string {
        try {
            //compensates for table table - it sets it as string
            currency = parseInt(currency.toString());
        } catch (error) {

        }
        var symbol = this.currencyService.getSymbol(currency);
        var formattedPrice = this.currencyService.priceToReadable(price);
        return symbol + formattedPrice;
    }
    //     To match pattern or an empty string, use
    // ^$|pattern
    // Explanation
    // ^ and $ are the beginning and end of the string anchors respectively.
    // | is used to denote alternates, e.g. this|that.
    get phoneValidator(): ValidatorFn {
        return Validators.pattern(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/)
    }
    get whatsappValidator(): ValidatorFn {
        return Validators.pattern(/^(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/)
    }
    get websiteValidator(): ValidatorFn {
        return Validators.pattern(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/)
    }
    get youTubeChannelValidator(): ValidatorFn {
        return Validators.pattern(/^(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+$/)
    }
    get facebookPageValidator(): ValidatorFn {
        return Validators.pattern(/^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)$/)
    }
    get instagramValidator(): ValidatorFn {
        return Validators.pattern(/^(?:(?:[\w][\.]{0,1})*[\w]){1,29}$/)
    }
    get twitterValidator(): ValidatorFn {
        return Validators.pattern(/^(?:(?:[\w][\.]{0,1})*[\w]){1,29}$/)
    }
    get noSpecialCharacters(): ValidatorFn {
        return Validators.pattern(/^[a-zA-Z0-9]{4,10}$/)
    }
    get noSpecialCharactersAllowSpaces(): ValidatorFn {
        return Validators.pattern(/^[A-Za-z0-9 ]+$/)
    }
    get lettersOnly(): ValidatorFn {
        return Validators.pattern(/^[a-zA-Z]*$/)
    }
    get lettersAndSpacesOnly(): ValidatorFn {
        return Validators.pattern(/^[a-zA-Z\s]*$/)
    }
    get numbersOnly(): ValidatorFn {
        return Validators.pattern(/^[0-9]*$/)
    }

    twoDecimalPlace(num: number): string {
        return (num / 100).toFixed(2).toString()
    }
}
