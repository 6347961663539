import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { BookingService } from 'src/booking/services/booking_service';
import { environment } from 'src/environments/environment';
import { AddToCartDTO } from 'src/shared/data/dtos/booking/AddToCartDTO';
import { AvailableCouponsCouponDTO } from 'src/shared/data/dtos/services/AvailableCouponsCouponDTO';
import { CategorisedServicesDTO } from 'src/shared/data/dtos/services/CategorisedServicesDTO';
import { ServiceAndPricesDTO } from 'src/shared/data/dtos/services/ServiceAndPricesDTO';
import { ServiceType } from 'src/shared/data/enums/service_type';
import { BusinessPublicModel } from 'src/shared/data/models/business_public_model';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ModalDesign } from 'src/shared/services/modal/modal_design';
import { ModalModel } from 'src/shared/services/modal/modal_model';
import { ModalService } from 'src/shared/services/modal/modal_service';
import { NotifyService } from 'src/shared/services/notify_service/notify_service';
import { NotifyType } from 'src/shared/services/notify_service/notify_type';
import { ServicesPublicService } from '../../../services/services_public_service';
import { AvailableScheduleComponent } from './availableschedule.component';
import { BookWrapperComponent } from './bookwrapper.component';
import { ContinueShoppingComponent } from './continueshopping.component';
import { CouponReadMoreComponent } from './couponreadmore.component';
import { ServiceReadMoreComponent } from './servicereadmore.component';

@Component({
    selector: "app-availableservicesv2",
    template: `
    <div class="bg-white pb-8">
            <ng-container *ngIf="categorisedServices$ | async as categorisedServices; else loadingOrError">
            <div *ngIf="!categorisedServices || categorisedServices.categories.length == 0; else categorisedServicesList">
                <app-empty icon="water" header="No services yet" description=""></app-empty>    
            </div>
            <ng-template #categorisedServicesList> 
                <!-- CATEGORY LOOP START    -->
                <div *ngFor="let category of categorisedServices.categories" >
                    <h2 class="text-lg mt-6">{{category.category}}</h2>
                    <p *ngIf="!category.serviceAndPrices || category.serviceAndPrices?.length == 0" class="text-xs italic text-gray-500">Not Available</p>
                    <!-- SERVICES LOOP START    -->
                    <div  *ngFor="let service of category.serviceAndPrices; let i = index" >
                        <!-- BOOK ROW START -->
                        <div class="flex w-full my-4 items-center">
                            <div class="hidden sm:flex-none sm:block ">
                                        <div (click)="readmore(service)" class="  cursor-pointer relative w-20 h-20 rounded-lg overflow-hidden">
                                            <img [src]="service.image != null ? imagesUrl + service.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"  alt="Service Image" class="w-full h-full object-center object-cover">
                                        </div>
                                    </div>
                                    <div class="flex-1 sm:px-4">
                                        <h3 class="text-md font-medium text-gray-900 line-clamp-2">{{service.name}}</h3>
                                        <div *ngIf="service.type == ServiceType.CALENDARBASED && service.capacity > 1" class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 my-2">
                                            Session for {{service.capacity}} people
                                        </div>
                                        <div *ngIf="service.type == ServiceType.CALENDARBASED && service.capacity == 1" class="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 my-2">
                                            121 session
                                        </div>
                                        <p class="text-sm text-gray-700 line-clamp-2">{{service.description}}</p>
                                        <a  target="_parent" *ngIf="isWidgetUser" [href]="service.widgeturl"  class="text-xs sm:text-sm wild-text-primary hover:underline cursor-pointer">find out more</a>
                                        <p *ngIf="!isWidgetUser" (click)="readmore(service)" class="text-xs sm:text-sm wild-text-primary hover:underline cursor-pointer">find out more</p>
                                    </div>
                                    <div class="flex-none ml-3">
                                        <div class="flex">
                                            <div class="text-right">
                                                <div class="text-md  text-black font-medium leading-tight">{{service.formattedPrice}}</div>
                                                <div style="font-size: .5rem" class="text-gray-500 leading-none">per person</div>
                                                <div class="text-sm text-gray-500">{{service.duration}}</div>
                                            </div>
                                            <div class="ml-3">
                                                <a target="_parent" *ngIf="isWidgetUser" [href]="service.widgeturl" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">Book</a>
                                                <a *ngIf="!isWidgetUser"  (click)="book(service)" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">Book</a>
                                            </div>
                                        </div>
                                </div>
                        </div>
                         <!-- BOOK ROW END -->
                        <!-- DIVIDER START -->
                        <div *ngIf="i < (category.serviceAndPrices.length - 1)" class="relative my-4">
                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t border-gray-200"></div>
                            </div>
                            <div class="relative flex justify-center">
                                <span class="bg-white px-2 text-gray-200">
                                    <fa-icon icon="water"></fa-icon>
                                </span>
                            </div>
                        </div>
                        <!-- DIVIDER END -->
                    </div> 
                    <!-- SERVICES LOOP END  -->
                </div>
                 <!-- CATEGORY LOOP END    -->
            <!-- COUPONS START -->
            <h2 *ngIf="categorisedServices.coupons && categorisedServices.coupons?.length > 0" class="text-lg">Vouchers</h2>
            <!-- LOOP START -->
            <div  *ngFor="let coupon of categorisedServices.coupons; let i = index" >
                <!-- BOOKING ROW START -->
                <div class="flex w-full my-4">
                    <div class="hidden sm:flex-none sm:block ">
                            <div (click)="readmoreCoupons(coupon)" class="  cursor-pointer relative w-20 h-20 rounded-lg overflow-hidden">
                                <img [src]="coupon.image != null ? imagesUrl + coupon.image?.image + '_1080x1080.jpeg?alt=media' : 'https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/business%2Fprofilepics%2Fplaceholder.png?alt=media'"  alt="Service Image" class="w-full h-full object-center object-cover">
                            </div>
                        </div>
                        <div class="flex-1 sm:px-4">
                            <h3 class="text-md font-medium text-gray-900 line-clamp-2">{{coupon.name}}</h3>
                            <p class="text-sm text-gray-700 line-clamp-2">{{coupon.description}}</p>
                            <p *ngIf="!isWidgetUser" (click)="readmoreCoupons(coupon)" class="text-xs sm:text-sm wild-text-primary hover:underline cursor-pointer">find out more</p>
                        </div>
                        <div class="flex-none">
                            <div class="flex">
                                <div class="text-right">
                                    <div class="text-md  text-black font-medium leading-tight">{{coupon.formattedPrice}}</div>
                                </div>
                                <div class="ml-3">
                                <a *ngIf="isWidgetUser" target="_parent" [href]="coupon.widgeturl" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">Buy</a>
                                    <a *ngIf="!isWidgetUser" (click)="bookCoupon(coupon)" class="cursor-pointer wild-btn-primary p-2 sm:p-auto w-12 sm-w-14">
                                    <span *ngIf="!coupon.addingToCart">Buy</span>
                                    <span *ngIf="coupon.addingToCart" class="loader"></span>
                                    </a>
                                </div>
                            </div>
                    </div>
                </div>
                <!-- BOOKING ROW END --> 
                <!-- DIVIDER START -->
                <div *ngIf="i < (categorisedServices.coupons?.length - 1)" class="relative my-4">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-200"></div>
                        </div>
                        <div class="relative flex justify-center">
                            <span class="bg-white px-2 text-gray-200">
                                <fa-icon icon="water"></fa-icon>
                            </span>
                        </div>
                    </div>
                    <!-- DIVIDER END -->
                </div>
                <!-- LOOP END -->
            <!-- COUPONS END -->
            <!-- POWERED BY MESSAGE START -->
            <div *ngIf="isWidgetUser" class="my-12 flex items-center flex-col justify-center">
                <p class="text-md">powered by</p>
                <a target="_blank" href="https://wildopenwater.com"><img  class="h-16 mt-3" src="https://firebasestorage.googleapis.com/v0/b/wild-live.appspot.com/o/logos%2Fwild%20logo%20small.png?alt=media&token=5db7aaeb-d57d-455b-bb0b-966738cfde41"/></a>
            </div>
             <!-- POWERED BY MESSAGE END -->
            </ng-template> 
            </ng-container>  
            <ng-template #loadingOrError>
                <ng-container *ngIf="error; else loading">
                    <app-retry [code]="error.code" [description]="error.description" [header]="error.message"
                        (onRetry)="load()">
                    </app-retry>
                </ng-container>
                <ng-template #loading>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                    <app-loadingserviceshimmer></app-loadingserviceshimmer>
                </ng-template>
            </ng-template>     
    </div>
    
  `
})
export class AvailableServicesV2Component implements OnInit {

    categorisedServices$: Observable<CategorisedServicesDTO>;
    imagesUrl = environment.imagesUrl;
    @Input() businessId: string;
    @Input() isWidgetUser: boolean;
    @Input() business: BusinessPublicModel;
    error: ErrorModel;
    ServiceType = ServiceType;

    constructor(
        private modalService: ModalService,
        private notifyService: NotifyService,
        private authService: AuthenticationService,
        private router: Router,
        private bookingService: BookingService,
        private servicesPublicService: ServicesPublicService) { }

    ngOnInit(): void {
        this.load();
        if (this.isWidgetUser)
            this.publishHeight();
    }

    bookCoupon(coupon: AvailableCouponsCouponDTO) {
        if (this.authService.isAuthedIfNotShowPopUp()) {
            if (coupon.addingToCart)
                return;
            coupon.addingToCart = true;
            var addToCart: AddToCartDTO = {
                couponId: coupon.id,
                quantity: 1,
                slot: null,
                scheduled: null,
                businessId: coupon.businessId
            }
            this.bookingService.addToCart(addToCart)
                .then(() => {
                    this.addedToCart(coupon.businessId);
                })
                .catch((error) => {
                    this.handlerError(error.message);
                }).finally(() => {
                    coupon.addingToCart = false;
                });
        }
    }


    handlerError(message: string) {
        this.notifyService.notify("Error", message, NotifyType.ERROR);
    }

    addedToCart(businessId: string) {
        window.scrollTo(0, 0);
        var settings: ModalModel = {
            close: true,
            large: false,
            design: ModalDesign.COMPONENT,
            component: ContinueShoppingComponent,
            componentInputs: [
                {
                    inputName: "businessId",
                    value: businessId
                }
            ]
        }
        this.modalService.show(settings);
    }


    load() {
        this.categorisedServices$ = null;
        this.categorisedServices$ = this.servicesPublicService.getCategorisedServicesV2(this.businessId).pipe(
            take(1),
            catchError(err => {
                this.error = err;
                return throwError(err);
            })
        );
    }

    readmoreCoupons(coupon: AvailableCouponsCouponDTO) {
        var settings: ModalModel = {
            close: true,
            large: true,
            design: ModalDesign.COMPONENT,
            component: CouponReadMoreComponent,
            componentInputs: [
                {
                    inputName: "coupon",
                    value: coupon
                }
            ]
        }
        this.modalService.show(settings);
    }

    readmore(service: ServiceAndPricesDTO) {
        this.router.navigate([this.business.url, "service", service.serviceId])
    }

    book(service: ServiceAndPricesDTO) {
        this.bookingService.book(service);
    }

    heightChecks = 0;
    totalHeightChecks = 10;

    publishHeight() {
        try {
            var actualHeight = this.getBodyHeight();
            var currentHeight = this.getViewPortHeight();
            if (Math.abs(actualHeight - currentHeight) > 15) {
                window.parent.postMessage({ message: "wildheight", value: actualHeight.toString() }, "*");
            }
            this.heightChecks++;
            if (this.heightChecks <= this.totalHeightChecks) setTimeout(() => {
                this.publishHeight();
            }, 1000);
        } catch (error) {
            console.log("Failed to set height check");
            console.log(error);
        }
    }


    getBodyHeight() {

        var height,
            scrollHeight,
            offsetHeight;

        if (document.body.scrollHeight) {
            height = scrollHeight = document.body.scrollHeight;
        }

        if (document.body.offsetHeight) {
            height = offsetHeight = document.body.offsetHeight;
        }

        if (scrollHeight && offsetHeight) {
            height = Math.max(scrollHeight, offsetHeight);
        }

        return height;
    }

    getViewPortHeight() {

        var height = 0;

        if (window.innerHeight) {
            height = window.innerHeight - 18;
        } else if ((document.documentElement) && (document.documentElement.clientHeight)) {
            height = document.documentElement.clientHeight;
        } else if ((document.body) && (document.body.clientHeight)) {
            height = document.body.clientHeight;
        }

        return height;

    }


}
