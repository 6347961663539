<div style="min-height: 80vh;">
    <div *ngIf="isLoading == true">
        <app-loader></app-loader>
    </div>
    <div *ngIf="isLoading == false">
        <router-outlet></router-outlet>
    </div>
</div>
<app-notify></app-notify>
<app-modal></app-modal>
<app-panel></app-panel>