import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  template: `
  <div [formGroup]="form">
    <div *ngIf="!hideHeaders" class="flex justify-between">
      <label [for]="name" class="block wild-label">
            {{description}}<span *ngIf="required">*</span>
      </label>
    </div>
    <div class="mt-1 flex rounded-md shadow-sm">
        <textarea [placeholder]="placeholder" rows=5 [formControlName]="name" [name]="name" [id]="name"
            class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0  rounded-r-md sm:text-sm border-gray-300"></textarea>
    </div>
    <div *ngIf="form.get(name)?.errors &&(form.get(name)?.dirty || form['submitted'] == true)">
        <div class="font-light text-xs text-red-600 py-1" *ngIf="form.get(name)?.errors">
            <span *ngIf="!validation"> {{description}} is required</span>
            <span *ngIf="validation"> {{validation}}</span>
        </div>
    </div>
</div>
`
})
export class TextareaComponent implements OnInit {

  @Input() form!: any;
  @Input() hideHeaders: boolean;
  @Input() name!: string;
  @Input() description!: string;
  @Input() validation!: string;
  @Input() span!: number;
  @Input() placeholder: string = "";
  @Input() value!: any;

  @HostBinding('class') get t() {
    return "p-0 border-0 bg-transparent sm:col-span-" + this.span;
  };

  constructor() { }

  required;

  ngOnInit(): void {
    if (this.value != undefined && this.value != null)
      this.form.controls[this.name].setValue(this.value);
    const form_field = this.form.get(this.name);
    if (form_field.validator) {
      const validator = form_field.validator({} as AbstractControl);
      if (validator && validator.required)
        this.required = true;
    }
  }

}
