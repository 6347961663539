export const environment = {
  production: false,
  appleRedirectUrl: "https://wild-dev-1.firebaseapp.com/__/auth/handler",
  widgetscripturl: "https://jsplugin.s3.eu-west-1.amazonaws.com/scriptdev.js",
  domain: 'https://swimwild.dev',
  widgetdomain: 'https://widget.swimwild.dev',
  api: "https://wild-dev-1.ew.r.appspot.com",
  stripe: "pk_test_51KD3X1JNp9ZMedMiv78TDMiifTlY8ds3LvrQiE7uLIaHka7rYDDGI8eED0aj6sAg3RYKN1TcupfSE9YVOxOI9XaY00vEqhtVfi",
  firebase: {
    apiKey: "AIzaSyBhOeAYLOWPS_u7zYVqjLqByWbkXA9VGsM",
    authDomain: "wild-dev-1.firebaseapp.com",
    projectId: "wild-dev-1",
    storageBucket: "wild-dev-1.appspot.com",
    messagingSenderId: "1016270149327",
    appId: "1:1016270149327:web:cd230d187a8bdc5e8809ba",
    measurementId: "G-R2PYDNGXVY",
  },
  imagesUrl: "https://firebasestorage.googleapis.com/v0/b/wild-dev-1.appspot.com/o/images%2Fresized%2F",
  bannerOptions: [{
    likedBy: [],
    likedByUsers: [],
    numberOfLikes: 0,
    numberOfComments: 0,
    caption: "",
    createdBy: "",
    creation_date: new Date(),
    id: "",
    image: "businessbannerimage_59799992011",
    notifiedCreatorAboutVerification: false,
    sourceDocumentId: "",
    sourceDocumentImageParentType: 0,
    sourceDocumentImageType: 0,
    sourceDocumentParentId: "",
    status: 0,
    url: "https://firebasestorage.googleapis.com/v0/b/wild-dev-1.appspot.com/o/images%2Fbusinessbannerimage_59799992011.jpeg?alt=media&token=f1439e10-ec59-4b5c-96c8-36f35150ca97"
  },
  {
    likedBy: [],
    likedByUsers: [],
    numberOfLikes: 0,
    numberOfComments: 0,
    caption: "",
    createdBy: "",
    creation_date: new Date(),
    id: "",
    image: "businessbannerimage_6959378971",
    notifiedCreatorAboutVerification: false,
    sourceDocumentId: "",
    sourceDocumentImageParentType: 0,
    sourceDocumentImageType: 0,
    sourceDocumentParentId: "",
    status: 0,
    url: "https://firebasestorage.googleapis.com/v0/b/wild-dev-1.appspot.com/o/images%2Fbusinessbannerimage_6959378971.jpg?alt=media&token=8ad1b34f-7ab0-4533-945d-590ea0f38a03"
  }],
  profilepicOptions: [
    {
      likedBy: [],
      likedByUsers: [],
      numberOfLikes: 0,
      numberOfComments: 0,
      caption: "",
      createdBy: "",
      creation_date: new Date(),
      id: "",
      image: "businessbannerimage_66293559486",
      notifiedCreatorAboutVerification: false,
      sourceDocumentId: "",
      sourceDocumentImageParentType: 0,
      sourceDocumentImageType: 0,
      sourceDocumentParentId: "",
      status: 0,
      url: "https://firebasestorage.googleapis.com/v0/b/wild-dev-1.appspot.com/o/images%2Fbusinessbannerimage_66293559486.jpg?alt=media&token=c6eb1859-28de-4f3a-ae1e-67b8bec12867"
    },
    {
      likedBy: [],
      likedByUsers: [],
      numberOfLikes: 0,
      numberOfComments: 0,
      caption: "",
      createdBy: "",
      creation_date: new Date(),
      id: "",
      image: "businessbannerimage_84474633767",
      notifiedCreatorAboutVerification: false,
      sourceDocumentId: "",
      sourceDocumentImageParentType: 0,
      sourceDocumentImageType: 0,
      sourceDocumentParentId: "",
      status: 0,
      url: "https://firebasestorage.googleapis.com/v0/b/wild-dev-1.appspot.com/o/images%2Fbusinessbannerimage_84474633767.jpg?alt=media&token=b19c3779-0759-4ce5-aeb1-85fcb01f05e6"
    }
  ]
};
