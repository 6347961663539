import { Injectable } from '@angular/core';
import { NotifyModel } from './notify_model';
import { NotifyType } from './notify_type';
@Injectable({
    providedIn: 'root'
})
export class NotifyService {

    constructor() { }

    notifications: NotifyModel[] = [];

    get get(): NotifyModel[] {
        console.log(this.notifications)
        return this.notifications;
    }

    notify(header: string, message: string, type: NotifyType,) {
        var id = Math.ceil(Math.random() * 10000000);
        var notification = {
            id: id,
            type: type,
            header: header,
            message: message,
            //used to trigger animation
            show: false,
            hide: false
        }
        this.notifications.push(notification);
        setTimeout(() => {
            notification.show = true;
        }, 100);
        setTimeout(() => {
            this.removeById(id);
        }, 5000);
    }

    removeById(id: number) {
        var index = this.getIndex(id);
        if (index == null || index == -1)
            return;
        var notification = this.notifications[index];
        notification.show = false;
        notification.hide = true;
        this.notifications[index] = notification;
        setTimeout(() => {
            //index is retrieved again in case the index has shifted
            var index = this.getIndex(id);
            if (index != null && index != -1)
                this.remove(index);
        }, 350)
    }

    remove(index: number) {
        this.notifications.splice(index, 1);
    }

    getIndex(id: number) {
        return this.notifications.findIndex(notification => notification.id == id);
    }


}
