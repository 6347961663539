import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, map, retryWhen, delayWhen, tap, finalize, mergeMap } from 'rxjs/operators';
import { ErrorType } from 'src/shared/data/enums/error_type';
import { ErrorModel } from 'src/shared/data/models/error_model';
import { AuthenticationService } from '../auth/auth_service';
import { BYPASS_AUTH } from './auth_interceptor';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private auth: AuthenticationService,
    private http: HttpClient) { }


  get(url, disableAuth: boolean = false): Observable<any> {
    return this.http.get<any>(url, { context: new HttpContext().set(BYPASS_AUTH, disableAuth) })
      .pipe(
        retryWhen(this.httpRetryStrategy()),
        catchError((err: HttpErrorResponse) => {
          var error = this.getError(err.error);
          return throwError(error);
        })
      );
  }

  post(url, body: any, disableAuth: boolean = false): Observable<any> {
    return this.http.post<any>(url, body, { context: new HttpContext().set(BYPASS_AUTH, disableAuth) })
      .pipe(
        retryWhen(this.httpRetryStrategy()),
        catchError((err: HttpErrorResponse) => {
          var error = this.getError(err.error);
          return throwError(error);
        })
      );
  }

  delete(url, disableAuth: boolean = false): Observable<any> {
    return this.http.delete<any>(url, { context: new HttpContext().set(BYPASS_AUTH, disableAuth) })
      .pipe(
        retryWhen(this.httpRetryStrategy()),
        catchError((err: HttpErrorResponse) => {
          var error = this.getError(err.error);
          return throwError(error);
        })
      );
  }

  put(url, body: any, disableAuth: boolean = false): Observable<any> {
    return this.http.put<any>(url, body, { context: new HttpContext().set(BYPASS_AUTH, disableAuth) })
      .pipe(
        retryWhen(this.httpRetryStrategy()),
        catchError((err: HttpErrorResponse) => {
          var error = this.getError(err.error);
          return throwError(error);
        })
      );
  }

  patch(url, body: any, disableAuth: boolean = false): Observable<any> {
    return this.http.patch<any>(url, body, { context: new HttpContext().set(BYPASS_AUTH, disableAuth) })
      .pipe(
        retryWhen(this.httpRetryStrategy()),
        catchError((err: HttpErrorResponse) => {
          var error = this.getError(err.error);
          return throwError(error);
        })
      );
  }

  httpRetryStrategy = ({
    maxRetryAttempts = 4,
    scalingDuration = 1000,
    excludedStatusCodes = []
  }: {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[]
  } = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find(e => e === error.status)
        ) {
          return throwError(error);
        }
        // Add conditions here to trigger a retry. If these conditions are not met then it will NOT retry. We DO NOT want retries running by default they need to be based on these conditrions. 
        // Simply add additional conditions here that will result in a retry
        if (error?.toString().includes("getIdTokenResult")) {
          console.log("Caught auth error... sign out and go again")
          this.auth.removeLoggedInCookies();
        } else {
          return throwError(error);
        }
        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      }),
      finalize(() => console.log('Retries processed'))
    );
  };

  getError(err): ErrorModel {
    //Check if it's an error in ErrorModel format. If not.... convert to standard error model format so front end can present
    if (err?.message != undefined && err?.description != undefined && err?.code != undefined) {
      return err;
    } else {
      var error: ErrorModel = {
        code: ErrorType.UNKNOWNHTTPERRORONCLIENT,
        description: "Please reload the page and try again or logout and retry",
        message: "Unknown error"
      }
      return error;
    }
  }

}
